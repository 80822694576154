import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import ActionBar from "../../../../common/components/actionBar/ActionBar";
import {Box, Drawer} from "@mui/material";
import TriggerChartSelector from "../TriggerChartSelector";
import VersionControl from "./components/VersionControl";
import BuySellToggleSwitch from "./components/BuySellToggleSwitch";
import LayoutInput from "./components/LayoutInput";
import PatternNameInput from "./components/PatternNameInput";
import PatternBuilderSidebar from "../../app/PatternBuilderSidebar";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from "@mui/material/IconButton";
import NavigationSelect from "../../../../components/NavigationSelect";
import TradingHouseSelector from "../../../../components/TradingHouseSelector";
import RemainingBalance from "../../../../components/RemainingBalance";
import {useApplicationSuiteContext} from "../../../../contexts/ApplicationSuiteProvider";

const PatternBuilderHeaderBar = () => {
    const isMobile = useMediaQuery('(max-width: 1146px)');
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const {
        canToggleTradingHouse
    } = useApplicationSuiteContext();

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const menuButton = () => {
        return (
            <Box sx={{ color: 'white', alignContent: 'center', display: 'flex' }}>
                <IconButton onClick={toggleSidebar}>
                    <MenuIcon />
                </IconButton>
            </Box>
        );
    };

    return (
        <>
            <ActionBar
                position={'top'}
                left={
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        gap={2}
                        sx={{
                            width: '100%',
                            alignItems: 'center'
                        }}
                    >
                        <NavigationSelect />
                        {canToggleTradingHouse && <TradingHouseSelector/>}
                        {!isMobile && <>
                        <Box sx={{ flex: 1 }}>
                            <PatternNameInput />
                        </Box>
                            <VersionControl sx={{ flex: 1 }} />
                            <Box sx={{ flex: 1 }}>
                                <LayoutInput />
                            </Box>
                            <Box sx={{ flex: 2 }}>
                                <TriggerChartSelector />
                            </Box>
                        </>}
                    </Box>
            }
                right={
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        sx={{
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'end'
                        }}>
                        {!isMobile ? <BuySellToggleSwitch /> : null}
                        <RemainingBalance />
                        {isMobile && menuButton()}
                    </Box>
                    }
            />
            {isMobile && (
                <Drawer
                    anchor="right"
                    open={isSidebarOpen}
                    onClose={toggleSidebar}
                >
                    <PatternBuilderSidebar />
                </Drawer>
            )}
        </>
    );
}

export default PatternBuilderHeaderBar;
