import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IndicatorParameterInputs from "../indicators/types/IndicatorParameterInputs";
import IndicatorNumberParameterInput from "./IndicatorNumberParameterInput";
import IndicatorSelectParameterInput from "./IndicatorSelectParameterInput";
import Box from "@mui/material/Box";
import IndicatorBooleanParameterCheckbox from "./indicatorBooleanParameterInput";

function IndicatorParameterDialog({parameters, onIndicatorParameterChange, indicatorName, viewOnly}: any) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const eventHandlers = !viewOnly ? {
        onMouseEnter: (e: any) => {
            e.currentTarget.style.color = 'rgba(0, 161, 255)';
            e.currentTarget.style.cursor = 'pointer';
        },
        onMouseLeave: (e: any) => {
            e.currentTarget.style.color = '';
        }
    } : {};

    return (
        <Box>
            <Box display={"flex"}
                 flexWrap={"wrap"}
                 width={200}
                 sx={viewOnly ? {color: "lightgray"} : {}}
                 {...eventHandlers}
                 onClick={() => !viewOnly && handleOpen()}

            >

                {
                    (Object.entries(parameters || {}) as Array<[keyof typeof IndicatorParameterInputs, any]>).map(([key, parameterDetails], index) => {
                        return <Box key={key} display={"flex"} fontSize={12} alignItems={"center"}>
                                <Box mr={.5} fontWeight={600}>{IndicatorParameterInputs[key].label}:</Box>
                                <Box>{`${parameterDetails}`}</Box>
                                {index !== Object.values(parameters).length - 1 && (<Box mx={1}>|</Box>)}
                            </Box>
                    })
                }
            </Box>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle  sx={{marginBottom: 1}}>{`${indicatorName} Parameters`}</DialogTitle>
                <DialogContent sx={{display: "flex", flexDirection: "column", gap: 2}}>
                    {
                        (Object.entries(parameters || {}) as Array<[keyof typeof IndicatorParameterInputs, any]>).map(([key, parameterDetails]) => {
                            const currentInput = IndicatorParameterInputs[key];

                            if (currentInput.type === "number") {
                                return <IndicatorNumberParameterInput
                                    key={key}
                                    label={currentInput.label}
                                    value={parameterDetails}
                                    step={currentInput.step}
                                    onChange={(val: any) => onIndicatorParameterChange(key, val)}
                                />;
                            }

                            if (currentInput.type === "select" && 'options' in currentInput) {
                                return <IndicatorSelectParameterInput
                                    key={key}
                                    label={currentInput.label}
                                    options={currentInput.options}
                                    value={parameterDetails}
                                    onChange={(val: any) => onIndicatorParameterChange(key, val)}
                                />;
                            }

                            if (currentInput.type === "boolean") {
                                return <IndicatorBooleanParameterCheckbox
                                    key={key}
                                    label={currentInput.label}
                                    checked={parameterDetails}
                                    onChange={(val: any) => onIndicatorParameterChange(key, val)}
                                />;
                            }

                            return null;
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default IndicatorParameterDialog;
