import Indicator from "../types/Indicator";
import Value from "../Value";
import conditionTypes from "../../conditions";

const MovingAverageConvergenceDivergence: Indicator = {
    value: "movingAverageConvergenceDivergence",
    label: "Moving Average Convergence Divergence",
    nickname: "MACD",
    fields: ["macd", "signal"],
    params: {
        fastPeriod: 5,
        slowPeriod: 10,
        signalPeriod: 9,
    },
    RHS: () =>  [MovingAverageConvergenceDivergence, Value],
    conditions: conditionTypes,
};


export default MovingAverageConvergenceDivergence;
