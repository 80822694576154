import Indicator from "../types/Indicator";
import {overlayIndicators} from "./index";
import Open from "../Open";
import High from "../High";
import Low from "../Low";
import Close from "../Close";
import Value from "../Value";
import conditionTypes from "../../conditions";

const MovingAverageEnvelope: Indicator = {
    value: "movingAverageEnvelope",
    label: "Moving Average Envelope",
    nickname: "Moving Average Envelope",
    fields: ["middle", "upper", "lower"],
    params: {
        period: 20,
        field: "close",
        multiplier: 0.1,
        multiplierType: "percent",
        movingAverageType: "simple",
    },
    RHS: () => [...overlayIndicators, Open, High, Low, Close, Value],
    conditions: conditionTypes,
}


export default MovingAverageEnvelope;
