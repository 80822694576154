import {FormControl, Grid, InputLabel, OutlinedInput} from "@mui/material";
import React from "react";
import ExitStrategiesProps from "./ExitStrategiesProps";

export default function IncrementalExitStrategyInput(props: ExitStrategiesProps) {
    const {backtestSettingInputData, handleInputChange} = props;

    return (
        <>
            <Grid container item spacing={2}>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Incremental start</InputLabel>
                        <OutlinedInput
                            value={backtestSettingInputData.generateTrades.incrementalStart}
                            name="generateTrades.incrementalStart"
                            onChange={(e) => handleInputChange(e.target.name, parseFloat(e.target.value))}
                            type={"number"}
                            inputProps={{step: .00001, min: 0}}
                            label="Incremental start"
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Incremental stop</InputLabel>
                        <OutlinedInput
                            value={backtestSettingInputData.generateTrades.incrementalStop}
                            name="generateTrades.incrementalStop"
                            onChange={(e) => handleInputChange(e.target.name, parseFloat(e.target.value))}
                            type={"number"}
                            inputProps={{step: .00001, min: 0}}
                            label="Incremental stop"
                        />
                    </FormControl>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Incremental step</InputLabel>
                        <OutlinedInput
                            value={backtestSettingInputData.generateTrades.incrementalStep}
                            name="generateTrades.incrementalStep"
                            onChange={(e) => handleInputChange(e.target.name, parseFloat(e.target.value))}
                            type={"number"}
                            inputProps={{step: .00001, min: .00001}}
                            label="Incremental step"
                        />
                    </FormControl>
                </Grid>
            </Grid>

        </>
    )
}
