import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

interface Option {
    value: string;
    label: string;
}

interface IndicatorSelectParameterInputProps {
    label: string;
    options: Option[];
    value: string;
    onChange: (value: string) => void;
}

const IndicatorSelectParameterInput: React.FC<IndicatorSelectParameterInputProps> = ({ label, options, value, onChange }) => {
    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <FormControl fullWidth variant={"standard"} onClick={handleClick}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                onChange={(event) => onChange(event.target.value as string)}
                label={label}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default IndicatorSelectParameterInput;
