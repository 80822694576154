import React, {useContext} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ConditionGroup from './conditionGroup/ConditionGroup';
import PhantomConditionGroup from "./conditionGroup/PhantomConditionGroup";
import { PatternTreeContext } from "./contexts/PatternTreeContext";
import Divider from "@mui/material/Divider";
import { styled, useTheme } from "@mui/material/styles";
import { Chip } from "@mui/material";
import Box from "@mui/material/Box";
import { useApplicationSuiteContext } from "../../contexts/ApplicationSuiteProvider";
import PatternBuilderHeaderBar from "./components/actionBars/PatternBuilderHeaderBar";
import PatternBuilderActionBar from "./components/actionBars/PatternBuilderActionBar";
import useMediaQuery from "@mui/material/useMediaQuery";

const CenteredDividerContainer = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
        marginTop: theme.spacing(2),
    },
}));

function BuildArea(props: any) {
    const {
        pattern,
        canViewPrivatePatterns
    } = useApplicationSuiteContext()

    const isSomeoneElsesPattern = pattern && pattern.author && pattern.author === "Showcase";

    const {
        conditionGroups
    } = useContext(PatternTreeContext);

    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width: 1146px)');

    // TODO: Review initialization, when code below is active the tab switching removes active state
    // useEffect(() => {
    //     if (pattern) {
    //         for (const condition of conditionsRef.current) {
    //             _set(condition, 'condition.isValid', true)
    //         }
    //         setPattern(pattern)
    //     } else {
    //         navigate(`/`, { replace: true });
    //     }
    // }, [pattern, setPattern, navigate])
    //
    // useEffect(() => {
    //     if (!patternId) {
    //         setPattern(_cloneDeep(defaultPattern))
    //     }
    // }, [patternId, setPattern]);

    return (
        <DndProvider backend={HTML5Backend}>
            {/*buy/sell button on the right*/}
            <PatternBuilderHeaderBar />
            <Box sx={{display: 'flex', flexDirection: "column", gap: 1, paddingRight: 1, minHeight: 'calc(100vh - 49px)', backgroundColor: theme.palette.background.custom1}}>
                {conditionGroups.map((group, index) => (
                    <React.Fragment key={group.id}>
                        <ConditionGroup
                            privateGroup={!canViewPrivatePatterns && isSomeoneElsesPattern}
                            conditionGroup={group}
                        />
                        {
                            index !== conditionGroups.length - 1 && <CenteredDividerContainer>
                                <Divider textAlign={"center"} sx={{mx: 6}}>
                                    <Chip
                                      label="AND"
                                      size={"small"}
                                    />
                              </Divider>
                            </CenteredDividerContainer>
                        }
                    </React.Fragment>
                ))}
                {(canViewPrivatePatterns || !isSomeoneElsesPattern) && <PhantomConditionGroup/>}
                {props.children}
            </Box>
            {!isMobile && <PatternBuilderActionBar/>}
        </DndProvider>
    );
};
// {/*{index !== conditionGroups.length - 1 && <Box sx={{alignSelf: "flex-start"}} ml={2}>AND</Box>}*/}

export default BuildArea;
