import Indicator from "../types/Indicator";
import {overlayIndicators} from "./index";
import Open from "../Open";
import High from "../High";
import Low from "../Low";
import Close from "../Close";
import Value from "../Value";
import conditionTypes from "../../conditions";

const PivotPoints: Indicator = {
    value: "pivotPoints",
    label: "Pivot Points",
    nickname: "Pivot Points",
    fields: ["pivot", "resistance1", "resistance2", "resistance3", "support1", "support2", "support3"],
    params: {
        pivotPointType: "standard",
        pivotPointContinuous: false,
    },
    RHS: () => [...overlayIndicators, Open, High, Low, Close, Value],
    conditions: conditionTypes,
}


export default PivotPoints;
