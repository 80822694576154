import React, {useContext, useState} from "react";
import {PatternContext} from "../contexts/PatternContext";
import {Box, Typography} from "@mui/material";
import TriggerChartDetailsInput from "./TriggerChartDetailsInput";

const TriggerChartSelector = () => {
    const {
        pattern,
        handleChangePattern,
    } = useContext(PatternContext);

    const [isEditingTriggerChart, setIsEditingTriggerChart] = useState(false)

    return (
        <Box width={isEditingTriggerChart ? '600px' : '210px'} display={"flex"} flexDirection={!isEditingTriggerChart ? "column" : "row"} alignSelf={'flex-start'} gap={1} color={'white'}>
            {!isEditingTriggerChart && <Typography variant={"body1"} alignSelf={"flex-start"}>Trigger
                Chart:</Typography>}
            <TriggerChartDetailsInput trigger={pattern.trigger}
                                      handleChange={(key: string, value: any) => handleChangePattern(`trigger.${key}`, value)}
                                      onEdit={(isEditing => setIsEditingTriggerChart(isEditing))}
            />
        </Box>
    )
}

export default TriggerChartSelector;