// import './wdyr'; // <--- needs to be first import

import React from 'react';
import {BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from "@mui/material/Box";
import {createTheme, ThemeProvider} from "@mui/material";
import {ApplicationSuiteProvider} from "./contexts/ApplicationSuiteProvider";
import MainLayout from "./layout/MainLayout";
import {SnackbarHelper} from "./contexts/SnackbarHelperContext";
import GreenChartSubscriptionRouteGuard from "./guards/GreenChartSubscriptionRouteGuard";


declare module '@mui/material/styles' {
    interface TypeBackground {
        custom1?: string;
        custom2?: string;
        custom3?: string;
        custom4?: string;
    }

    interface TypeAction {
        main?: string;
        background?: string;
        idleBorder?: string;
        idle?: string;
    }

    interface PaletteColor {
        main: string;
        background?: string;
        disabled?: string;
        idleBorder?: string;
        idle?: string;
    }

    interface SimplePaletteColorOptions {
        main: string;
        background?: string;
        disabled?: string;
        idleBorder?: string;
        idle?: string;
    }

    interface PaletteColorOptions {
        main?: string;
        background?: string;
        disabled?: string;
        idleBorder?: string;
        idle?: string;
    }

    interface Palette {
        danger: PaletteColor;
        cyan: PaletteColor;
        green: PaletteColor;
        idleBorder?: string;
        idle?: string;
    }

    interface PaletteOptions {
        danger?: PaletteColorOptions;
        cyan?: PaletteColorOptions;
        green?: PaletteColorOptions;
        idleBorder?: string;
        idle?: string;
    }
}

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            custom1: '#2B2B2B',
            custom2: '#313335',
            custom3: '#3C3F41',
            custom4: '#555758',
        },
        primary: {
            main: '#00a1ff',
        },
        secondary: {
            main: '#00a1ff',
        },
        action: {
            main: '#00a1ff',
            idle: 'rgba(0, 161, 255, .6)',
            background: 'rgba(0, 161, 255, .08)',
            disabled: 'rgba(255, 255, 255, 0.3)',
            idleBorder: 'rgba(0, 161, 255, .3)',
        },
        success: {
            main: '#00ff5c',
            idle: 'rgba(0, 255, 141, .6)',
            background: 'rgba(0, 255, 141, .07)',
            disabled: 'rgba(255, 255, 255, 0.3)',
            idleBorder: 'rgba(0, 255, 141, .3)',
        },
        danger: {
            main: '#f44336',
            idle: 'rgba(244, 67, 54, .6)',
            background: 'rgba(244, 67, 54, .07)',
            disabled: 'rgba(255, 255, 255, 0.3)',
            idleBorder: 'rgba(244, 67, 54, .3)',
        },
        cyan: {
            main: '#00bcd4',
            idle: 'rgba(0, 188, 212, .6)',
            background: 'rgba(0, 188, 212, .07)',
            disabled: 'rgba(255, 255, 255, 0.3)',
            idleBorder: 'rgba(0, 188, 212, .3)',
        },
        green: {
            main: '#1DC30E',
            idle: 'rgba(29, 195, 14, .6)',
            background: 'rgba(29, 195, 14, .07)',
            disabled: 'rgba(255, 255, 255, 0.3)',
            idleBorder: 'rgba(29, 195, 14, .3)',
        }

    },
});


function App() {
    return (
        <Router>
            <Routes>
                <Route path='/*' element={
                    <GreenChartSubscriptionRouteGuard>
                        <ThemeProvider theme={theme}>
                            <SnackbarHelper>
                                <Box className="App">
                                    <ApplicationSuiteProvider>
                                        <MainLayout/>
                                    </ApplicationSuiteProvider>
                                </Box>
                            </SnackbarHelper>
                        </ThemeProvider>
                    </GreenChartSubscriptionRouteGuard>
                }/>
            </Routes>
        </Router>


    );
}


export default App;
