import DialogTitle from "@mui/material/DialogTitle";
import {Box, Dialog, DialogContent, Typography} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import React, {FC, forwardRef, ReactElement, Ref, useContext} from "react";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {SnackbarHelperContext} from "../../../../../contexts/SnackbarHelperContext";


const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface GeneralDialogProps {
    open: boolean;
    title: string;
    content: string;
    onClose: () => void;
}

const ShareDialog: FC<GeneralDialogProps> = ({ open, title, content, onClose }) => {
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>
                            { content }
                        </Typography>
                        <Box sx={{ ml: 1 }}>
                            <IconButton onClick={() => {
                                copyLinkToClipboard( content );
                            }} color="primary">
                                <ContentCopyIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );

    function copyLinkToClipboard(link: string | null) {
        try {
            if (link) {
                navigator.clipboard.writeText(link);
                setSnackbarSettings({message: 'Pattern share link copied to clipboard.', severity: 'success', autoHideDuration: 6000});
            }
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    }
};

export default ShareDialog
