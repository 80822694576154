import BollingerBands from "./BollingerBands";
import SimpleMovingAverage from "./SimpleMovingAverage";
import ExponentialMovingAverage from "./ExponentialMovingAverage";
import AverageTrueRangeBands from "./AverageTrueRangeBands";
import Indicator from "../types/Indicator";
import MovingAverageConvergenceDivergence from "./MovingAverageConvergenceDivergence";
import AverageDirectionalIndex from "./AverageDirectionalIndex.ts";
import Stochastic from "./Stochastic";
import CommodityChannelIndex from "./CommodityChannelIndex";
import RelativeStrengthIndex from "./RelativeStrengthIndex";
import KeltnerChannel from "./KeltnerChannel";
import ParabolicSAR from "./ParabolicSAR";
import AwesomeOscillator from "./AwesomeOscillator";
import WilliamsR from "./WilliamsR";
import AverageTrueRange from "./AverageTrueRange";
import MovingAverageEnvelope from "./MovingAverageEnvelope";
import PivotPoints from "./PivotPoints";
import IchimokuCloud from "./IchimokuCloud";
import DonchianChannel from "./DonchianChannel";

const standardIndicators: Indicator[] = [
    AverageTrueRange,
    AverageTrueRangeBands,
    BollingerBands,
    ExponentialMovingAverage,
    SimpleMovingAverage,
    MovingAverageConvergenceDivergence,
    AverageDirectionalIndex,
    Stochastic,
    CommodityChannelIndex,
    RelativeStrengthIndex,
    KeltnerChannel,
    ParabolicSAR,
    AwesomeOscillator,
    WilliamsR,
    IchimokuCloud,
    MovingAverageEnvelope,
    PivotPoints,
    DonchianChannel,
]


const overlayIndicators: Indicator[] = [
    AverageTrueRange,
    AverageTrueRangeBands,
    BollingerBands,
    ExponentialMovingAverage,
    SimpleMovingAverage,
    KeltnerChannel,
    ParabolicSAR,
    IchimokuCloud,
    MovingAverageEnvelope,
    PivotPoints,
    DonchianChannel,
]

const oscillatorIndicators: Indicator[] = [
    MovingAverageConvergenceDivergence,
    AverageDirectionalIndex,
    Stochastic,
    CommodityChannelIndex,
    RelativeStrengthIndex,
    AwesomeOscillator,
    WilliamsR,
]

export default standardIndicators;
export { overlayIndicators, oscillatorIndicators }
