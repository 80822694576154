import { useContext } from 'react';
import { doc, updateDoc } from "firebase/firestore";
import ReactGA from "react-ga4";
import _cloneDeep from "lodash.clonedeep";
import {firebase_firestore} from "../common/firebaseConfig";
import {defaultPattern, PatternContext} from "../app/builder/contexts/PatternContext";
import {usePattern} from "./usePattern";

const useDeletePattern = () => {
    const { pattern, setPattern } = useContext(PatternContext);
    const [, patternRefPath] = usePattern(pattern.id || undefined);

    const deletePattern = async () => {
        ReactGA.event('pattern_delete', {
            author: pattern.author,
            pattern_name: pattern.name
        });
        await updateDoc(doc(firebase_firestore, patternRefPath), { deleted: true, active: false });
        setPattern(_cloneDeep(defaultPattern));
    };

    return { deletePattern };
};

export default useDeletePattern;
