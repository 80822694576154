//  !!! DUPLICATION WARNING !!!
//  The files within the symbolSelectDialog are duplicated between
//  gc_chartiq AND pattern-builder
//  When updating this file, the other should be updated as well

import React, {useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import TypeFilterChips from "./components/TypeFilterChips";
import {CircularProgress, InputAdornment, Typography, useMediaQuery, useTheme} from "@mui/material";
import SymbolList from "./components/SymbolList";
import useSearch from "./hooks/dialog/useSearch";
import useTypeFilter from './hooks/dialog/useTypeFilter';
import useFilteredSymbols from "./hooks/dialog/useFilteredSymbols";
import useKeyboardInteraction from "./hooks/dialog/useKeyboardInteraction";
import useKeyboardNavigation from "./hooks/dialog/useKeyboardNavigation";

interface ISymbolSelectProps {
    symbols: Array<{ name: string, type: string }>;
    onSymbolSelect: (SymbolResult: ISymbol) => void;
    types: string[];
    filteredTypes: string[];
    isOpen: boolean;
    toggleDialog: () => void;
    recentSymbols: ISymbol[];
    callerTitle?: string;
    optionalFooter?: React.ReactNode;
}

export interface ISymbol {
    name: string;
    type: string;
    params?: {
        contractSize?: number;
        decimals?: number;
    }
}

const SymbolSelect: React.FC<ISymbolSelectProps> = ({  symbols, onSymbolSelect, types, filteredTypes, isOpen, toggleDialog, recentSymbols, callerTitle, optionalFooter }) => {
    const { selectedType, handleTypeClick, isTypeSelected, resetTypeFilter, isTypeEnabled } = useTypeFilter(types, filteredTypes);
    const { searchQuery, handleSearchChange, resetSearch } = useSearch();
    const [selectedIndex, setSelectedIndex] = React.useState(0); // Example state for selected index
    const [loading, setLoading] = useState(true);
    const dialogTitle = 'Symbol Search';

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const listRef = useRef<{ scrollToItem: (index: number, align?: 'auto' | 'smart' | 'center' | 'end' | 'start') => void }>(null);
    const searchInputRef = useRef<HTMLInputElement>(null); // Ref for the search input

    // Reset the filters when the dialog is closed
    useEffect(() => {
        if (isOpen === true) return;

        setTimeout(() => {
            resetTypeFilter();
            resetSearch();
            setSelectedIndex(0);
        }, 100);

    }, [isOpen, resetSearch, resetTypeFilter]);

    // Scrolling the list to the selected item
    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollToItem(selectedIndex, 'auto');
        }
    }, [selectedIndex]);

    // Loading handler
    useEffect(() => {
        if (symbols.length) {
            setLoading(false);
        }
    }, [symbols]);

    const filteredSymbols = useFilteredSymbols(symbols, selectedType, searchQuery, recentSymbols);

    const handleKeyDown = useKeyboardNavigation({
        selectedIndex,
        setSelectedIndex,
        filteredSymbols,
        onSymbolSelect,
        isOpen
    });

    useKeyboardInteraction(isOpen, searchInputRef, handleKeyDown);

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={toggleDialog}
                fullWidth
                fullScreen={fullScreen}
                BackdropProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
                onKeyDown={handleKeyDown}
            >
                <DialogTitle sx={{ padding: '16px 24px 8px' }}>
                    <Typography variant="h6" component="div" sx={{fontSize: '22px'}}>
                        { dialogTitle }
                    </Typography>
                    <Typography variant="subtitle2" component="div" style={{ fontSize: '14px', fontWeight: 300 }}>
                        { callerTitle }
                    </Typography>
                    <IconButton aria-label="close" onClick={toggleDialog} sx={{ position: 'absolute', right: 8, top: 8 }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', height: '600px', overflowY: 'hidden', position: 'relative' }}>
                    { loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', position: 'absolute', width: '100%', top: 0, left: 0, zIndex: 2 }}>
                            <CircularProgress sx={{color: '#008000'}} color="success" size={"4rem"}/>
                        </div>
                    ) : (
                        <>
                            <TypeFilterChips
                                types={types}
                                filteredTypes={filteredTypes}
                                handleTypeClick={handleTypeClick}
                                isTypeSelected={isTypeSelected}
                                isTypeEnabled={isTypeEnabled}
                            />
                            <TextField
                                inputRef={searchInputRef}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                onChange={handleSearchChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        fontFamily: 'Roboto, sans-serif',
                                        fontWeight: 300,
                                        color: 'white',
                                        paddingLeft: '0px',
                                        backgroundColor: '#565758',
                                    },
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '34px',
                                        '& fieldset': {
                                            borderColor: '#7b7c7e',
                                            borderWidth: '1px',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#7b7c7e !important', // maintain the same border color on hover
                                            borderWidth: '1px',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#01a1ff !important',
                                            borderWidth: '2px !important',
                                        },
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: '0 10px',
                                        height: 'calc(100% - 2px)',
                                    },
                                    '& .MuiOutlinedInput-root:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(0, 0, 0, 0.23)', // maintain the same border color on hover
                                        backgroundColor: 'transparent', // remove background color on hover
                                    },
                                    '& .MuiInputAdornment-root': {
                                        marginRight: '0px !important', // Override the marginRight style
                                    },
                                    // Night mode specific overrides to maintain day mode styles
                                    '.ciq-night & .MuiOutlinedInput-root, .ciq-night & .MuiOutlinedInput-input, .ciq-night & .share-copy .share-link-div': {
                                        borderColor: 'rgba(0, 0, 0, 0.23)', // Override border color for night mode
                                        backgroundColor: 'transparent', // Override background color for night mode
                                        color: '#071931', // Override text color for night mode
                                    },
                                }}
                            />
                            <SymbolList
                                symbols={filteredSymbols}
                                selectedIndex={selectedIndex}
                                onSymbolSelect={onSymbolSelect}
                                ref={listRef}
                            />
                        </>
                    )}
                </DialogContent>
                { optionalFooter }
            </Dialog>
        </>
    );
};

export default SymbolSelect;
