import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import LockIcon from "@mui/icons-material/Lock";
import {Typography} from "@mui/material";
import {GreenChartTooltip} from "./GreenChartTooltip";

type Item = {
    label: any,
    action?: () => void,
    color?: string,
    display?: boolean,
    locked?: boolean,
    icon?: any,
}

export type GC_Menu_Props = {
    items: Item[],
    title: any,
    styles?: { [key: string]: any },
}

export default function GCMenu({items, title, styles}: GC_Menu_Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const theme = useTheme();

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <IconButton
                sx={styles}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {title}
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        background: theme.palette.background.custom3,
                    }
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    items
                        .filter(item => item.display !== false)
                        .map((item, index) => (
                        <MenuItem sx={{color: item.color}} key={index} onClick={() => {
                            handleClose();
                            if (item.action && !item.locked) {
                                item.action()
                            }
                        }}>
                            <Box display={'flex'} gap={1}>
                                {!item.locked && item.icon && item.icon}
                                {item.locked &&
                                  <GreenChartTooltip title={<Typography variant={"body2"}>Upgrade to a higher tier to access.</Typography>} arrow>
                                    <a href="https://myaccount.greenchart.com/dashboard/marketplace?display=membership"
                                        target="_blank"
                                        style={{ textDecoration: 'none', display: 'block', width: '25%' }} rel="noreferrer">
                                         <LockIcon style={{ color: theme.palette.grey[500]}} />
                                    </a>
                                  </GreenChartTooltip>
                                }
                                <Typography>{item.label}</Typography>
                            </Box>
                        </MenuItem>
                    ))
                }
            </Menu>
        </Box>
    );
}
