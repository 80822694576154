import React from 'react';
import ActionBar from "../../../../common/components/actionBar/ActionBar";
import NavigationSelect from "../../../../components/NavigationSelect";
import TradingHouseSelector from "../../../../components/TradingHouseSelector";
import {Box} from "@mui/material";
import RemainingBalance from "../../../../components/RemainingBalance";
import {useApplicationSuiteContext} from "../../../../contexts/ApplicationSuiteProvider";

const TrainingHeaderBar = () => {

    const { canToggleTradingHouse } = useApplicationSuiteContext();

    return (
        <>
            <ActionBar
                position={'top'}
                left={
                    <Box display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'}>
                        <NavigationSelect />
                        {canToggleTradingHouse && <TradingHouseSelector/>}
                    </Box>
                }
                right={<RemainingBalance />}
            />
        </>
    );
}

export default TrainingHeaderBar;
