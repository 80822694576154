import {useApplicationSuiteContext} from "../../../../contexts/ApplicationSuiteProvider";
import React, {useContext, useState} from "react";
import {PatternContext} from "../../contexts/PatternContext";
import useSavePattern from "../../../../hooks/useSavePattern";
import {GreenChartTooltip} from "../../../../common/components/GreenChartTooltip";
import {collection, getDocs, query} from "firebase/firestore";
import {firebase_firestore} from "../../../../common/firebaseConfig";
import {Box} from "@mui/material";
import OkayDialog from "../sidebar/patternInput/OkayDialog";
import SSButton from "../../../../common/components/actionButton/SSButton";
import SaveAsIcon from "@mui/icons-material/SaveAs";

const SavePatternAsButton = (props: {disabled: boolean}) => {
    const {disabled} = props;

    const {
        usermeta,
    } = useApplicationSuiteContext();

    const {
        pattern,
    } = useContext(PatternContext);

    const [isPatternLimitReachedDialogOpen, setIsPatternLimitReachedDialogOpen] = useState<boolean>(false);

    const { savePattern } = useSavePattern();

    return (
        <GreenChartTooltip
            title={disabled ? "All conditions must be complete" : "Save pattern as"} arrow
            key={"saveAsButton"}
        >
            <Box>
                <OkayDialog
                    open={isPatternLimitReachedDialogOpen}
                    title={disabled ? "All conditions must be complete" : "100 Pattern Limit Reached"}
                    description="You have reached the maximum number of patterns. Please delete some patterns before creating a new one."
                    onClose={() => setIsPatternLimitReachedDialogOpen(false)}
                    onOkay={() => setIsPatternLimitReachedDialogOpen(false)}
                />
                <SSButton
                    type={'green'}
                    disabled={disabled}
                    onClick={async () => {
                        if (disabled) {
                            return;
                        }
                        const patterns = await getDocs(query(collection(firebase_firestore, `usermeta/${usermeta.gcid}/patterns`)));
                        const activePatterns = patterns.docs.filter(doc => !doc.data().deleted);
                        if (activePatterns.length < 100) {
                            pattern.id = undefined
                            pattern.version = 1;
                            pattern.alertVersion = 1;
                            savePattern();
                        } else {
                            setIsPatternLimitReachedDialogOpen(true);
                        }
                    }}
                >
                    <SaveAsIcon />
                </SSButton>
            </Box>
        </GreenChartTooltip>
    )
}

export default SavePatternAsButton;