import {FormControl, Grid, Switch} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import ATRExitStrategyInput from "./AtrExitStrategyInput";
import PeakValleyExitStrategyInput from "./PeakValleyExitStrategyInput";
import FixedExitStrategyInput from "./FixedExitStrategyInput";
import RiskRewardInput from "./RiskRewardInput";
import IncrementalExitStrategyInput from "./IncrementalExitStrategyInput";
import DynamicTakeProfitInput from "./DynamicTakeProfitInput";
import ExitStrategiesProps from "./ExitStrategiesProps";


const defaultDynamicTakeProfitInputData = {
    type: "atr",
    atrRecalculationPeriod: 5,
    atrRecalculationTimeframe: "minute",
    atrPeriod: 15,
    atrChartPeriod: 1,
    atrChartTimeframe: "minute",
    allowIncrease: true,
    allowDecrease: true,
}



function DynamicTakeProfit(props : ExitStrategiesProps) {
    const {backtestSettingInputData, handleInputChange} = props;

    return (
        <>
            <Grid item xs={12} display={"flex"} alignItems={"center"}>
                <Typography variant={"body1"}>Periodically modify take profit</Typography>
                <FormControl>
                    <Switch
                        checked={!!backtestSettingInputData.analyzeTrades.dynamicTakeProfit}
                        onChange={(e) => handleInputChange("analyzeTrades.dynamicTakeProfit", e.target.checked ? defaultDynamicTakeProfitInputData : null)}
                        color="primary"
                    />
                </FormControl>
            </Grid>
            {
                backtestSettingInputData.analyzeTrades.dynamicTakeProfit
                    ? <DynamicTakeProfitInput {...props}/>
                    : null
            }
        </>
    )
}



export default function ExitStrategyInputs(props: ExitStrategiesProps) {
    const {backtestSettingInputData} = props;
    const { exitStrategy } = backtestSettingInputData.generateTrades;

    switch (exitStrategy) {
        case "atr":
            return <>
                <RiskRewardInput {...props}/>
                <ATRExitStrategyInput {...props}/>
                <DynamicTakeProfit {...props} />
            </>
        case "incremental":
            return <>
                <RiskRewardInput {...props}/>
                <IncrementalExitStrategyInput {...props}/>
                <DynamicTakeProfit {...props} />
            </>
        case "fixed":
            return <>
                <FixedExitStrategyInput {...props}/>
                <DynamicTakeProfit {...props} />
            </>
        case "peak_valley":
            return <>
                <RiskRewardInput {...props}/>
                <PeakValleyExitStrategyInput {...props}/>
                <DynamicTakeProfit {...props} />
            </>
        default:
            return null;
    }

}
