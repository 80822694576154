import Indicator from "../../types/Indicator";
import Value from "../../Value";
import conditionTypes from "../../../conditions";

const ThinOrange: Indicator = {
    value: "thinOrange",
    label: "Thin Orange",
    fields: ["thinOrange"],
    RHS: () => [Value],
    conditions: conditionTypes,

};

export default ThinOrange;

