import Indicator from "../types/Indicator";
import {overlayIndicators} from "./index";
import Open from "../Open";
import High from "../High";
import Low from "../Low";
import Close from "../Close";
import Value from "../Value";
import conditionTypes from "../../conditions";

const BollingerBands: Indicator = {
    value: "bollingerBands",
    label: "Bollinger Bands",
    nickname: "Bollinger Bands",
    fields: ["middle", "upper", "lower"],
    params: {
        field: "close",
        period: 20,
        standardDeviations: 2,
    },
    RHS: () => [...overlayIndicators, Open, High, Low, Close, Value],
    conditions: conditionTypes,
}


export default BollingerBands;
