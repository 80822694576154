import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {Box} from '@mui/material';
import SymbolItem from './SymbolItem';
import { ISymbol } from '../SymbolSelect';

type SymbolListProps = {
    symbols: Array<{ name: string; type: string, category?: string, description?: string }>;
    selectedIndex: number;
    onSymbolSelect: (symbol: ISymbol) => void;
};

interface RowProps extends ListChildComponentProps {
    index: number,
    style: any,
    data: {
        symbols: Array<{ name: string; type: string; category?: string; description?: string }>;
        selectedIndex: number;
        onSymbolSelect: (symbol: ISymbol) => void;
    };
}

const Row: React.FC<RowProps> = ({ index, style, data }) => {
    const { symbols, onSymbolSelect, selectedIndex } = data;
    const symbol = symbols[index];
    const selected = selectedIndex === index;

    return (
        <div style={{ ...style }} key={ index }>
            <SymbolItem
                symbol={symbol}
                onClick={() => onSymbolSelect(symbol)}
                selected={selected}
                dataIndex={index}
            />
        </div>
    );
};

const SymbolList = forwardRef((props: SymbolListProps, ref) => {
    const { symbols, selectedIndex, onSymbolSelect } = props;
    const listRef = useRef<List>(null);

    // This allows the parent component to call scrollToItem on this ref
    useImperativeHandle(ref, () => ({
        scrollToItem: (index: number, align: 'auto' | 'smart' | 'center' | 'end' | 'start' = 'auto') => {
            listRef.current?.scrollToItem(index, align);
        }
    }));

    return (
        <Box style={{ height: 600, width: '100%', boxShadow: 'none' }}>
            <AutoSizer>
                {({ height, width }: { height: number; width: number }) => (
                    <List
                        ref={listRef}
                        height={height}
                        width={width}
                        itemCount={symbols.length}
                        itemSize={44}
                        itemData={{ symbols, selectedIndex, onSymbolSelect }}
                    >
                        {Row}
                    </List>
                )}
            </AutoSizer>
        </Box>
    );
});

export default SymbolList;
