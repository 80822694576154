import {ISymbol} from "../components/dialogs/symbolSelectDialog/SymbolSelect";

enum SymbolType {
    Forex = 'forex',
    Crypto = 'crypto',
    Stock = 'stock',
    Index = 'index',
}

function getContractSize(symbolObject: ISymbol) {

    if (!symbolObject) return 1;

    // return the saved contract size if it exists
    const contractSizeMap = JSON.parse(localStorage.getItem('contractSize') || '{}');
    if (contractSizeMap && contractSizeMap[symbolObject.name]) {
        return contractSizeMap[symbolObject.name];
    }

    // return the contract size if it exists
    if (symbolObject.params && symbolObject.params.contractSize) {
        return symbolObject.params.contractSize;
    }

    // return the default contract size based on the symbol type
    switch(symbolObject.type) {
        case SymbolType.Forex:
            return 100000;
        case SymbolType.Crypto:
            return 1;
        case SymbolType.Stock:
            return 1;
        case SymbolType.Index:
            return 1;
        default:
            return 1;
    }
}

export default getContractSize;
