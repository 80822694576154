import { useState, useEffect } from 'react';
import {ISymbol} from "../../SymbolSelect";

const MAX_RECENT_SYMBOLS = 5;
const LOCAL_STORAGE_KEY = 'recentSymbols';

const useRecentlySelectedSymbols = () => {
    // Initialize state from localStorage
    const [recentSymbols, setRecentSymbols] = useState(() => {
        const savedSymbols = localStorage.getItem(LOCAL_STORAGE_KEY);
        return savedSymbols ? JSON.parse(savedSymbols) : [];
    });

    useEffect(() => {
        // Update localStorage whenever recentSymbols changes
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(recentSymbols));
    }, [recentSymbols]);

    const addRecentSymbol = (newSymbol: ISymbol) => {
        setRecentSymbols((prevSymbols: ISymbol[]) => {
            const filtered = prevSymbols.filter((symbol: ISymbol) => symbol.name !== newSymbol.name);
            const updated = [newSymbol, ...filtered].slice(0, MAX_RECENT_SYMBOLS);
            return updated;
        });
    };

    return { recentSymbols, addRecentSymbol };
};

export default useRecentlySelectedSymbols;