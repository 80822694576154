import React, {createContext, useContext, ReactNode, useState} from 'react';
import ConfirmDialog from "../components/backtestList/ConfirmDialog";
import {firebase_functions} from "../../../common/firebaseConfig";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {SnackbarHelperContext} from "../../../contexts/SnackbarHelperContext";

interface BacktestReportContextProps {
    backtestToView: string | undefined;
    setBacktestToView: React.Dispatch<React.SetStateAction<string | undefined>>;
    dialogOpen: {type: string, data: { id: string, backup?: boolean }} | undefined;
    setOpenDialog: React.Dispatch<React.SetStateAction<{type: string, data: { id: string, backup?: boolean }} | undefined>>;
}

export const BacktestReportContext = createContext<BacktestReportContextProps | undefined>(undefined);

interface Props {
    children: ReactNode;
}

export const BacktestReportProvider: React.FC<Props> = ({ children }) => {
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    const [backtestToView, setBacktestToView] = useState<string>();
    const [dialogOpen, setOpenDialog] = useState<{type: string, data: { id: string, backup?: boolean }}>();


    const [deleteBacktestCallable] = useHttpsCallable(firebase_functions, "deleteBacktest")


    return (
        <BacktestReportContext.Provider value={{ dialogOpen, setOpenDialog, backtestToView, setBacktestToView, }}>
            {children}


            <ConfirmDialog
                open={!!dialogOpen && dialogOpen.type === 'delete'}
                title="Confirm Backtest Deletion"
                description="By proceeding, all trades linked to this backtest and the summary will be deleted."
                onClose={() => setOpenDialog(undefined)}
                onConfirm={() => {
                    setOpenDialog(undefined);
                    if (dialogOpen && dialogOpen.data && dialogOpen.data.id) {
                        deleteBacktestCallable({backtestId: dialogOpen.data.id, backup: dialogOpen.data.backup});
                        setSnackbarSettings({severity: 'success', message: "Deleted backtest.", autoHideDuration: 6000})
                    }
                }}
            />

        </BacktestReportContext.Provider>
    );
}

// Custom hook to use this contexts
export const useBacktestReportContext = (): BacktestReportContextProps => {
    const context = useContext(BacktestReportContext);
    if (!context) {
        throw new Error('useBacktestBacktestContext must be used within a BacktestReportProvider');
    }
    return context;
}
