import {
    Box
} from "@mui/material";
import React, { useEffect, useState} from "react";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, orderBy, query, getDocs, limit, startAfter, where} from "firebase/firestore";
import { firebase_firestore} from "../../../../common/firebaseConfig";
import BacktestListTable from "./BacktestListTable";
import {useApplicationSuiteContext} from "../../../../contexts/ApplicationSuiteProvider";

export default function BacktestList() {
    const {
        usermeta,
        tradingHouse,
    } = useApplicationSuiteContext()

    const [backtests, , , backtestQuerySnapshot] = useCollectionData(
        query(
            collection(firebase_firestore, `usermeta/${usermeta.gcid}/backtests`),
            where("backtestSettings.tradingHouse", "==", tradingHouse),
            orderBy("startTime", "desc"),
            limit(20))
    );

    const [backtestReportList, setBacktestReportList] = useState<any[]>(backtests || []);
    const [lastBacktestDoc, setLastBacktestDoc] = useState<any>(backtestQuerySnapshot?.docs[backtestQuerySnapshot?.docs.length - 1]);

    useEffect(() => {
        if (!backtestQuerySnapshot || backtestQuerySnapshot.empty) return;

        setBacktestReportList(backtestQuerySnapshot?.docs.map((doc) => doc.data()) || []);
        setLastBacktestDoc(backtestQuerySnapshot?.docs[backtestQuerySnapshot?.docs.length - 1]);
    }, [backtestQuerySnapshot]);

    async function fetchData() {
        if (lastBacktestDoc) {
            try {
                let results = await getDocs(
                    query(
                        collection(firebase_firestore, `usermeta/${usermeta.gcid}/backtests`),
                        where("backtestSettings.tradingHouse", "==", tradingHouse),
                        orderBy("startTime", "desc"),
                        limit(10),
                        startAfter(lastBacktestDoc)
                    )
                )
                setBacktestReportList((backtestReportList) => [...backtestReportList, ...results.docs.map((doc: any) => doc.data())]);
                setLastBacktestDoc(results.docs[results.docs.length - 1] || null)
            } catch (e) {
                console.error(e)
            }
        }
    }

    return (
        <>
            <Box sx={{ overflowY: "auto"}}>
                <BacktestListTable backtests={backtestReportList} loadMore={fetchData}/>
            </Box>
        </>
    )
}
