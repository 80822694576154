import React, {useContext, useEffect, useState} from "react";
import {PatternContext} from "../../../contexts/PatternContext";
import * as jsondiffpatch from "jsondiffpatch";
import { diff_match_patch } from 'diff-match-patch';
import Drawer from "@mui/material/Drawer";
import {Box, Button, Collapse, FormControl, MenuItem, Select, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {collection, doc, getDoc, getDocs, orderBy, query} from "firebase/firestore";
import {firebase_firestore} from "../../../../../common/firebaseConfig";
import ReactGA from "react-ga4";
import {useApplicationSuiteContext} from "../../../../../contexts/ApplicationSuiteProvider";

const jsondiffpatchInstance = jsondiffpatch.create({
    textDiff: {
        diffMatchPatch: diff_match_patch,
    },
});

export function PatternVersionView() {
    const [contentVisible, setContentVisible] = useState(false);
    const [versions, setVersions] = useState<any[]>([])

    const {
        setPattern,
        pattern,
    } = useContext(PatternContext);

    const {
        usermeta,
        tradingHouse
    } = useApplicationSuiteContext()


    const fetchVersions = async (path: string) => {
        const q = query(collection(firebase_firestore, path), orderBy("version"));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => doc.data());
    };

    useEffect(() => {
        const userDocPath = `usermeta/${usermeta.gcid}/patterns/${pattern.id}`;
        const userDocRef = doc(firebase_firestore, userDocPath);

        getDoc(userDocRef).then(docSnapshot => {
            const versionsPath = docSnapshot.exists() ?
                `${userDocPath}/versions` :
                `tradingHouses/${tradingHouse}/standardPatterns/${pattern.id}/versions`;

            return fetchVersions(versionsPath);
        }).then(docs => {
            setVersions(docs);
        });

    }, [usermeta.gcid, pattern.version, pattern.id, tradingHouse]);

    const toggleDrawerContent = () => {
        setContentVisible(prev => !prev);
    }

    /**
     * Reconstructs an object to the given version using a history of diffs.
     * @param {number} targetVersion - The version to reconstruct.
     * @param {Array} histories - The array of history objects, each with a version and diff.
     * @returns {Object} - The reconstructed object at the specified version.
     */
    function reconstructToVersion(targetVersion: number, histories: any[]) {
        // Check if target version is valid
        const latestVersion = histories[histories.length - 1].version;
        if (targetVersion > latestVersion) {
            console.warn("The target version is greater than the latest version in the histories provided.");
            return null;
        }

        let obj = jsondiffpatchInstance.patch({}, JSON.parse(histories[0].diff));  // Start with version 1

        // Apply each diff sequentially until we reach the desired version
        for (let i = 1; i < targetVersion; i++) {
            obj = jsondiffpatchInstance.patch(obj, JSON.parse(histories[i].diff));
        }

        return obj;
    }

    return (
        pattern.id && versions && versions.length ?
            <Box  sx={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: 83,
                borderTop: '1px solid gray',
                backgroundColor: '#fff'
            }}>
                <Drawer
                    variant="permanent"
                    anchor="bottom"
                    open={true}
                    sx={{height: 83}}
                >
                    <Box>
                        <Button
                            fullWidth
                            onClick={toggleDrawerContent}
                            sx={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                            <Box display="flex" flexGrow={1} style={{textAlign: "left", flexDirection: 'column'}} gap={1}>
                                <Typography variant={"body1"} color={'rgb(0, 161, 255)'}>Current Versions</Typography>
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Typography variant={"caption"} color={'rgb(0, 161, 255)'}><b>Pattern:</b> v{pattern.version}</Typography>
                                    <Typography variant={"caption"} color={'rgb(0, 161, 255)'}><b>Alert:</b> v{pattern.alertVersion || 1}</Typography>
                                </Box>
                            </Box>
                            {contentVisible ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </Button>

                        <Collapse in={contentVisible}>
                            <Box style={{textAlign: "left", padding: '8px 12px'}}>
                                <Typography variant={"body1"}>View a previous version</Typography>

                                <FormControl fullWidth >
                                    <Select
                                        variant={"standard"}
                                        value={pattern.version}
                                        onChange={(selectedVersion: any) => {
                                            const selectedVersionId = selectedVersion.target.value

                                            const newPattern = reconstructToVersion(selectedVersionId, versions)

                                            if (newPattern) {
                                                ReactGA.event('pattern_viewed_previous_version', {
                                                    version: selectedVersionId
                                                });

                                                newPattern.alertVersion = pattern.alertVersion || 1
                                                setPattern(newPattern)
                                            }
                                        }}
                                        label="View previous version"
                                    >
                                        {versions.map((versionObj: any) => (
                                            <MenuItem key={versionObj.version} value={versionObj.version}>
                                                v{versionObj.version}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Collapse>
                    </Box>
                </Drawer>
            </Box>
         : null
    );
}

