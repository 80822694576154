const IndicatorParameterInputs: {[label: string]: IndicatorParameterInput} = {
    period: {    // Simple Moving Average, Exponential Moving Average, Bollinger Bands, ATR Bands, ADX, Commodities Channel Index, RSI, WilliamsR, Keltner Channel, Average True Range
        type: "number",
        label: "Period",
    },
    field: { // Simple Moving Average, Exponential Moving Average, Bollinger Bands, Stochastics, RSI
        type: "select",
        label: "Field",
        options: [
            {value: "open", label: "Open"},
            {value: "high", label: "High"},
            {value: "low", label: "Low"},
            {value: "close", label: "Close"},
            {value: "hl/2", label: "HL/2"},
            {value: "hlc/3", label: "HLC/3"},
            {value: "ohlc/4", label: "OHLC/4"},
        ]
    },
    standardDeviations: {// Bollinger Bands
        type: "number",
        label: "Standard Deviations",
    },
    multiplier: {// ATR Bands, Keltner Channel, Moving Average Envelope
        type: "number",
        label: "Shift",
        step: .1,
    },
    fastPeriod: {// MACD, Awesome Oscillator
        type: "number",
        label: "Fast MA Period",
    },
    slowPeriod: {// MACD, Awesome Oscillator
        type: "number",
        label: "Slow MA Period",
    },
    signalPeriod: {// MACD
        type: "number",
        label: "Signal Period",
    },
    smoothingPeriod: { //ADX
        type: "number",
        label: "Smoothing Period",
    },
    kPeriod: {// stochastics
        type: "number",
        label: "K Period",
    },
    dPeriod: {// stochastics
        type: "number",
        label: "D Period",
    },
    kSmoothingPeriod: {// stochastics
        type: "number",
        label: "K Smoothing Period",
    },
    smooth: {// stochastics
        type: "boolean",
        label: "Smooth",
    },
    step: {// Parabolic SAR
        type: "number",
        label: "Minimum AF",
    },
    max: {// Parabolic SAR
        type: "number",
        label: "Maximum AF",
    },
    conversionPeriod: {// Ichimoku Cloud
        type: "number",
        label: "Conversion Line Period",
    },
    basePeriod: {// Ichimoku Cloud
        type: "number",
        label: "Base Line Period",
    },
    spanPeriod: {// Ichimoku Cloud
        type: "number",
        label: "Leading Span B Period",
    },
    displacement: {// Ichimoku Cloud
        type: "number",
        label: "Lagging Span Period",
    },
    movingAverageType: {// Moving Average Envelope
        type: "select",
        label: "Moving Average Type",
        options: [
            {value: "simple", label: "Simple"},
            {value: "exponential", label: "Exponential"},
            // {value: "weighted", label: "Weighted"},
            // {value: "wilders", label: "Wilders"},
        ]
    },
    multiplierType: {// Moving Average Envelope
        type: "select",
        label: "Shift Type",
        options: [
            {value: "percent", label: "Percent"},
            {value: "points", label: "Points"},
        ]
    },
    pivotPointType: { // Pivot Points
        type: "select",
        label: "Type",
        options: [
            {value: "standard", label: "Standard"},
            {value: "fibonacci", label: "Fibonacci"},
        ]
    },
    pivotPointContinuous: { // Pivot Points
        type: "boolean",
        label: "Continuous",
    },
    highPeriod: { // Donchian Channel
        type: "number",
        label: "High Period",
    },
    lowPeriod: { // Donchian Channel
        type: "number",
        label: "Low Period",
    },
};


type NumberInputParameter = {
    type: "number",
    label: string,
    step?: number,
}

type SelectInputParameter = {
    type: "select",
    label: string,
    options: {value: string, label: string}[],
}

type BooleanInputParameter = {
    type: "boolean",
    label: string,
}

type IndicatorParameterInput = NumberInputParameter | SelectInputParameter | BooleanInputParameter;


export default IndicatorParameterInputs;
