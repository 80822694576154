import Grid from "@mui/material/Grid";
import PatternBuilderInstructionsCard from "./PatternBuilderInstructionsCard";
import PatternBuilderUsesCard from "./PatternBuilderUsesCard";
import PatternBuilderFeaturesToComeCard from "./PatternBuilderFeaturesToComeCard";
import {useContext, useEffect, useState} from "react";
import {PatternTreeContext} from "../contexts/PatternTreeContext";
import "./AdArea.css";

function AdArea() {
    const [showAd, setShowAd] = useState(true);
    const [isDisplayed, setIsDisplayed] = useState(true);  // For rendering in the DOM

    const {
        conditionGroups
    } = useContext(PatternTreeContext);

    useEffect(() => {
        // Initial fade-in when component mounts
        if (!conditionGroups || conditionGroups.length === 0) {
            setIsDisplayed(true);  // Ensure the component is displayed
            setShowAd(true);  // Trigger fade-in
        } else {
            setShowAd(false);  // Trigger fade-out
        }
    }, [conditionGroups]);

    // This function will be called when any animation ends
    const handleAnimationEnd = () => {
        if (!showAd) {
            setIsDisplayed(false);  // If it was a fade-out, remove from DOM
        }
    };

    if (!isDisplayed) {
        return null;  // Don't render the component
    }

    return (
        <Grid container className={`${showAd ? 'fade-in' : 'fade-out'}`} onAnimationEnd={handleAnimationEnd} spacing={2} px={2} pb={2} alignItems="stretch">
            <Grid item xs={12} md={5} style={{ display: 'flex', flexDirection: 'column'}}>
                <PatternBuilderInstructionsCard/>
            </Grid>
            <Grid container item xs={12} md={7} spacing={2}>
                <Grid item xs={12}>
                    <PatternBuilderUsesCard/>
                </Grid>
                <Grid item xs={12}>
                    <PatternBuilderFeaturesToComeCard/>
                </Grid>
            </Grid>
        </Grid>

    );
}

export default AdArea;
