import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {useApplicationSuiteContext} from "../contexts/ApplicationSuiteProvider";

const WelcomeDialog = () => {

    const {
        hasStarterTrialAccess,
    } = useApplicationSuiteContext();

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    function openMarketplace() {
        window.open('https://myaccount.greenchart.com/dashboard/marketplace?display=Strategy%20Suite', '_blank');
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Welcome to Strategy Suite!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Here's what you can do:
                    <ul>
                        {
                            hasStarterTrialAccess
                                ? <li><b>Showcase:</b> View and copy winning strategies from members.</li>
                                : <li><b>Showcase:</b> View winning strategies from members.</li>
                        }
                        <li><b>Pattern Builder:</b> Build a winning strategy.</li>
                        <li><b>Backtester:</b> Simulate your strategy with historical data.</li>
                        <li><b>Reports:</b> Gain valuable insights and refine your strategy.</li>
                        <li><b>Alerts:</b> Get buy/sell notifications.</li>
                    </ul>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '16px'

            }}>
                <Button onClick={openMarketplace} variant={"contained"} color={"primary"}>Upgrade</Button>
                <Button onClick={handleClose} color="primary">
                    Got it!
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WelcomeDialog;
