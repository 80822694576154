import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

const CreationButtonContainer = styled(Box)((props: any)=> ({
    width: 50,
    height: "100%",
    transition: 'all 0.3s ease-in-out',
    cursor: 'copy',
    border: '1px dotted rgba(0, 161, 255, .4)',
    borderRadius: 8,
    color: props.theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '&:hover': {
        border: `1px solid ${props.theme.palette.secondary.main}`,
        color: props.theme.palette.secondary.main,
        backgroundColor: 'rgba(0, 161, 255, .1)',
    },
    ...props.sx,
}));

export default CreationButtonContainer;
