import React, {useContext, useEffect, useRef} from 'react';
import { useDrag } from 'react-dnd';
import ConditionSelector from "./ConditionSelector";
import { PatternTreeContext} from "../contexts/PatternTreeContext";
import {Chip, Paper, Tooltip} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UndoIcon from '@mui/icons-material/Undo';
import "./Condition.css"
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {styled, useTheme} from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import {Star} from "@mui/icons-material";
import GCMenu from "../../../common/components/GCMenu";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from "@mui/material/IconButton";
import {v4 as uuidv4} from "uuid";
import Overlay from "../components/overlay/Overlay";
import ReactGA from "react-ga4";
import ConditionTypes from "./conditions";
import {PatternContext} from "../contexts/PatternContext";

const CenteredDividerContainer = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
        marginTop: theme.spacing(2),
    },
}));

const ConditionHeader = styled(Box)(({ theme }) => ({
    '&:hover': {
        background: `linear-gradient(0deg, ${theme.palette.background.custom3}, ${theme.palette.background.custom4})`,
    },
}));

const TextualConditionView = styled(Box)(({ theme, viewonly }: any) => ({
    '&:hover': {
        color: !viewonly ? theme.palette.secondary.main : '',
        cursor: !viewonly ? 'pointer' : ''
    },
}));

const Condition = ({condition, sourceGroupId, field, operator, value, index, depth, viewOnly }: any) => {

    const {
        getIndicatorByValue,
    } = useContext(PatternContext);

    const {
        handleChangeCondition,
        handleRemoveCondition,
        handleConditionDuplicate,
    } = useContext(PatternTreeContext);

    const [isUnfinishedCondition, setUnfinishedCondition] = React.useState(!condition.condition.isValid);
    const paperRef = useRef(null);
    const theme = useTheme();
    const [textualConditionView, setTextualConditionView] = React.useState(viewOnly || condition.condition.isValid);

    useEffect(() => {
        if (!viewOnly) {
            // @ts-ignore
            paperRef.current?.focus();
        }
    }, [viewOnly]);

    useEffect(() => {
        setUnfinishedCondition(!condition.condition.isValid);
    }, [condition.condition.isValid]);

    const [, drag] = useDrag(() => ({
        type: 'condition',
        item: { id: condition.id, type: 'condition', sourceGroupId, field, operator, value, index },
    }));

    function _onChangeCondition(key: string, value: any) {
        if (key === "LHS.indicator.name") {
            handleChangeCondition(sourceGroupId, condition.id, `condition.LHS`, undefined);

            const lhsIndicator = getIndicatorByValue(value);
            if (lhsIndicator && lhsIndicator.RHS && condition?.condition?.RHS?.indicator) {
                const supportedRHS = lhsIndicator.RHS();
                if (supportedRHS.every(supported => supported.value !== condition.condition.RHS.indicator.name)) {
                    // if LHS indicator doesn't support RHS indicator, clear RHS
                    handleChangeCondition(sourceGroupId, condition.id, `condition.RHS`, undefined);
                }
            }

        } else if (key === "RHS.indicator.name") {
            handleChangeCondition(sourceGroupId, condition.id, `condition.RHS`, undefined);
        } else if (key === "conditionType") {
            const conditionType = ConditionTypes.find((conditionType) => conditionType.value === value);
            if (!conditionType || !conditionType.hasRHS) { // if condition type does not have RHS, clear RHS
                handleChangeCondition(sourceGroupId, condition.id, `condition.RHS`, undefined);
            }
        }

        handleChangeCondition(sourceGroupId, condition.id, `condition.${key}`, value);
    }

    function handleSaveCondition() {
        const savedConditions = localStorage.getItem('savedConditions')
        const savedConditionsArray = savedConditions ? JSON.parse(savedConditions) : [];
        const copyOfCondition = JSON.parse(JSON.stringify(condition));
        copyOfCondition.sourceGroupId = uuidv4();
        copyOfCondition.id = uuidv4();
        savedConditionsArray.push(copyOfCondition);

        let conditionIndicatorLabel = "";
        if (condition) {
            conditionIndicatorLabel = `${condition.condition.LHS?.indicator.name} ${condition.condition.LHS?.indicator.name === condition.condition.LHS?.field ? "" : condition.condition.LHS?.field} ${condition.condition.conditionType} ${condition.condition.RHS?.indicator.name === condition.condition.RHS?.field ? "" : condition.condition.RHS?.field}`;
        }

        ReactGA.event('condition_save', {
            condition: conditionIndicatorLabel,
        });

        localStorage.setItem('savedConditions', JSON.stringify(savedConditionsArray));
    }

    // const menuOptions = [
    //     {label: "Duplicate", action: () => handleConditionDuplicate(sourceGroupId, condition.id)},
    //     {label: "Delete", action: () => handleRemoveCondition(sourceGroupId, condition.id), color: "red"},
    // ]

    function handleBlur(event: { currentTarget: any; relatedTarget: any; }) {
        // Current Paper component element
        const currentElement = event.currentTarget;

        // Next focused element
        const relatedElement = event.relatedTarget;

        // Check if the next focused element is a descendant of the current element
        if ((relatedElement && currentElement.contains(relatedElement)) || (relatedElement && relatedElement.closest('.MuiDialog-root'))) {
            return; // Do nothing as focus is still within the Paper
        }

        if (!isUnfinishedCondition) {
            setTextualConditionView(true)
        }
    }

    const menuOptions = [
        {
            label: (
                <Tooltip title={"Duplicate condition"} arrow>
                    <ContentCopyIcon
                        fontSize={"small"}
                        style={{cursor: "copy", margin: "auto 0"}}
                    />
                </Tooltip>
            ),
            action: () => handleConditionDuplicate(sourceGroupId, condition.id)
        },
        {
            label: (
                <Tooltip title={condition.condition.isValid ? "Save condition" : "Complete the condition before saving"} arrow>
                    <Star
                        fontSize={"small"}
                        style={{cursor: "pointer", margin: "auto 0", color: `${condition.condition.isValid ? 'rgb(0, 161, 255)' : 'rgb(201,80,80)'}`}}
                    />
                </Tooltip>
            ),
            action: () => condition.condition.isValid && handleSaveCondition()
        }
    ];


    function indicatorString(indicator: any) {
        if (!indicator) return null;
        if (!indicator.indicator || !indicator.field) return null;

        let indicatorString = '';

        if (indicator.value !== undefined) {
            indicatorString = `${indicator.value}`
        } else if (indicator.indicator.name !== indicator.field) {
            indicatorString = `${reverseCamelCase(indicator.indicator.name)} (${reverseCamelCase(indicator.field)})`
        } else {
            indicatorString = reverseCamelCase(indicator.indicator.name)
        }

        return indicatorString;
    }

    function reverseCamelCase(str: string) {
        if (!str) return str;
        const spaced = str.replace(/([A-Z])/g, ' $1');
        const capitalized = spaced.charAt(0).toUpperCase() + spaced.slice(1);
        return capitalized;
    }


    return (
        <Paper
            tabIndex={0}
            ref={paperRef}
            onBlur={handleBlur}
            elevation={1}
            style={{
                width: "auto",
                minWidth: 450,
                height: '100%',
                display: "flex",
                flexDirection: "column",
                border: isUnfinishedCondition ? '1px solid rgb(201,80,80)' : `${theme.palette.secondary.main} ${textualConditionView ? 'solid' : 'dotted'} 1px`,
                boxShadow: isUnfinishedCondition ? '0px 0px 10px rgb(196 127 127)' : '',
                background: theme.palette.background.custom4,
            }}
            className={"condition-body"}
        >
            {!viewOnly && <Overlay active={viewOnly}>
                <ConditionHeader
                    ref={drag}
                    className="condition-header"
                >
                    <GCMenu
                        styles={{height: "100%", width: 32, marginLeft: 2, cursor: "pointer", padding: 0}}
                        items={menuOptions}
                        title={
                            <Tooltip title={"More..."} arrow>
                                <MenuIcon fontSize={"small"}/>
                            </Tooltip>}
                    />
                    <Tooltip title={"Delete condition"} arrow>
                        <IconButton sx={{height: '100%', padding: 0, width: 32, marginRight: 2}}
                                    onClick={() => handleRemoveCondition(sourceGroupId, condition.id)}>
                            <CloseIcon style={{height: "100%", cursor: "pointer", padding: 0}} fontSize={"small"}/>
                        </IconButton>
                    </Tooltip>

                </ConditionHeader>
            </Overlay>}


            {textualConditionView
                ? <>

                    <TextualConditionView style={{display: 'flex', margin: 16, flexGrow: 1}}
                                          /*@ts-ignore*/
                                          viewonly={viewOnly ? 'true' : undefined}
                                          onClick={() => {
                                              if (!viewOnly) {
                                                  setTextualConditionView(false)
                                                  //@ts-ignore
                                                  paperRef.current?.focus();
                                              }
                                          }}
                    >
                        {indicatorString(condition.condition.LHS)} {condition.condition.conditionType} {indicatorString(condition.condition.RHS)} {condition.settings?.previousCandle?.enabled ? 'on previous candle' : ''}
                    </TextualConditionView>
                    {!viewOnly &&
                      <>
                          <CenteredDividerContainer>
                            <Divider textAlign={"center"}>
                                <Chip
                                    label="Settings"
                                    size={"small"}
                                />
                            </Divider>
                        </CenteredDividerContainer>
                        <Overlay active={viewOnly}>
                            <Box display={"flex"} alignItems={"center"} p={1}>

                                <Tooltip title="Previous Candle" arrow>
                                    <UndoIcon fontSize={"small"}
                                    className={`condition-setting-icon ${condition.settings?.previousCandle?.enabled ? 'active' : 'default'}`}
                                    onClick={() => handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.enabled", !condition.settings?.previousCandle?.enabled)}
                                    />
                                </Tooltip>

                            </Box>
                        </Overlay>
                      </>
                    }
                </>
            : <>
                        <Box style={{display: 'flex', justifyContent: 'space-around', margin: 16, flexGrow: 1}}>
                            <ConditionSelector viewOnly={viewOnly} condition={condition.condition}
                                               onChangeCondition={_onChangeCondition}
                                               setConditionIsValid={(val) => handleChangeCondition(sourceGroupId, condition.id, `condition.isValid`, val)}
                            />
                        </Box>
                        <CenteredDividerContainer>
                            <Divider textAlign={"center"}>
                                <Chip
                                    label="Settings"
                                    size={"small"}
                                />
                            </Divider>
                        </CenteredDividerContainer>
                        <Overlay active={viewOnly}>
                            <Box display={"flex"} alignItems={"center"} p={1}>
                                {
                                    <Tooltip title="Previous Candle" arrow>
                                        <UndoIcon fontSize={"small"}
                                                  className={`condition-setting-icon ${condition.settings?.previousCandle?.enabled ? 'active' : 'default'}`}
                                                  onClick={() => handleChangeCondition(sourceGroupId, condition.id, "settings.previousCandle.enabled", !condition.settings?.previousCandle?.enabled)}
                                        />
                                    </Tooltip>
                                }
                            </Box>
                        </Overlay>
                    </>
                    }

        </Paper>
    );
};


export default Condition;
