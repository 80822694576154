import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {firebase_auth, firebase_firestore} from "../common/firebaseConfig";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {doc} from "firebase/firestore";
import {signInWithCustomToken} from "firebase/auth";
import ProgressCircle from "../components/ProgressCircle";
import ContactSupport from "../components/ContactSupport";
import ReactGA from "react-ga4";

export default function GreenChartSubscriptionRouteGuard({children}: any) {
    const [searchParams] = useSearchParams();

    const [hasAccess, setHasAccess] = useState(false);
    const [user, loading] = useAuthState(firebase_auth);
    const [usermeta, loadingUserMeta] = useDocumentData(doc(firebase_firestore, `usermeta/${user?.uid}`));
    const [validatingToken, setValidatingToken] = useState(false);

    useEffect(() => {
        const idToken = searchParams.get('idToken');
        searchParams.delete('idToken');
        if (idToken && !user) {
            setValidatingToken(true)
            fetch(`https://us-central1-${firebase_firestore.app.options.projectId}.cloudfunctions.net/validateIdToken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: idToken }),
            })
                .then(response => response.json())
                .then(async data => {
                    const authToken = data.authToken;
                    try {
                        await signInWithCustomToken(firebase_auth, authToken)
                        setHasAccess(true)
                    } catch (err) {
                        setHasAccess(false)
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                })
                .finally(() => setValidatingToken(false))
        }
    }, [user, searchParams]);

    useEffect(() => {
        if (usermeta) {
            ReactGA.set({ user_id: usermeta.gcid });
        }
    }, [usermeta]);

    if (loading || loadingUserMeta || validatingToken) {
        return <ProgressCircle/>;
    }

    if (hasAccess && user && usermeta) {
        return children;
    } else {
        return <ContactSupport/>
    }
};

