import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function BacktestReportSidebar() {
    return <>

        <Box sx={{ overflowY: "auto"}}>
            <Typography variant={"h6"}>Reports</Typography>
            <Typography variant={"caption"} mb={4}>Select a report to view an in depth summary.</Typography>
        </Box>

        <Box>
            
        </Box>
    </>
}

export default BacktestReportSidebar;
