import Indicator from "./types/Indicator";
import Value from "./Value";
import Open from "./Open";
import High from "./High";
import Low from "./Low";
import conditionTypes from "../conditions";

const Close: Indicator = {
    value: "close",
    label: "Close/Current",
    fields: ["close"],
    RHS: () => [Value, Open, High, Low, Close],
    conditions: conditionTypes,
}

export default Close
