import Indicator from "../types/Indicator";
import Value from "../Value";
import conditionTypes from "../../conditions";

const WilliamsR: Indicator = {
    value: "williamsR",
    label: "Williams %R",
    nickname: "Williams %R",
    fields: ["williamsR"],
    params: {
        period: 14,
    },
    RHS: () => [WilliamsR, Value],
    conditions: conditionTypes,
}


export default WilliamsR;
