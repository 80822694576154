import React, {createContext, useContext, ReactNode, useState} from 'react';
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {SnackbarHelperContext} from "../../../contexts/SnackbarHelperContext";
import {firebase_functions} from "../../../common/firebaseConfig";
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import Box from "@mui/material/Box";

interface BacktestContextProps {
    backtestSettingInputData: any,
    setBacktestSettingInputData: React.Dispatch<React.SetStateAction<any | null>>;
    backtestToView: string | undefined;
    setBacktestToView: React.Dispatch<React.SetStateAction<string | undefined>>;
    backtestListItemFlipped: boolean | undefined;
    setBacktestListItemFlipped: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    backtestListItemFlipStatus: { [backtestId: string]: boolean };
    setBacktestListItemFlipStatus: React.Dispatch<React.SetStateAction<{ [backtestId: string]: boolean }>>;
    runBacktest: (settings: any) => void;
    dialogOpen: {type: string, data: any} | undefined;
    setOpenDialog: React.Dispatch<React.SetStateAction<{type: string, data: any} | undefined>>;
    backtestInputIsValid: boolean;
    setBacktestInputIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the contexts
const BacktestContext = createContext<BacktestContextProps | undefined>(undefined);

interface Props {
    children: ReactNode;
}

export const BacktestProvider: React.FC<Props> = ({ children }) => {
    const {
        backtestSettingInputData,
        setBacktestSettingInputData,
        tradingHouse,
        setActiveTab,
    } = useApplicationSuiteContext()

    const [backtestToView, setBacktestToView] = useState<string>();
    const [backtestListItemFlipped, setBacktestListItemFlipped] = useState<boolean>();
    const [dialogOpen, setOpenDialog] = useState<{type: string, data: any}>();
    const [backtestListItemFlipStatus, setBacktestListItemFlipStatus] = useState<{ [backtestId: string]: boolean }>({});
    const [backtestInputIsValid, setBacktestInputIsValid] = useState<boolean>(false);

    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    const [executeBacktestCallable] = useHttpsCallable(firebase_functions, "patternbacktest")

    const runBacktest = async (settings: any) => {

        if (settings) {
            settings.tradingHouse = tradingHouse;
            settings.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            const response = await executeBacktestCallable(settings);
            if (response?.data) {
                const data: any = response.data;
                if (data.status >= 400) {
                    setSnackbarSettings({severity: 'error', message: data.message, autoHideDuration: 10000})
                } else if (data.status >= 200) {
                    setSnackbarSettings({
                        severity: 'success',
                        message: <Box>{data.message} <br/> Go to <Box sx={{display: 'inline', cursor: 'pointer', color: '#00a1ff'}} onClick={() => setActiveTab(2)}>Reports</Box> to view your backtest results</Box>,
                        autoHideDuration: 20000,
                        anchorOrigin: {vertical: 'bottom', horizontal: 'right'}
                    });
                }
            }
        }
    }

    return (
        <BacktestContext.Provider value={{
            backtestInputIsValid,
            setBacktestInputIsValid,
            backtestListItemFlipStatus,
            setBacktestListItemFlipStatus,
            dialogOpen,
            setOpenDialog,
            backtestToView,
            setBacktestToView,
            setBacktestSettingInputData,
            backtestSettingInputData,
            backtestListItemFlipped,
            setBacktestListItemFlipped,
            runBacktest,
        }}>
            {children}
        </BacktestContext.Provider>
    );
}

// Custom hook to use this contexts
export const useBacktestContext = (): BacktestContextProps => {
    const context = useContext(BacktestContext);
    if (!context) {
        throw new Error('useBacktestContext must be used within a BacktestProvider');
    }
    return context;
}
