import Indicator from "../types/Indicator";
import Value from "../Value";
import conditionTypes from "../../conditions";

const RelativeStrengthIndex: Indicator = {
    value: "relativeStrengthIndex",
    label: "Relative Strength Index",
    nickname: "RSI",
    fields: ["relativeStrengthIndex"],
    params: {
        field: "close",
        period: 14,
    },
    RHS: () =>  [RelativeStrengthIndex, Value],
    conditions: conditionTypes,
};


export default RelativeStrengthIndex;
