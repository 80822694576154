import {
    Box,
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import React from "react";
import Application from "../../../layout/Application";
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import TrainingHeaderBar from "../components/actionBars/TrainingHeaderBar";

function TrainingApp() {
    const {
        tradingHouse,
    } = useApplicationSuiteContext();
    const theme = useTheme();

    function renderTrainingIframe() {
        switch (tradingHouse) {
            case "gtf":
                return (
                    <iframe src='https://vimeo.com/showcase/10796897/embed' allowFullScreen frameBorder='0'
                            title={"Training - Day Trade Learning Center"}
                            style={{position: 'absolute', top: 76, left: 0, width: '100%', height: 'calc(100vh - 76px)'}}></iframe>
                );
            case "greenchart":
                return (
                    <iframe src='https://vimeo.com/showcase/11215697/embed' allowFullScreen frameBorder='0'
                            title={"Training - GreenChart"}
                            style={{position: 'absolute', top: 76, left: 0, width: '100%', height: 'calc(100vh - 76px)'}}></iframe>
                );
            default:
                return null;
        }
    }

    return (
        <>
            <Box display={"flex"} sx={{minHeight: '100vh'}} flexDirection={{xs: "column", md: "row"}} >
                <Box sx={{backgroundColor: theme.palette.background.custom1}} width={"100%"} display={"flex"}>
                    <Box sx={{position: 'relative', width: '100%'}} display={'flex'} flexDirection={'column'}>
                        <TrainingHeaderBar />
                        { renderTrainingIframe() }
                    </Box>
                </Box>
            </Box>
        </>

    );
}

const App = ({tab}: any) => <Application AppComponent={TrainingApp} tab={tab}/>;
export default App;
