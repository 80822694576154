import {GreenChartTooltip} from "../common/components/GreenChartTooltip";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as React from "react";
import {useApplicationSuiteContext} from "../contexts/ApplicationSuiteProvider";
import {styled, useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledLinks = styled("a")(({ theme }) => ({
    '&': {
        color: theme.palette.primary.main,
        textDecoration: "none"
    },
    '&:hover': {
        textDecoration: "underline"
    }
}));

const RemainingBalance = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        usermeta,
    } = useApplicationSuiteContext();

    const maxCost = usermeta.products && usermeta.products.backtest ? usermeta.products.backtest.maxCost : 0;
    const accruedCost = usermeta.products && usermeta.products.backtest ? usermeta.products.backtest.accruedCost : 0;
    const accountBalance = (maxCost - accruedCost).toFixed(2)

    return (
        <GreenChartTooltip title={<Typography variant={"body2"}>{accountBalance} of {maxCost} backtest credits remaining. <br/> <StyledLinks href={"https://myaccount.greenchart.com/dashboard/marketplace?display=membership"} target={"_blank"}>Upgrade for more.</StyledLinks></Typography>} arrow>
            <Box width={isMobile ? 60 : 152} color={"white"} padding={2}>
                {isMobile ? accountBalance : `${accountBalance} credits available`}
            </Box>
        </GreenChartTooltip>
    )
}

export default RemainingBalance;