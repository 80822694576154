import React, {useContext} from "react";
import Box from "@mui/material/Box";
import {ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import PatternActionButtons from "../components/sidebar/patternActionButtons/PatternActionButtons";
import {PatternVersionView} from "../components/sidebar/patternVersion/PatternVersionView";
import PatternInput from "../components/sidebar/patternInput/PatternInput";
import {PatternTreeContext} from "../contexts/PatternTreeContext";

function PatternBuilderSidebar() {
    const {
        activeTab,
        setActiveTab
    } = useContext(PatternTreeContext);

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newTab: string | null,
    ) => {
        if (newTab) {
            setActiveTab(newTab);
        }
    };

    return (<>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 4, height: 'calc(100% - 83px)'}} p={3}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant={"h6"}>Current Pattern</Typography>
                <ToggleButtonGroup
                    color={'secondary'}
                    value={activeTab}
                    exclusive
                    onChange={handleChange}
                    aria-label="condition group buy/sell"
                >
                    <ToggleButton value="buy" aria-label="buy conditions" >
                        Buy
                    </ToggleButton>
                    <ToggleButton value="sell" aria-label="sell conditions" >
                        Sell
                    </ToggleButton>
                </ToggleButtonGroup>

            </Box>
            <PatternInput />
            <PatternActionButtons />
        </Box>

        <PatternVersionView/>
    </>);
}

export default PatternBuilderSidebar;
