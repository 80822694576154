import {useApplicationSuiteContext} from "../../../../contexts/ApplicationSuiteProvider";
import React, {useContext, useEffect, useState} from "react";
import {PatternContext} from "../../contexts/PatternContext";
import {collection, doc, getDoc, getDocs, orderBy, query} from "firebase/firestore";
import {firebase_firestore} from "../../../../common/firebaseConfig";
import useSavePattern from "../../../../hooks/useSavePattern";
import {GreenChartTooltip} from "../../../../common/components/GreenChartTooltip";
import {Box} from "@mui/material";
import SSButton from "../../../../common/components/actionButton/SSButton";
import ReactGA from "react-ga4";
import PublishIcon from "@mui/icons-material/Publish";

const PublishPatternButton = (props: {disabled: boolean}) => {
    const {disabled} = props;

    const {
        usermeta,
        tradingHouse,
    } = useApplicationSuiteContext();

    const {
        pattern,
    } = useContext(PatternContext);

    const [versions, setVersions] = useState<any[]>([])

    const fetchVersions = async (path: string) => {
        const q = query(collection(firebase_firestore, path), orderBy("version"));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => doc.data());
    };

    const { savePattern } = useSavePattern();

    useEffect(() => {
        const userDocPath = `usermeta/${usermeta.gcid}/patterns/${pattern.id}`;
        const userDocRef = doc(firebase_firestore, userDocPath);

        getDoc(userDocRef).then(docSnapshot => {
            const versionsPath = docSnapshot.exists() ?
                `${userDocPath}/versions` :
                `tradingHouses/${tradingHouse}/standardPatterns/${pattern.id}/versions`;

            return fetchVersions(versionsPath);
        }).then(docs => {
            setVersions(docs);
        });

    }, [usermeta.gcid, pattern.version, pattern.id, tradingHouse]);

    return (
        <GreenChartTooltip
            title={disabled ? "All conditions must be complete" : "Publish pattern"} arrow
            key={"publishButton"}>
            <Box>
                <SSButton
                    type={'action'}
                    onClick={() => {
                        if (disabled) {
                            return;
                        }
                        const previousVersion = versions && versions.length && versions[versions.length - 1].version ? versions[versions.length - 1].version : 1
                        pattern.alertVersion = previousVersion + 1
                        ReactGA.event('pattern_publish', {
                            author: pattern.author,
                            pattern_name: pattern.name
                        });
                        savePattern()
                    }}
                    disabled={disabled}
                >
                    <PublishIcon />
                </SSButton>
            </Box>
        </GreenChartTooltip>
    )
}

export default PublishPatternButton;