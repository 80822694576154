import Indicator from "../../types/Indicator";
import Value from "../../Value";
import conditionTypes from "../../../conditions";

const Major: Indicator = {
    value: "major",
    label: "Major",
    fields: ["redMajor", "greenMajor"],
    RHS: () => [Major, Value],
    conditions: conditionTypes,
}

export default Major
