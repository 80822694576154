import {forwardRef, useEffect, useRef, useState} from "react";
import {firebase_firestore, firebase_realtime} from "../../../../common/firebaseConfig";
import { ref, onValue } from "firebase/database";
import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {CircularProgress, CircularProgressProps, Collapse} from "@mui/material";
import BacktestTable from "../backtestResults/BacktestTable";
import {useTheme} from "@mui/material/styles";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Box from "@mui/material/Box";
import {GreenChartTooltip} from "../../../../common/components/GreenChartTooltip";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import _duration from "format-duration-time";
import TimeAgo from "javascript-time-ago";
import {useApplicationSuiteContext} from "../../../../contexts/ApplicationSuiteProvider";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import en from "javascript-time-ago/locale/en.json";
import {useBacktestReportContext} from "../../contexts/BacktestReportContext";
import GCMenu from "../../../../common/components/GCMenu";
import Divider from "@mui/material/Divider";
import {doc, updateDoc} from "firebase/firestore";
import {usePattern} from "../../../../hooks/usePattern";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import VideoSettingsOutlinedIcon from "@mui/icons-material/VideoSettingsOutlined";
import DeleteIcon from '@mui/icons-material/Delete';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';

TimeAgo.addDefaultLocale(en)


function BacktestListRow({backtest}: any) {
    const _timeAgo = new TimeAgo('en-US')

    const {
        usermeta,
        setBacktestSettingInputData,
        setActiveTab,
        canPrivatePatterns,
        setPattern,
    } = useApplicationSuiteContext();

    const [pattern] = usePattern( backtest.backtestSettings.patternId, backtest.backtestSettings.patternVersion, backtest.backtestSettings.gcid)

    const { setOpenDialog  } = useBacktestReportContext();

    const theme = useTheme();
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [percentage, setPercentage] = useState<number>(0);
    const listenerRef: any = useRef(null);

    function togglePrivacy(backtestId: string) {
        const docRefPath = `usermeta/${usermeta.gcid}/backtests/${backtestId}`;
        const docRef = doc(firebase_firestore, docRefPath);
        const newIsPublicValue = !backtest.backtestSettings.isPublic;

        updateDoc(docRef, {
            'backtestSettings.isPublic': newIsPublicValue
        })
            .catch((error) => {
                console.error("Error updating document:", error);
            });
    }

    useEffect(() => {

        if (backtest.status === "running") {
            let backtestDBRef = null;

            const subStatus = backtest.subStatus;

            let basePercentage = 0;
            let rangePercentage = 50;

            if (subStatus === "signals") {
                backtestDBRef = ref(firebase_realtime, `usermeta/${usermeta.gcid}/backtests/${backtest.id}/findingSignalTasks`);
                basePercentage = 0;
            } else if (subStatus === "analyze") {
                backtestDBRef = ref(firebase_realtime, `usermeta/${usermeta.gcid}/backtests/${backtest.id}/analyzingRatioTasks`);
                basePercentage = 50;
            }

            if (backtestDBRef) {
                listenerRef.current = onValue(backtestDBRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data && data.total > 0) {
                        const taskPercentage = ((data.total - data.remaining) / data.total) * 100;
                        const normalizedPercentage = basePercentage + (taskPercentage * rangePercentage / 100);
                        setPercentage(normalizedPercentage);
                    }
                });
            }
        }

        return () => {
            if (listenerRef.current) {
                listenerRef.current();
                listenerRef.current = null; // Clear the ref
            }
        }
    }, [backtest, usermeta.gcid])


    function getSubStatus(subStatus: string) {
        switch (subStatus) {
            case "initialize":
                return "Initializing"
            case "signals":
                return "Finding patterns"
            case "generate":
                return "Generating trades"
            case "analyze":
                return "Analyzing trades"
            case "saving":
                return "Saving report"
            case "timeout":
                return "Timed out"
            case "summary":
                return "Creating report"
        }
        return ""
    }

    const backtestActions = [
        {
            label: 'View pattern',
            action: () => {
                if (pattern) {
                    setPattern(pattern);
                    setActiveTab(0);
                }
            },
            icon: <QueryStatsOutlinedIcon />,
        },
        {
            label: 'View backtest settings',
            action: () => {
                setBacktestSettingInputData(backtest.backtestSettings);
                setActiveTab(1);
            },
            icon: <VideoSettingsOutlinedIcon />,
        },
        {
            label: backtest.backtestSettings.isPublic ? 'Make private' : 'Make public',
            action: () => togglePrivacy(backtest.id),
            display: canPrivatePatterns,
            icon: backtest.backtestSettings.isPublic ? <PublicOffIcon /> : <PublicIcon />,
        },
        {
            label: <Divider/>,
            action: () => {}
        },
        {
            label: 'Delete',
            action: () => setOpenDialog({type: 'delete', data: {id: backtest.id, backup: true}}), color: 'rgb(201,80,80)',
            icon: <DeleteIcon />,
        }
    ]

    return (
        <React.Fragment>
            <TableRow sx={{
                    display: 'flex',
                    background: theme.palette.background.custom3,
                    "&:hover": {
                        background: theme.palette.background.custom4
                    },
                }}
                key={backtest.id}
            >
                <TableCell
                    sx={{
                        width: 100,
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: "center"
                    }}
                    key={"status"}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    <Box display="flex" alignItems={"center"} textAlign={"center"}>
                        <Box display="flex" flexDirection="column" alignItems={"center"} textAlign={"center"}>
                            <BacktestStatusIcon backtest={backtest} percentage={percentage}/>
                            {getSubStatus(backtest.subStatus)}
                        </Box>
                        {
                            backtest.status === "complete" && <ProfitableIcon backtest={backtest}/>
                        }
                    </Box>
                </TableCell>
                <TableCell
                    sx={{
                        width: `calc((100% - 72px) / 5)`,
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: "center"
                    }}
                    key={"name"}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    <Box display="flex" flexDirection={"column"}>
                        <Typography variant={"body1"}>{backtest.name} <Typography variant={"caption"}>(v{backtest.backtestSettings.patternVersion})</Typography></Typography>
                        <Typography variant={"caption"}>{backtest.backtestSettings.name}</Typography>
                        <Typography variant={"caption"}>{backtest.backtestSettings.executionSymbol}</Typography>
                        <Typography variant={"caption"}>{backtest.system?.estimatedPrice && (backtest.system.estimatedPrice).toFixed(4)}</Typography>
                        {usermeta.isAdmin && <Typography variant={"caption"}>{backtest.id}</Typography>}
                    </Box>
                </TableCell>
                <TableCell
                    sx={{
                        width: `calc((100% - 72px) / 5)`,
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: "center"
                    }}
                    key={"dateRange"}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    <Box>
                        <Typography variant={"body1"}>{new Date(backtest.backtestSettings.dateRange.startDate).toDateString()} to {new Date(backtest.backtestSettings.dateRange.endDate).toDateString()}</Typography>
                        <Typography variant={"caption"}>{new Date(backtest.backtestSettings.startTime).toLocaleTimeString()} to {new Date(backtest.backtestSettings.endTime).toLocaleTimeString()}</Typography>
                    </Box>
                </TableCell>
                <TableCell
                    sx={{
                        width: `calc((100% - 72px) / 5)`,
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: "center"
                    }}
                    key={"duration"}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    <Box display="flex" flexDirection="column" alignItems={"center"} textAlign={"center"}>
                        <Typography variant={"body1"}>{_duration((backtest.endTime || new Date().getTime()) - backtest.startTime).format('m[m] ss[s]')}</Typography>
                    </Box>
                </TableCell>
                <TableCell
                    sx={{
                        width: `calc((100% - 72px) / 5)`,
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: "center"
                    }}
                    key={"timeAgo"}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    <Box>
                        <Typography variant={"body1"}>{_timeAgo.format(new Date(backtest.startTime))}</Typography>
                        <Typography variant={"caption"}>{backtest.endTime && new Date(backtest.endTime).toLocaleString()}</Typography>
                    </Box>
                </TableCell>
                <TableCell
                    sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: "center",
                        justifyContent: "center",
                        flexGrow: 1,
                    }}
                >
                    <GCMenu items={backtestActions} title={<GreenChartTooltip arrow title={"More..."}><MoreVertIcon/></GreenChartTooltip>}/>
                </TableCell>
            </TableRow>

            {!collapsed && (
                <TableRow sx={{display: 'flex'}} key={`${backtest.id}_collapsed`}>
                    <TableCell colSpan={5} sx={{width: '100%', padding: 0}}>
                        <Collapse in={true}>
                            <BacktestTable backtest={backtest} />
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </React.Fragment>
    );
}

const MoneyIcons = forwardRef((props: any, ref) => {
    const { qty, ...otherProps } = props; // Destructure qty and collect other props

    let icons = [];
    for (let i = 0; i < qty; i++) {
        icons.push(<AttachMoneyIcon key={i} sx={{ left: `${10 * i}px`, position: "absolute", transform: 'translate(0, -50%)' }} color={"success"} />);
    }

    return (
        <Box ref={ref} position={"relative"} {...otherProps}>
            {icons}
        </Box>
    );
});

function BacktestStatusIcon({backtest, percentage}: any) {

    function getStatusColor(status: string) {
        switch (status) {
            case "running":
                return "#00A1ff"
            case "complete":
                return `#008000`
            case "timeout":
            case "error":
                return `#DC0000`
            default:
        }
    }

    switch (backtest.status) {
        case "running":
            return <CircularProgressWithLabel value={percentage} sx={{ color: getStatusColor(backtest.status)}}  size={"2rem"}/>
        case "complete":
            return <GreenChartTooltip arrow title={"Complete"}><DoneIcon fontSize={"small"} sx={{ color: getStatusColor(backtest.status)}}/></GreenChartTooltip>
        case "error":
            return <GreenChartTooltip arrow title={"Error occurred, Contact support@greenchart.com for assistance"}><ErrorIcon fontSize={"small"} sx={{ color: getStatusColor(backtest.status)}}/></GreenChartTooltip>
        default:
            return null
    }
}


function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            {/* Background circle for the outline */}
            <CircularProgress
                variant="determinate"
                size={props.size}
                sx={{
                    color: 'transparent',  // Hide the foreground color
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1, // Keep this behind the main circle
                    '& .MuiCircularProgress-circle': {
                        strokeWidth: '.5', // Set the thickness of the outline
                        stroke: 'lightgrey' // Define the color of the outline
                    }
                }}
                thickness={4} // Adjust thickness to make the outline more visible
                value={100}
            />
            {/* Main progress circle */}
            <CircularProgress
                variant="determinate"
                {...props}
                sx={{
                    '& .MuiCircularProgress-circle': {
                        strokeWidth: '3.5', // Ensure consistent stroke width
                        stroke: props.color || 'primary', // Customize the progress circle color
                    }
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}


function ProfitableIcon({backtest}: any) {


    function isProfitable(profits: any) {

        return profits && profits.length && profits.some((profit: any) => profit > 0);
    }
    function isAllProfitable(profits: any) {
        return profits && profits.length && profits.every((profit: any) => profit > 0);
    }
    function mostAreProfitable(profits: any) {
        return profits && profits.length && profits.filter((profit: any) => profit > 0).length > profits.filter((profit: any) => profit < 0).length;
    }

    const profits = (backtest.summary || []).map((summary: any) => {
        if (!summary['Net Profit (%)']) {
            return 0;
        }

        let netProfitStr = summary['Net Profit (%)'].replace(/\$/, '').match(/-?[\d,]+(\.\d+)?/);

        if (netProfitStr) {
            netProfitStr = netProfitStr[0].replace(/,/g, '');
            if (netProfitStr) {
                return parseFloat(netProfitStr);
            }
        }

        return 0;
    })

    if (isAllProfitable(profits)) {
        return <GreenChartTooltip arrow title={"All ratios profitable"}><MoneyIcons qty={3}/></GreenChartTooltip>
    } if (mostAreProfitable(profits)) {
        return <GreenChartTooltip arrow title={"Most ratios are profitable"}><MoneyIcons qty={2}/></GreenChartTooltip>
    } else if (isProfitable(profits)) {
        return <GreenChartTooltip arrow title={"At least 1 profitable ratio"}><MoneyIcons qty={1}/></GreenChartTooltip>
    } else {
        return null;
    }
}


export default BacktestListRow
