import Indicator from "./types/Indicator";

const Value: Indicator = {
    value: "value",
    label: "Value",
    fields: ["value"],
    conditions: [],
}

export default Value;
