import React, { useCallback } from "react";
import {ISymbol} from "../../SymbolSelect";

interface UseKeyboardNavigationParams {
    selectedIndex: number;
    setSelectedIndex: (index: number) => void;
    filteredSymbols: ISymbol[];
    onSymbolSelect: (symbol: ISymbol) => void;
    isOpen: boolean;
}

const useKeyboardNavigation = ({
                                   selectedIndex,
                                   setSelectedIndex,
                                   filteredSymbols,
                                   onSymbolSelect,
                                   isOpen,
                               }: UseKeyboardNavigationParams) => {
    return useCallback((event: React.KeyboardEvent) => {
        if (!isOpen) return;

        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            event.preventDefault();
            event.stopPropagation();

            const newIndex = event.key === 'ArrowDown'
                ? Math.min(selectedIndex + 1, filteredSymbols.length - 1)
                : Math.max(selectedIndex - 1, 0);

            setSelectedIndex(newIndex);
        } else if (event.key === 'Enter' && filteredSymbols[selectedIndex]) {
            onSymbolSelect(filteredSymbols[selectedIndex]);
        }
    }, [selectedIndex, setSelectedIndex, filteredSymbols, onSymbolSelect, isOpen]);
};

export default useKeyboardNavigation;
