import React, {useContext, useState} from "react";
import {PatternContext} from "../../contexts/PatternContext";
import {GreenChartTooltip} from "../../../../common/components/GreenChartTooltip";
import {Box} from "@mui/material";
import ShareDialog from "../sidebar/patternInput/ShareDialog";
import SSButton from "../../../../common/components/actionButton/SSButton";
import ShareIcon from "@mui/icons-material/Share";
import PreviewIcon from "@mui/icons-material/Preview";

const SharePatternButton = (props: {shareLink: string | null, createShareLink: any, disabled: boolean}) => {
    const {shareLink, createShareLink, disabled} = props;
    const [isSharePatternDialogOpen, setIsSharePatternDialogOpen] = useState<boolean>(false);

    const {
        pattern,
    } = useContext(PatternContext);

    const handleClick = () => {
        if (disabled) {
            return;
        }
        if (shareLink) {
            setIsSharePatternDialogOpen(true);
        } else {
            createShareLink();
        }
    };

    return (
        <GreenChartTooltip
            title={disabled ? "All conditions must be complete" : "Share pattern"} arrow
            key={"shareButton"}>
            <Box>
                <ShareDialog
                    open={ isSharePatternDialogOpen }
                    title={ `Share Pattern: ${pattern.name}` }
                    onClose={ () => setIsSharePatternDialogOpen(false) }
                    content={ shareLink || '' }
                />
                <SSButton
                    type={'cyan'}
                    disabled={disabled}
                    onClick={handleClick}
                >
                    {!shareLink ? <ShareIcon/> : <PreviewIcon/>}
                </SSButton>
            </Box>
        </GreenChartTooltip>
    )
}

export default SharePatternButton;