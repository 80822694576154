import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';

interface ActionBarProps {
    left?: ReactNode;
    middle?: ReactNode;
    right?: ReactNode;
    position?: 'top' | 'bottom';
}

const ActionBar: React.FC<ActionBarProps> = ({ left, middle, right, position }) => {
    const theme = useTheme();

    let styling: any = {
        position: 'sticky',
        zIndex: 1000,
        backgroundColor: theme.palette.background.custom3,
    };

    if (position === 'bottom' || !position) {
        styling = {
            ...styling,
            bottom: 0,
            borderTop: `2.5px solid ${theme.palette.background.custom4}`,
        };
    }

    if (position === 'top') {
        styling = {
            ...styling,
            paddingTop: '5px',
            top: 0,
            borderBottom: `4px solid ${theme.palette.background.custom4}`,
            background: `linear-gradient(rgb(85, 87, 88), rgb(60, 63, 65))`,
        };
    }

    const actionBarTheme = createTheme({
        ...theme,
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontSize: '18px',
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        fontSize: '18px',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        fontSize: '18px',
                    },
                    notchedOutline: {
                        fontSize: '18px',
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    inputRoot: {
                        '& .MuiAutocomplete-input': {
                            fontSize: '18px',
                        },
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={actionBarTheme}>
            <Box sx={styling}>
                <Box sx={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px 0',
                    boxShadow: 3,
                }}>
                    <Box sx={{ flex: 1, paddingLeft: '16px' }}>
                        {left}
                    </Box>
                    <Box sx={{ flex: 1, textAlign: 'center' }}>
                        {middle}
                    </Box>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', paddingRight: '16px' }}>
                        {right}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default ActionBar;
