import Box from "@mui/material/Box";
import {Button, Typography} from "@mui/material";
import React from "react";


const buttonStyles: React.CSSProperties = {
    cursor: 'pointer',
    width: 140,
    height: 35,
    color: 'white',
    fontSize: 20,
    border: 'none',
    background: '#00A1ff',
    borderRadius: 4,
};

function ContactSupport() {

    function refreshPage() {
        // window.location.reload();
        // window.location.href = window.location.href;
    }

    return (
        <>
            <Box sx={{textAlign: 'center', width: '100%'}}>
                <Typography variant={"h2"} style={{fontSize: '1.75em'}}>We are having trouble loading Pattern Builder</Typography>
                <Typography variant={"body2"} sx={{fontSize: '1.3em'}}>Contact support and we will have you building in no time.</Typography>
                <Typography  sx={{fontSize: '24px'}}>When resolved:</Typography>
                <Button sx={buttonStyles} onClick={refreshPage}>
                    Try Again
                </Button>
            </Box>
        </>
    )
}

export default ContactSupport;
