import React from "react";
import {Box} from "@mui/material";
import ActionBar from "../../../../common/components/actionBar/ActionBar";
import BacktestActions from "../BacktestActions";
import VisibilitySelect from "./components/VisibilitySelect";

const BacktestActionBar: React.FC = () => {
    return (
        <ActionBar
            left={
            <Box display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'}>
                <VisibilitySelect />
            </Box>
        }
            right={
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'flex-end', paddingRight: '16px' }} height={48}>
                    <BacktestActions />
                </Box>
            }
        />
    );
};

export default BacktestActionBar;
