import { useMemo } from "react";
import prioritySymbols from "../../filters/prioritySymbols";
import {ISymbol} from "../../SymbolSelect";
import Fuse from 'fuse.js';

const useFilteredSymbols = (symbols: ISymbol[], selectedType: string, searchQuery: string, recentSymbols: ISymbol[]) => {
    const getPriorityIndex = (symbolName: string) => {
        const index = prioritySymbols.indexOf(symbolName);
        return index === -1 ? Infinity : index;
    };

    const filterSymbols = (symbolList: ISymbol[], includeRecent = false) => {
        if (!searchQuery || searchQuery.trim() === '') {
            return symbolList.filter(symbol =>
                (selectedType === 'All' || selectedType === symbol.type) &&
                (!includeRecent || symbols.some((s: ISymbol) => s.name === symbol.name))
            );
        }

        const options = {
            includeScore: true,
            keys: [
                {
                    name: 'name',
                    weight: 0.9
                },
                {
                    name: 'description',
                    weight: 0.1
                }
            ],            isCaseSensitive: false,
            threshold: 0.2,
        };

        const fuse = new Fuse(symbolList, options);
        let results = fuse.search(searchQuery);

        return results.filter((result: any) => {
            const symbol = result.item;
            return (selectedType === 'All' || selectedType === symbol.type) &&
                (!includeRecent || symbols.some(s => s.name === symbol.name));
        }).map((result: any) => result.item);
    };

    return useMemo(() => {
        // Filter recent symbols (only include if they are in the symbols array) and main symbols based on the selected types and search query
        const filteredRecentSymbols = filterSymbols(recentSymbols, true).map((symbol: ISymbol) => ({ ...symbol, category: "Recently Used" }));
        const filteredMainSymbols = filterSymbols(symbols);

        // Remove any filtered recent symbols from the filtered main symbols to avoid duplicates
        const nonRecentSymbols = filteredMainSymbols.filter((mainSymbol: ISymbol) =>
            !filteredRecentSymbols.some((recentSymbol: ISymbol) => recentSymbol.name === mainSymbol.name)
        );

        const sortedNonRecentSymbols = !searchQuery || !searchQuery.length ?
            nonRecentSymbols.sort((a: ISymbol, b: ISymbol) => {
                const indexA = getPriorityIndex(a.name);
                const indexB = getPriorityIndex(b.name);

                if (indexA !== indexB) {
                    return indexA - indexB;
                }
                return a.name.localeCompare(b.name);
            }) : nonRecentSymbols;

        // Sort the non-recent symbols and categorize as "Most Popular" if they are priority symbols
        const categorizedSymbols = sortedNonRecentSymbols.map((symbol: ISymbol) => prioritySymbols.includes(symbol.name) ? { ...symbol, category: "Popular" } : symbol);

        // Combine the filtered recent symbols with the sorted non-recent symbols
        return [...filteredRecentSymbols, ...categorizedSymbols];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols, searchQuery, recentSymbols, selectedType]);
};

export default useFilteredSymbols;
