import {FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import React from "react";

type AtrInputsProps = {
    onATRPeriodChange: (period: number) => void;
    onChartTimeframeChange: (timeframe: string) => void;
    onChartPeriodChange: (period: number) => void;
    atrPeriod: number;
    chartTimeframe: string;
    chartPeriod: number;
}

function AtrInputs(props: AtrInputsProps) {
    const {onATRPeriodChange, onChartPeriodChange, onChartTimeframeChange, chartPeriod, chartTimeframe, atrPeriod} = props;

    return (
        <>
            <Grid container item spacing={2}>
                <Grid item sm={4} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>ATR value</InputLabel>
                        <OutlinedInput
                            value={atrPeriod}
                            onChange={(e) => onATRPeriodChange(parseFloat(e.target.value))}
                            type={"number"}
                            inputProps={{min: 0}}
                            label="ATR value"
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Periodicity for chart</InputLabel>
                        <OutlinedInput
                            value={chartPeriod}
                            onChange={(e) => onChartPeriodChange(parseFloat(e.target.value))}
                            type={"number"}
                            inputProps={{min: 0}}
                            label="Periodicity for chart"
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="atr-chart-timeframe-label">Timeframe for chart</InputLabel>
                        <Select
                            labelId="atr-chart-timeframe-label"
                            value={chartTimeframe}
                            onChange={(e) => onChartTimeframeChange(e.target.value)}
                            label="Timeframe for chart"
                        >
                            <MenuItem value="minute">Minute</MenuItem>
                            <MenuItem value="hour">Hour</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )
}

export default AtrInputs;
