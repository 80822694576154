import ConditionType from "./ConditionType";

const MovingDownCondition: ConditionType = {
    value: "moving down",
    label: "Moving Down",
    hasRHS: false,
    hasValue: true,
};

export default MovingDownCondition;
