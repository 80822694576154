import Indicator from "../types/Indicator";
import {overlayIndicators} from "./index";
import Open from "../Open";
import High from "../High";
import Low from "../Low";
import Close from "../Close";
import Value from "../Value";
import conditionTypes from "../../conditions";

const IchimokuCloud: Indicator = {
    value: "ichimokuCloud",
    label: "Ichimoku Cloud",
    nickname: "Ichimoku Cloud",
    fields: ["conversion", "base", "spanA", "spanB"],
    params: {
        conversionPeriod: 9,
        basePeriod: 26,
        spanPeriod: 52,
        displacement: 26,
    },
    RHS: () => [...overlayIndicators, Open, High, Low, Close, Value],
    conditions: conditionTypes,
}


export default IchimokuCloud;
