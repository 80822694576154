import {useApplicationSuiteContext} from "../../../../../contexts/ApplicationSuiteProvider";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, orderBy, query} from "firebase/firestore";
import {firebase_firestore} from "../../../../../common/firebaseConfig";
import React, {useContext, useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {PatternContext} from "../../../contexts/PatternContext";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const LayoutInput = () => {
    const {
        usermeta,
    } = useApplicationSuiteContext();

    const [querySnapshot] = useCollection(query(collection(firebase_firestore, `usermeta/${usermeta.gcid}/layouts`), orderBy("name")));
    const [layouts, setLayouts] = useState<any[]>([])

    const theme = useTheme();

    useEffect(() => {
        if (querySnapshot) {
            setLayouts(querySnapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() })));
        }
    }, [querySnapshot])
    const {
        setPattern,
        pattern,
    } = useContext(PatternContext);

    if (!layouts) {
        return null;
    }

    return (
        <Box
            sx={{
                maxWidth: '300px',
                width: 'calc(100vw - 1146px)',
                minWidth: '100px',
                '@media (min-width: 1446px)': {
                    width: '300px',
                },
            }}
        >
            <FormControl size="medium" fullWidth>
                <InputLabel id="layout-select-label">Layout</InputLabel>
                <Select
                    labelId="layout-select-label"
                    id="pattern-layout_id"
                    sx={{textAlign: "left", background: theme.palette.background.custom4}}
                    label="Layout"
                    value={pattern.layoutId || ''}
                    onChange={(e) => {
                        let value: string | null = e.target.value
                        if (value && value.trim() === '') {
                            value = null
                        }
                        setPattern((prevPattern: any) => ({
                            ...prevPattern,
                            layoutId: value,
                        }));
                    }}
                >
                    <MenuItem value={''}>
                        None
                    </MenuItem>
                    {layouts.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
}

export default LayoutInput;
