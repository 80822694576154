import React, {useContext, useEffect, useRef, useState} from 'react';
import { useDrop, useDrag } from 'react-dnd';
import {TextField, Tooltip} from "@mui/material";
import Condition from '../condition/Condition';
import MenuIcon from '@mui/icons-material/Menu';
import GCMenu from "../../../common/components/GCMenu";
import Box from "@mui/material/Box";
import PhantomCondition from '../condition/PhantomCondition';
import { Select, MenuItem, Typography } from '@mui/material';
import GCModalConfirmation from "../../../common/components/modals/GCModalConfirmations";
import DropIndicator from "../components/DropIndicator";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {PatternTreeContext} from "../contexts/PatternTreeContext";
import "./ConditionGroup.css"
import {ChartDetailInput} from "../components/ChartDetailInput";
import {v4 as uuidv4} from "uuid";
import Overlay from "../components/overlay/Overlay";
import Divider from "@mui/material/Divider";
import {MAX_CONDITION_GROUP_DEPTH} from "../../../common/constants";
import ReactGA from "react-ga4";
import {styled, useTheme} from "@mui/material/styles";
import LockIcon from '@mui/icons-material/Lock';
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";

const ConditionGroupHeader = styled(Box)(({ theme }) => ({
    '&:hover': {
        background: `linear-gradient(0deg, ${theme.palette.background.custom2}, ${theme.palette.background.custom3})`,
    },
}));

const ConditionGroup = ({
                            privateGroup,
                            canEdit,
                            canView,
                            conditionsVisible: areConditionsVisible,
                            conditionGroup,
                            insertIndex,
                        }: any) => {


    // Defaults and cascading permissions
    privateGroup = privateGroup === undefined ? false : privateGroup;
    canView = canView === undefined ? !privateGroup : canView && !privateGroup;
    canEdit = canEdit === undefined ? canView : canView && canEdit;
    areConditionsVisible = areConditionsVisible === undefined ? canView : canView && areConditionsVisible;

    const {
        tradingHouse,
    } = useApplicationSuiteContext();

    const {
        activeTab
    } = useContext(PatternTreeContext);

    const ref = useRef(null);
    const isAfterPrevious = useRef(false);
    const [dropIndex, setDropIndex] = useState(-1);
    const [conditionsVisible, setConditionsVisible] = useState(areConditionsVisible);
    const [groupConditionMessageRows, setGroupConditionMessageRows] = useState(1);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const theme = useTheme();

    const {
        handleRemoveConditionGroup,
        handleAddCondition,
        handleChangeConditionGroup,
        handleConditionGroupDuplicate,
        handleConditionGroupCopyTo,
        handleAddNestedGroup,
        handleDrop,
    } = useContext(PatternTreeContext);

    useEffect(() => {
        const hideDeleteConditionGroupDialog = localStorage.getItem("ConfirmRemoveConditionGroup")
        if (hideDeleteConditionGroupDialog) {
            const monthAgo = new Date()
            monthAgo.setDate(new Date().getDate() - 10);
            if (new Date(hideDeleteConditionGroupDialog).getTime() < monthAgo.getTime()) {
                localStorage.removeItem("ConfirmRemoveConditionGroup")
            }
        }
    }, [])

    const [{isOver, canDrop, itemType, dragId}, drop] = useDrop(() => ({
        accept: ['condition', 'group'],
        drop: (item: any, monitor) => {
            // Check if it's a nested drop
            if (monitor.didDrop()) {
                return;
            }
            const indexToInsert = monitor.getItemType() === "condition" ? dropIndex : insertIndex;

            if (handleDrop) {
                handleDrop(conditionGroup.id, item, indexToInsert);
            }
        },
        hover: (item, monitor) => {
            // Prevent the drag event from propagating to parent drop targets
            monitor.isOver({ shallow: true });

            if (!ref.current) return;

            const hoverBoundingRect = (ref.current as HTMLElement).getBoundingClientRect();
            const clientOffset = monitor.getClientOffset()!;
            const hoverClientX = clientOffset.x - hoverBoundingRect.left;
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            let dropPosition = -1;
            let isAfter = false;
            let conditions = conditionGroup.conditions;
            for (let i = 0; i < conditions.length; i++) {
                const conditionId = conditions[i].id;
                const conditionElement = (ref.current as HTMLElement).querySelector(
                    `[data-condition-id="${conditionId}"]`
                );
                if (!conditionElement) continue;
                const conditionRect = (conditionElement as HTMLElement).getBoundingClientRect();

                const conditionLeft = conditionRect.left;
                const conditionHalfWidth = conditionRect.width/2;
                const conditionRight = conditionRect.right;
                const conditionTop = conditionRect.top;

                if (hoverClientY > conditionTop) {
                    continue;
                }

                // console.log("mouse", hoverClientX, hoverClientY)
                // console.log("condition", conditionLeft, conditionHalfWidth)

                if (hoverClientX >= conditionLeft && hoverClientX < (conditionLeft + conditionHalfWidth)) {
                    dropPosition = i;

                    // if (hoverClientX > )
                    isAfter = false;
                    break;
                } else if (hoverClientX > conditionLeft + conditionHalfWidth && hoverClientX <= conditionRight) {
                    dropPosition = i;
                    isAfter = true;
                    break;
                }
            }

            if (dropPosition === dropIndex && isAfter === isAfterPrevious.current) return;

            setDropIndex(dropPosition);
            isAfterPrevious.current = isAfter;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({shallow: true}),
            canDrop: monitor.canDrop(),
            itemType: monitor.getItemType(),
            dragId: monitor.isOver() && monitor.getItem().id,
        }),
    }),  [conditionGroup.id, handleDrop, dropIndex, insertIndex]);

    const [, drag] = useDrag(() => ({
        type: 'group',
        item: { id: conditionGroup.id, type: 'group', sourceGroupId: conditionGroup.id, depth: conditionGroup.depth},
    }));

    function handleSaveConditionGroup() {
        const savedConditionGroups = localStorage.getItem(`savedConditions-${tradingHouse}`)
        const savedConditionGroupsArray = savedConditionGroups ? JSON.parse(savedConditionGroups) : []

        const legacySavedConditions = localStorage.getItem(`savedConditions`)
        let savedLegacyConditionGroupsArray = legacySavedConditions ? JSON.parse(legacySavedConditions) : [];

        if (legacySavedConditions) {
            localStorage.setItem(`savedConditions-${tradingHouse}`, legacySavedConditions);
            localStorage.removeItem(`savedConditions`);

            savedConditionGroupsArray.push(...savedLegacyConditionGroupsArray);
        }

        const copyOfConditionGroup = JSON.parse(JSON.stringify(conditionGroup))
        copyOfConditionGroup.sourceGroupId = uuidv4()
        copyOfConditionGroup.id = uuidv4()
        savedConditionGroupsArray.push(copyOfConditionGroup)

        ReactGA.event('condition_group_save', {
            groups: copyOfConditionGroup.conditionGroups.length,
            conditions: copyOfConditionGroup.conditions.length
        });

        localStorage.setItem(`savedConditions-${tradingHouse}`, JSON.stringify(savedConditionGroupsArray))
    }

    const options = [
        {label: "New Condition", action: () => canEdit && handleAddCondition(conditionGroup.id), icon: <SpeakerNotesOutlinedIcon/>},
        {label: "New Group", action: () => canEdit && handleAddNestedGroup(conditionGroup.id), icon: <PlaylistAddIcon/>},
        {label: "Duplicate", action: () => canEdit && handleConditionGroupDuplicate(conditionGroup.id), icon: <ContentCopyIcon/>},
        {label: `Copy to ${activeTab === 'buy' ? 'sell' : 'buy'}`, action: () => canEdit && handleConditionGroupCopyTo(conditionGroup.id), icon: <FlipCameraAndroidIcon/>},
        {label: "Save Group", action: () => canEdit && handleSaveConditionGroup(), icon: <StarBorderIcon/>},
        {label: <Divider/>, action: () => {}},
        {
            label: "Delete group",
            action: () => {
                if (localStorage.getItem("ConfirmRemoveConditionGroup") || conditionGroup.conditions.length === 0) {
                    canEdit && handleRemoveConditionGroup(conditionGroup.id);
                } else {
                    canEdit && setDeleteDialogOpen(true);
                }
            },
            color: 'rgb(201,80,80)',
            icon: <PlaylistRemoveIcon/>
        },
    ]
    const optionsWithoutNewGroup = options.filter(option => option.label !== "New Group");
    const menuOptions = conditionGroup.depth < MAX_CONDITION_GROUP_DEPTH ? options : optionsWithoutNewGroup

    function getBorderColor() {
        return theme.palette.background.custom4;
    }

    function openMarketplace() {
        window.open('https://myaccount.greenchart.com/dashboard/marketplace?display=Strategy%20Suite', '_blank');
    }

    return (
        <Box
            ref={canEdit ? drop : undefined}
            className={"condition-group"}
            style={{
                borderTop: `solid 2px  ${getBorderColor()}`,
                borderLeft: `solid 2px ${getBorderColor()}`,
                borderBottom: `solid 1px ${getBorderColor()}`,
                marginLeft: conditionGroup.depth > 1 ? 64 : 8,
                color: "white"
            }}
        >
            <Overlay active={!canEdit}>

                <ConditionGroupHeader
                    ref={drag}
                    className={"condition-group-header"}
                    sx={{
                        borderBottom: `solid 1px ${getBorderColor()}`,
                        backgroundColor: theme.palette.background.custom3,
                    }}
                >
                    <Box display={"flex"} gap={4} alignItems={"center"}>
                        <Tooltip title={`${conditionGroup.count} / ${(conditionGroup.conditions.length || 0) + conditionGroup.conditionGroups?.length || 0} condition(s) need to be met`} arrow placement={"top"}>
                            <Box display={"flex"} alignItems={"center"}>
                                <Select
                                    value={conditionGroup.count}
                                    onChange={(e) => handleChangeConditionGroup(conditionGroup.id, "count", e.target.value)}
                                    sx={{
                                        boxShadow: "none",
                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                        color: 'white',
                                        '& .MuiSelect-icon': {
                                            color: 'white',
                                        }
                                    }}
                                >
                                    {
                                        Array.from({ length: (conditionGroup.conditions?.length ?? 0) + (conditionGroup.conditionGroups?.length ?? 0) + 1 }).map(
                                            (_, index) => (
                                                <MenuItem key={index} value={index} selected={index === conditionGroup.count}>
                                                    {index}
                                                </MenuItem>
                                            ),
                                        )
                                    }

                                </Select>
                                <Typography marginLeft={0}> / {(conditionGroup.conditions?.length ?? 0) + (conditionGroup.conditionGroups?.length ?? 0)}</Typography>
                            </Box>
                        </Tooltip>

                        <ChartDetailInput details={conditionGroup} handleChange={(key: string, value: any) => handleChangeConditionGroup(conditionGroup.id, key, value)} />
                    </Box>


                    <TextField
                        label="Group message"
                        variant={"standard"}
                        size={"small"}
                        multiline
                        maxRows={groupConditionMessageRows}
                        sx={{minWidth: 600, alignSelf: "flex-start", margin: "auto 0"}}
                        value={conditionGroup.groupTitle}
                        onFocus={() => setGroupConditionMessageRows(6)}
                        onBlur={() => setGroupConditionMessageRows(1)}
                        onChange={(e) => handleChangeConditionGroup(conditionGroup.id, "groupTitle", e.target.value)}
                    />


                    <Box display={"flex"} alignItems={"center"}>
                        {
                            conditionsVisible
                                ? <Tooltip title={"Hide conditions"} arrow><VisibilityIcon onClick={() => canView && setConditionsVisible(false)} sx={{cursor: "pointer"}}/></Tooltip>
                                : <Tooltip title={"Show conditions"} arrow><VisibilityOffIcon onClick={() => canView && setConditionsVisible(true)} sx={{cursor: "pointer"}}/></Tooltip>
                        }

                        <GCMenu
                            items={menuOptions}
                            title={<MenuIcon/>}/>
                    </Box>
                </ConditionGroupHeader>
            </Overlay>


            <Box sx={{background: !conditionsVisible ? `repeating-linear-gradient(45deg,  ${theme.palette.background.custom1}, ${theme.palette.background.custom1} 10px, ${getBorderColor()} 10px, ${getBorderColor()} 20px)` : '#313335'}}>

                {
                    conditionsVisible && canView && <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 8, padding: '8px'}} ref={ref}>
                        {(conditionGroup.conditions || []).map((condition: { id: any, condition: any }, index: number) => (
                            <Box key={condition.id} style={{ display: "flex", gap: 8, }}>
                                {itemType === "condition" && isOver && canDrop && dragId !== condition.id && dropIndex === index &&
                                    isAfterPrevious.current && <DropIndicator color={"rgb(0, 161, 255, .4)"} />}
                                <Box data-condition-id={condition.id}>
                                    <Condition
                                        viewOnly={!canEdit}
                                        condition={condition}
                                        sourceGroupId={conditionGroup.id}
                                        index={index}
                                        depth={conditionGroup.depth}
                                    />
                                </Box>
                                {itemType === "condition" &&
                                    isOver &&
                                    canDrop &&
                                    dragId !== condition.id &&
                                    dropIndex === index &&
                                    !isAfterPrevious.current &&
                                  <DropIndicator color={"rgb(0, 161, 255, .4)"} />
                                }
                            </Box>
                        ))}

                        {canEdit && <PhantomCondition groupId={conditionGroup.id}/>}
                    </Box>
                }


                {!conditionsVisible && canView && <Box display={"flex"} height={"100%"} alignItems={"center"} p={1} gap={1}>
                    <Tooltip title={"Show conditions"} arrow onMouseEnter={(e) => e.currentTarget.style.color = `rgb(0, 161, 255)`} onMouseLeave={(e) => e.currentTarget.style.color = `unset`}>
                        <VisibilityOffIcon onClick={() => canView && setConditionsVisible(true)} sx={{cursor: "pointer"}}/>
                    </Tooltip>
                </Box>
                }

                {
                    privateGroup && <Box display={"flex"} height={"100%"} alignItems={"center"} p={1} gap={1}>
                    <Tooltip title={"Please upgrade to view Showcased patterns."} arrow onMouseEnter={(e) => e.currentTarget.style.color = `rgb(0, 161, 255)`} onMouseLeave={(e) => e.currentTarget.style.color = `unset`}>
                      <LockIcon onClick={() => openMarketplace()} sx={{cursor: "pointer"}}/>
                    </Tooltip>
                  </Box>
                }

                {itemType === "conditionGroup" && isOver && canDrop && <DropIndicator color={"red"}/>}

                <Box style={{display: 'flex', flexDirection: "column"}}>
                    {(conditionGroup.conditionGroups || []).map((group: any) => (
                        <ConditionGroup
                            privateGroup={privateGroup}
                            canView={canView}
                            canEdit={canEdit}
                            conditionsVisible={conditionsVisible}
                            key={group.id}
                            sourceGroupId={conditionGroup.id}
                            conditionGroup={group}
                            depth={conditionGroup.depth+1}
                            dropIndex={insertIndex}
                        />
                    ))}
                </Box>
            </Box>

            <GCModalConfirmation
                modalBody={'Deleting this group will delete all conditions and nested group conditions.'}
                modalTitle={"Delete group?"}
                cancelText={"Cancel"}
                shouldShowAgainOption={true}
                onCancel={() => {}}
                setOpen={(val) => () => setDeleteDialogOpen(val)}
                open={deleteDialogOpen}
                onSubmit={(showAgain) => {
                    handleRemoveConditionGroup(conditionGroup.id)
                    if (showAgain) {
                        localStorage.setItem("ConfirmRemoveConditionGroup", new Date().toLocaleString())
                    }
            }}
                submitText={"Delete"}
                text={"Delete Group"}
            />

        </Box>

    );
};

export default ConditionGroup;
