import {initializeApp} from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";

let firebase_app: any;
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    firebase_app = initializeApp({
        apiKey: "AIzaSyD33m4VSGtjXLketIRCi_68hEngNgMe2DM",
        authDomain: "greenchart-93a2a.firebaseapp.com",
        databaseURL: "https://greenchart-93a2a.firebaseio.com",
        projectId: "greenchart-93a2a",
        storageBucket: "greenchart-93a2a.appspot.com",
        messagingSenderId: "86344372816",
        appId: "1:86344372816:web:2bfdc62c0d06f88c86f755",
        measurementId: "G-WM1QNRNRQC"
    });
} else {
    firebase_app = initializeApp({
        apiKey: "AIzaSyBsEthvwyyBqUIcykIIQpXusrgQ6Je16RI",
        authDomain: "greenchart-dev.firebaseapp.com",
        databaseURL: "https://greenchart-dev-default-rtdb.firebaseio.com",
        projectId: "greenchart-dev",
        storageBucket: "greenchart-dev.appspot.com",
        messagingSenderId: "506245531367",
        appId: "1:506245531367:web:52d1097c63030820b40442",
        measurementId: "G-2GYK8TEYFX"
    });
}

const firebase_auth = getAuth(firebase_app);
const firebase_firestore = getFirestore(firebase_app);
const firebase_functions = getFunctions(firebase_app);
const firebase_realtime = getDatabase(firebase_app);

export { firebase_firestore, firebase_auth, firebase_functions, firebase_realtime};

