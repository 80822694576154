import React, {useState} from "react";

const useSearch = () => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value.toLowerCase());
    };
    
    const resetSearch = () => {
        setSearchQuery('');
    }

    return { searchQuery, handleSearchChange, resetSearch };
};

export default useSearch;