import Indicator from "../../types/Indicator";
import Value from "../../Value";
import conditionTypes from "../../../conditions";

const ThickBlue: Indicator = {
    value: "thickBlue",
    label: "Thick Blue",
    fields: ["thickBlue"],
    RHS: () => [Value],
    conditions: conditionTypes,
}


export default ThickBlue;
