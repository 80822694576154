import {useTheme} from "@mui/material/styles";
import {useApplicationSuiteContext} from "../contexts/ApplicationSuiteProvider";
import React from "react";
import {SelectChangeEvent} from "@mui/material/Select";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const NavigationSelect = () => {
    const theme = useTheme();
    const { setActiveTab, activeTab } = useApplicationSuiteContext();
    const [selectedOption, setSelectedOption] = React.useState<string>(activeTab.toString());

    const options = [
        { id: "0", name: "Patterns" },
        { id: "1", name: "Backtest" },
        { id: "2", name: "Reports" },
        { id: "3", name: "Training" },
        { id: "4", name: "Showcase" }
    ];

    const handleChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value as string;
        setSelectedOption(value);
        setActiveTab(parseInt(value));
    };

    return (
        <Box sx={{ width: '140px' }}>
            <FormControl size="medium" fullWidth>
                <InputLabel id="navigation-select-label">Application</InputLabel>
                <Select
                    labelId="navigation-select-label"
                    id="strategy-suite-navigation"
                    sx={{ textAlign: "left", background: theme.palette.background.custom4 }}
                    label="Application"
                    value={selectedOption}
                    onChange={handleChange}
                >
                    {options.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default NavigationSelect;
