import React, {useContext, useEffect, useState} from "react";
import {PatternContext} from "../../../contexts/PatternContext";
import {useApplicationSuiteContext} from "../../../../../contexts/ApplicationSuiteProvider";
import * as jsondiffpatch from "jsondiffpatch";
import {diff_match_patch} from "diff-match-patch";
import {useTheme} from "@mui/material/styles";
import {collection, doc, getDoc, getDocs, orderBy, query} from "firebase/firestore";
import {firebase_firestore} from "../../../../../common/firebaseConfig";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import ReactGA from "react-ga4";

const VersionControl = (props: { sx: any }) => {
    const { sx } = props;

    const {
        setPattern,
        pattern,
    } = useContext(PatternContext);

    const {
        usermeta,
        tradingHouse
    } = useApplicationSuiteContext()

    const jsondiffpatchInstance = jsondiffpatch.create({
        textDiff: {
            diffMatchPatch: diff_match_patch,
        },
    });

    const theme = useTheme();

    const fetchVersions = async (path: string) => {
        const q = query(collection(firebase_firestore, path), orderBy("version"));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => doc.data());
    };


    const [versions, setVersions] = useState<any[]>([])

    useEffect(() => {
        const userDocPath = `usermeta/${usermeta.gcid}/patterns/${pattern.id}`;
        const userDocRef = doc(firebase_firestore, userDocPath);

        getDoc(userDocRef).then(docSnapshot => {
            const versionsPath = docSnapshot.exists() ?
                `${userDocPath}/versions` :
                `tradingHouses/${tradingHouse}/standardPatterns/${pattern.id}/versions`;

            return fetchVersions(versionsPath);
        }).then(docs => {
            setVersions(docs);
        });

    }, [usermeta.gcid, pattern.version, pattern.id, tradingHouse]);

    /**
     * Reconstructs an object to the given version using a history of diffs.
     * @param {number} targetVersion - The version to reconstruct.
     * @param {Array} histories - The array of history objects, each with a version and diff.
     * @returns {Object} - The reconstructed object at the specified version.
     */
    function reconstructToVersion(targetVersion: number, histories: any[]) {
        // Check if target version is valid
        const latestVersion = histories[histories.length - 1].version;
        if (targetVersion > latestVersion) {
            console.warn("The target version is greater than the latest version in the histories provided.");
            return null;
        }

        let obj = jsondiffpatchInstance.patch({}, JSON.parse(histories[0].diff));  // Start with version 1

        // Apply each diff sequentially until we reach the desired version
        for (let i = 1; i < targetVersion; i++) {
            obj = jsondiffpatchInstance.patch(obj, JSON.parse(histories[i].diff));
        }

        return obj;
    }

    return (
        pattern.id && versions && versions.length ?
            <Box sx={{...sx, width: '80px'}}>
                <FormControl fullWidth>
                    <InputLabel id="layout-select-label">Version</InputLabel>
                    <Select
                        sx={{background: theme.palette.background.custom4}}
                        value={pattern.version}
                        onChange={(selectedVersion: any) => {
                            const selectedVersionId = selectedVersion.target.value

                            const newPattern = reconstructToVersion(selectedVersionId, versions)

                            if (newPattern) {
                                ReactGA.event('pattern_viewed_previous_version', {
                                    version: selectedVersionId
                                });

                                newPattern.alertVersion = pattern.alertVersion || 1
                                setPattern(newPattern)
                            }
                        }}
                        label="Versions"
                    >
                        {versions.map((versionObj: any) => (
                            <MenuItem key={versionObj.version} value={versionObj.version}>
                                v{versionObj.version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            : null
    )
}

export default VersionControl;