
import lotSizeCalculatorFilter from './lotSizeCalculatorFilter';
import tradeSimulatorFilter from "./tradeSimulatorFilter";

const filters = {
    lotSizeCalculator: lotSizeCalculatorFilter,
    patternAlerts: undefined,
    navigationBar: undefined,
    tradingSimulator: tradeSimulatorFilter,
    backtester: undefined,
};

export default filters;