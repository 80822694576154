import {Card, CardContent, CardHeader, List, ListItem, ListItemText, Typography, ListItemIcon} from "@mui/material";
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

function PatternBuilderFeaturesToComeCard() {
    return (
        <Card variant="outlined" sx={{backgroundColor: (theme) => theme.palette.background.custom4}}>
            <CardHeader
                title={
                    <Typography variant="h4" className={"ad-card-header"}>
                        Coming Soon!
                    </Typography>
                }
            />

            <CardContent>
                <Typography variant="body1" paragraph>
                    We're always innovating to offer you the best tools. Here are some exciting features to look forward to:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <PrecisionManufacturingIcon className={"ad-primary-text-color"}/>
                        </ListItemIcon>
                        <ListItemText
                            className={"ad-primary-text-color"}
                            primary="AI Generative Building"
                            secondary="Let AI assist in crafting and optimizing your patterns."
                        />
                    </ListItem>
                </List>
            </CardContent>
        </Card>

    );
}

export default PatternBuilderFeaturesToComeCard;
