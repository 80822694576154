import Indicator from "../types/Indicator";
import Value from "../Value";
import conditionTypes from "../../conditions";

const AverageDirectionalIndex: Indicator = {
    value: "averageDirectionalIndex",
    label: "Average Directional Index",
    nickname: "ADX",
    fields: ["mdi", "pdi", "adx"],
    params: {
        period: 14,
    },
    RHS: () => [AverageDirectionalIndex, Value],
    conditions: conditionTypes,
};


export default AverageDirectionalIndex;
