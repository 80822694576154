import React, { useState, useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Autocomplete, Box, TextField, Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ActionBar from "../../../../common/components/actionBar/ActionBar";
import setNestedValue from "lodash.set";
import { useBacktestContext } from "../../contexts/BacktestContext";
import { SnackbarHelperContext } from "../../../../contexts/SnackbarHelperContext";
import BacktestSidebar from "../../app/BacktestSidebar";
import MenuIcon from '@mui/icons-material/Menu';
import NavigationSelect from "../../../../components/NavigationSelect";
import TradingHouseSelector from "../../../../components/TradingHouseSelector";
import RemainingBalance from "../../../../components/RemainingBalance";
import {useApplicationSuiteContext} from "../../../../contexts/ApplicationSuiteProvider";

const BacktestHeaderBar: React.FC = () => {
    const { setBacktestSettingInputData, backtestSettingInputData } = useBacktestContext();
    const { canToggleTradingHouse } = useApplicationSuiteContext();
    const initialBacktestOptions = JSON.parse(localStorage.getItem('savedBacktestOptions') || '[]');
    const [backtestOptions, setBacktestOptions] = useState(initialBacktestOptions);
    const { setSnackbarSettings } = useContext(SnackbarHelperContext);
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const menuButton = () => {
        return (
            <Box sx={{ color: 'white', alignContent: 'center', display: 'flex' }}>
                <IconButton onClick={toggleSidebar}>
                    <MenuIcon />
                </IconButton>
            </Box>
        );
    };

    useEffect(() => {
        const handleStorageChange = (event: any) => {
            if (event.key === 'savedBacktestOptions') {
                setBacktestOptions(JSON.parse(event.newValue || '[]'));
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    function handleDeleteBacktestOption(op: any) {
        const savedBacktestOptions = JSON.parse(localStorage.getItem('savedBacktestOptions') || '[]');
        const newSavedBacktestOptions = savedBacktestOptions.filter((option: any) => option.id !== op.id);
        localStorage.setItem('savedBacktestOptions', JSON.stringify(newSavedBacktestOptions));
        setBacktestOptions(newSavedBacktestOptions);
        setSnackbarSettings({ message: 'Backtest settings deleted.', severity: 'success', autoHideDuration: 6000 });
    }

    return (
        <>
            <ActionBar
                left={
                    <Box display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'}>
                        <NavigationSelect />
                        {canToggleTradingHouse && <TradingHouseSelector/>}
                        {!isTabletOrMobile && <Box sx={{width: '300px'}}>
                            <Autocomplete
                                freeSolo
                                options={backtestOptions}
                                value={backtestSettingInputData}
                                onChange={(_, val: any) => val && setBacktestSettingInputData(val)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{background: theme.palette.background.custom4}}
                                        onChange={(e) => {
                                            setBacktestSettingInputData((prevState: any) => {
                                                const newState = {...prevState};
                                                setNestedValue(newState, 'name', e.target.value);
                                                return newState;
                                            })
                                        }}
                                        label="Backtest settings name"
                                        fullWidth
                                    />
                                )}
                                getOptionLabel={(option) => typeof option === 'string' ? "" : option.name}
                                renderOption={(props, option, {selected}) => (
                                    <li {...props} key={option.id}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <>
                                                {option.name}
                                                <IconButton
                                                    size="small"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleDeleteBacktestOption(option);
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit"/>
                                                </IconButton>
                                            </>
                                        </Box>
                                    </li>
                                )}
                            />
                        </Box>}
                    </Box>
                }
                right={
                <Box display={'flex'} alignItems={'center'}>
                    <RemainingBalance />
                    {isTabletOrMobile && menuButton()}
                </Box>
            }
                position={'top'}
            />
            {isTabletOrMobile && (
                <Drawer
                    anchor="right"
                    open={isSidebarOpen}
                    onClose={toggleSidebar}
                >
                    <BacktestSidebar />
                </Drawer>
            )}
        </>
    );
};

export default BacktestHeaderBar;
