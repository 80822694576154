import Indicator from "../types/Indicator";
import {overlayIndicators} from "./index";
import Open from "../Open";
import High from "../High";
import Low from "../Low";
import Close from "../Close";
import Value from "../Value";
import conditionTypes from "../../conditions";

const DonchianChannel: Indicator = {
    value: "donchianChannel",
    label: "Donchian Channel",
    nickname: "Donchian Channel",
    fields: ["middle", "upper", "lower"],
    params: {
        highPeriod: 20,
        lowPeriod: 20,
    },
    RHS: () => [...overlayIndicators, Open, High, Low, Close, Value],
    conditions: conditionTypes,
}


export default DonchianChannel;
