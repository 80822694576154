import React, {useContext, useEffect} from "react";
import {
    Autocomplete,
    Box,
    TextField
} from "@mui/material";
import "./ConditionSelector.css"
import IndicatorParameterDialog from "./indicatorParameterDialogInputs/IndicatorParameterDialog";
import _cloneDeep from "lodash.clonedeep";
import {ConditionType as ConditionBlock} from "../contexts/PatternTreeContext";
import Overlay from "../components/overlay/Overlay";
import ConditionType from "./conditions/ConditionType";
import conditions from "./conditions";
import {PatternContext} from "../contexts/PatternContext";

interface ConditionSelectorProps {
    condition: ConditionBlock["condition"],
    onChangeCondition?: (key: string, value: any) => void,
    viewOnly?: boolean,
    setConditionIsValid?: (isValid: boolean) => void
}

const ConditionSelector: React.FC<ConditionSelectorProps> = ({
                                                                 condition,
                                                                 onChangeCondition = () => {},
                                                                 viewOnly = false,
                                                                 setConditionIsValid,
                                                             }) => {

    const {
        getIndicatorByValue,
        startingIndicators,
    } = useContext(PatternContext)


    function getComparisonByValue(comparisonName: string | undefined): ConditionType | undefined {
        return conditions.find(condition => condition.value === comparisonName)
    }

    useEffect(() => {
        const conditionBlock: ConditionBlock["condition"] = condition;

        const LHS = getIndicatorByValue(conditionBlock.LHS?.indicator?.name);
        const conditionType: ConditionType | undefined = conditions.find(condition => condition.value === conditionBlock.conditionType);

        let isValid = false
        if (LHS && conditionType) {
            if (conditionType.hasRHS) {
                if (getIndicatorByValue(conditionBlock.RHS?.indicator?.name) || conditionBlock.RHS?.indicator?.name === 'value') {
                    isValid = true
                } else {
                    isValid = false
                }
            } else {
                isValid = true
            }
        } else {
            isValid = false
        }

        if (setConditionIsValid && condition.isValid !== isValid) {
            setConditionIsValid(isValid)
        }
    }, [condition, getIndicatorByValue, setConditionIsValid])



    function reverseCamelCase(str: string | undefined){
        if (!str) return str;
        const spaced = str.replace(/([A-Z])/g, ' $1');
        const capitalized = spaced.charAt(0).toUpperCase() + spaced.slice(1);
        return capitalized;
    }

    return (
        <>
            <Overlay active={viewOnly}>

                <Box display={"flex"} gap={2}>
                    {/*
                LHS SELECTION
            */}
                    <Box display={"flex"} flexDirection={"column"} gap={1}>
                        <Autocomplete
                            disabled={viewOnly}
                            value={getIndicatorByValue(condition?.LHS?.indicator?.name) ?? null}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    onChangeCondition("LHS.indicator.name", newValue.value)
                                    onChangeCondition("LHS.field", newValue.fields[0])
                                    onChangeCondition("LHS.indicator.params", _cloneDeep(newValue.params))
                                }
                            }}
                            options={startingIndicators.filter(indicator => indicator.value !== 'value')}
                            className="condition-selector-input"
                            getOptionLabel={(option) => {
                                if (!option) return ""
                                return (option.label.length > 13 && option.nickname) ? option.nickname : option.label
                            }}
                            renderInput={(params) => <TextField {...params} label="Indicator" />}
                            sx={{ minWidth: 200 }}
                        />
                        {getIndicatorByValue(condition?.LHS?.indicator?.name)?.fields?.length! > 1 && (
                            <Autocomplete
                                disabled={viewOnly}
                                value={getIndicatorByValue(condition?.LHS?.indicator?.name)?.fields?.find(field => field === condition?.LHS?.field) ?? null}
                                getOptionLabel={(option) => reverseCamelCase(option)!}
                                onChange={(_, newValue) => newValue && onChangeCondition("LHS.field", newValue)}
                                options={getIndicatorByValue(condition?.LHS?.indicator?.name)?.fields ?? []}
                                renderOption={(props, option) => (
                                    <li {...props}>{reverseCamelCase(option)}</li>
                                )}
                                className="condition-selector-input"
                                renderInput={(params) => <TextField {...params} label="Output" />}
                                sx={{ minWidth: 200 }}
                            />
                        )}


                        {
                            condition?.LHS?.indicator?.params && <IndicatorParameterDialog
                                onIndicatorParameterChange={(key: string, value: any) => onChangeCondition(`LHS.indicator.params.${key}`, value)}
                                parameters={condition.LHS.indicator.params}
                                indicatorName={getIndicatorByValue(condition?.LHS?.indicator?.name)?.label}
                                viewOnly={viewOnly}
                              />
                        }

                    </Box>

                    {/*
                CONDITION TYPE SELECTION
            */}
                    <Box>
                        {(condition?.LHS?.field || getIndicatorByValue(condition?.LHS?.indicator?.name)?.fields?.length! === 1) && (
                            <Autocomplete
                                disabled={viewOnly}
                                value={getComparisonByValue(condition?.conditionType!) ?? null}
                                onChange={(_, newValue) => {
                                    if (newValue) {
                                        onChangeCondition("conditionType", newValue.value)
                                    }
                                }}
                                options={getIndicatorByValue(condition?.LHS?.indicator?.name)?.conditions ?? []}
                                getOptionLabel={(option) => {
                                    if (!option) return ""
                                    return option.label
                                }}
                                className="condition-selector-input"
                                renderInput={(params) => <TextField {...params} label="Condition" />}
                                sx={{ minWidth: 200 }}
                            />
                        )}
                    </Box>

                    {/*
                RHS SELECTION
            */}
                    <Box display={"flex"} flexDirection={"column"} gap={1}>
                        {condition?.conditionType && getComparisonByValue(condition?.conditionType!)?.hasRHS && (
                            <Autocomplete
                                disabled={viewOnly}
                                value={getIndicatorByValue(condition.RHS?.indicator?.name) ?? null}
                                onChange={(_, newValue) => {
                                    if (newValue) {
                                        onChangeCondition("RHS.indicator.name", newValue.value)
                                        onChangeCondition("RHS.field", newValue.fields[0])
                                        onChangeCondition("RHS.indicator.params", _cloneDeep(newValue.params))
                                        if (newValue.value === 'value') {
                                            onChangeCondition("RHS.value", 0)
                                        }
                                    }
                                }}
                                className="condition-selector-input"
                                options={(() => {
                                    const lhs = getIndicatorByValue(condition.LHS!.indicator.name)

                                    if (!lhs || !lhs.RHS) return []

                                    return lhs.RHS();
                                })()}
                                getOptionLabel={(option) => {
                                    if (!option) return ""
                                    return option.label.length > 10 && option.nickname ? option.nickname : option.label
                                }}
                                renderInput={(params) => <TextField {...params} label="Indicator"/>}
                                sx={{ minWidth: 200 }}
                            />
                        )}

                        {
                            condition?.RHS?.indicator && (condition?.RHS.indicator?.name === 'value' || getIndicatorByValue(condition!.RHS?.indicator?.name)?.fields[0] === "value")
                                ? (<>
                                    <TextField
                                        disabled={viewOnly}
                                        variant={"outlined"}
                                        value={condition?.RHS.value ?? 0}
                                        className="condition-selector-input"
                                        onChange={(e) => onChangeCondition("RHS.value", e.target.value)}
                                        type={"number"}
                                    />
                                </>)
                                :
                                <>{
                                    getIndicatorByValue(condition?.RHS?.indicator?.name)?.fields?.length! > 1 && (
                                        <Autocomplete
                                            disabled={viewOnly}
                                            value={reverseCamelCase(getIndicatorByValue(condition?.RHS?.indicator?.name)?.fields?.find(field => field === condition?.RHS?.field)) ?? null}
                                            onChange={(_, newValue) => newValue && onChangeCondition("RHS.field", newValue)}
                                            options={getIndicatorByValue(condition?.RHS?.indicator?.name)?.fields ?? []}
                                            renderOption={(props, option) => (
                                                <li {...props}>{reverseCamelCase(option)}</li>
                                            )}
                                            className="condition-selector-input"
                                            renderInput={(params) => <TextField {...params} label="Output" />}
                                            sx={{ minWidth: 200 }}
                                        />
                                    )
                                }</>
                        }

                        {
                            condition?.RHS?.indicator?.params && <IndicatorParameterDialog onIndicatorParameterChange={(key: string, value: any) => onChangeCondition(`RHS.indicator.params.${key}`, value)} parameters={condition.RHS.indicator.params} indicatorName={getIndicatorByValue(condition?.RHS?.indicator?.name)?.label} viewOnly={viewOnly}/>
                        }

                    </Box>

                </Box>
            </Overlay>

        </>

    );
};

export default ConditionSelector;

