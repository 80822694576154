import {
    Autocomplete, Chip, Grid, TextField, createFilterOptions
} from "@mui/material";
import React, {useState} from "react";
import ExitStrategiesProps from "./ExitStrategiesProps";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

const filter = createFilterOptions<any>();

export default function FixedExitStrategyInput(props: ExitStrategiesProps) {
    const {backtestSettingInputData, handleInputChange} = props;

    const [risk, setRisk] = useState('');
    const [reward, setReward] = useState('');

    const handleKeyPress = (event: any) => {
        if(event.key === 'Enter') {
            if (verifyRiskRewardRatio(event.target.value)) {
                const [risk, reward] = event.target.value.split('/');
                handleInputChange('generateTrades.tradeRatios', [
                    ...backtestSettingInputData.generateTrades.tradeRatios,
                    { risk: parseFloat(risk), reward: parseFloat(reward) },
                ]);
            }
        }
    }

    const handleAdd = () => {
        if (verifyRiskRewardRatio(`${risk}/${reward}`)) {
            handleInputChange('generateTrades.tradeRatios', [
                ...backtestSettingInputData.generateTrades.tradeRatios,
                { risk: parseFloat(risk), reward: parseFloat(reward) },
            ]);
            setRisk('');
            setReward('');
        }
    };

    function verifyRiskRewardRatio(ratio: string) {
        const onlyNumbersLeftAndRightOfBackslash = new RegExp(/^(\d{1,3}(,\d{3})*(\.\d+)?|\d*\.?\d+)\/(\d{1,3}(,\d{3})*(\.\d+)?|\d*\.?\d+)/);
        return onlyNumbersLeftAndRightOfBackslash.test(ratio)
    }

    return (
        <>
            <Grid item xs={6}>
                <Autocomplete
                    multiple
                    disableCloseOnSelect={true}
                    id="tags-filled"
                    onChange={(event, newValue: any) => {
                        newValue = newValue
                            .filter((val: any) => verifyRiskRewardRatio(val.inputValue ? val.inputValue : val))
                            .map((val: any) => {
                                if (typeof val === 'string') {
                                    return {
                                        risk: parseFloat(val.split("/")[0]),
                                        reward: parseFloat(val.split("/")[1])
                                    }
                                } else {
                                    let inputValue = val.inputValue;
                                    return {
                                        risk: parseFloat(inputValue.split("/")[0]),
                                        reward: parseFloat(inputValue.split("/")[1])
                                    }
                                }
                            })
                        handleInputChange("generateTrades.tradeRatios", newValue)
                    }}
                    options={backtestSettingInputData.generateTrades.tradeRatios.map((option: { risk: any; reward: any; }) =>  `${option.risk}/${option.reward}`)}
                    value={backtestSettingInputData.generateTrades.tradeRatios.map((option: any) => `${option.risk}/${option.reward}`)}
                    renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === `${option.risk}/${option.reward}`);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                title: `Add "${inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Risk / Reward"
                            placeholder=""
                            onKeyPress={handleKeyPress}
                        />
                    )}
                />
            </Grid>
            <Grid container item spacing={2} alignItems={"center"}>
                <Grid item xs={5} lg={3}>
                    <TextField
                        value={risk}
                        fullWidth
                        onChange={(e) => setRisk(e.target.value)}
                        variant="outlined"
                        label="Risk"
                        placeholder=""
                    />
                </Grid>
                <Grid item xs={5} lg={3}>
                    <TextField
                        value={reward}
                        fullWidth
                        onChange={(e) => setReward(e.target.value)}
                        variant="outlined"
                        label="Reward"
                        placeholder=""
                    />
                </Grid>
                <Grid item xs={2} lg={3}>
                    <IconButton onClick={handleAdd}>
                        <AddIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </>
    )
}
