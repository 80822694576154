import React from 'react';
import { Chip } from '@mui/material';

interface ITypeFilterChipsProps {
    types: string[];
    filteredTypes: string[];
    handleTypeClick: (type: string) => void;
    isTypeSelected: (type: string) => boolean;
    isTypeEnabled: (type: string) => boolean;
}

const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

const TypeFilterChips: React.FC<ITypeFilterChipsProps> = ({ types, filteredTypes, handleTypeClick, isTypeSelected, isTypeEnabled }) => {
    // Sort the types array so that enabled types come first
    const sortedTypes = [...types].sort((a, b) => {
        if (isTypeEnabled(a) && !isTypeEnabled(b)) {
            return -1; // a comes before b
        } else if (!isTypeEnabled(a) && isTypeEnabled(b)) {
            return 1; // b comes before a
        }
        return 0; // Default order if both are either enabled or disabled
    });

    return (
        <div>
            {sortedTypes.map(type => (
                <Chip
                    key={type}
                    label={capitalizeFirstLetter(type)}
                    onClick={() => handleTypeClick(type)}
                    disabled={!isTypeEnabled(type)}
                    style={{
                        margin: '5px',
                        backgroundColor: isTypeSelected(type) ? '#00A1ff' : undefined,
                        color: isTypeSelected(type) ? 'white' : undefined,
                    }}
                />
            ))}
        </div>
    );
};

export default TypeFilterChips;
