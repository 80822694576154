import React from 'react';
import ListItem from '@mui/material/ListItem';
import { Typography, Box } from '@mui/material';

interface ISymbolItemProps {
    symbol: {
        name: string;
        type: string;
        category?: string;
        description?: string;
    };
    onClick: () => void;
    selected: boolean;
    dataIndex: number;
}

const SymbolItem: React.FC<ISymbolItemProps> = ({ symbol, onClick, selected, dataIndex }) => {
    return (
        <ListItem
            button
            onClick={onClick}
            selected={selected}
            data-index={dataIndex}
            // style={{backgroundColor: 'transparent'}}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                bgcolor: selected ? '#565758' : 'transparent',
                '&:hover': {
                    backgroundColor: '#565758',
                },
                '&.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: '#565758',
                    outline: 'none'
                },
                color: 'white',
                alignItems: 'center', // Ensure vertical alignment
                paddingRight: 2, // Add padding to ensure the type doesn't touch the edge
            }}
        >
            <Box flex={1} justifyContent="flex-start">
                <Typography variant="subtitle1" component="span" style={{ fontWeight: 'bold' }}>
                    {symbol.name}
                </Typography>
                <Typography variant="subtitle2" component="span" style={{ color: '#a8a8a8', fontWeight: 300, paddingLeft: '12px'}}>
                    {symbol.category}
                </Typography>
            </Box>
            <Box flex={1} justifyContent="flex-start">
                <Typography variant="caption" component="span" color="text.secondary" style={{ fontWeight: 300 }}>
                    {symbol.description}
                </Typography>
            </Box>
            <Box justifyContent="flex-end">
                <Typography variant="subtitle1" component="span" color="text.secondary" style={{ fontWeight: 300, textTransform: 'uppercase' }}>
                    {symbol.type}
                </Typography>
            </Box>
        </ListItem>
    );
};

export default SymbolItem;
