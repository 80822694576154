import React from "react";
import "./Overlay.css";

export default function Overlay(props: any) {

    return (
        <div
            className={`overlay-container ${props.active ? 'disabled' : ''}`}
            style={{position: 'relative'}}
        >
            {props.children}
            {props.active && <div className="overlay"></div>}
        </div>
    );
}
