// import ChartLinker from "../../../app/helpers/ChartLinker";
import {ISymbol} from "../SymbolSelect";

const tradeSimulatorFilter = (symbols: ISymbol[]) => {
    // const symbolsOnChartSet = new Set<string>();

    // // Find all current symbols on the charts and add them to the Set
    // ChartLinker.charts.forEach((chart: any) => {
    //     symbolsOnChartSet.add(chart.stx.chart.symbol);
    // });

    // return symbols.filter((symbol: ISymbol) => symbolsOnChartSet.has(symbol.name));
    return [];
};

export default tradeSimulatorFilter;
