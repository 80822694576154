import {Grid} from "@mui/material";
import React from "react";
import AtrInputs from "./AtrInputs";
import ExitStrategiesProps from "./ExitStrategiesProps";

export default function ATRExitStrategyInput(props: ExitStrategiesProps) {
    const {backtestSettingInputData, handleInputChange} = props;

    return (
        <>
            <Grid container item spacing={2}>
                <Grid item xs={12}>
                    <AtrInputs
                        onATRPeriodChange={(val) => handleInputChange("generateTrades.atrPeriod", val)}
                        onChartPeriodChange={(val) => handleInputChange("generateTrades.atrChartPeriod", val)}
                        onChartTimeframeChange={(val) => handleInputChange("generateTrades.atrChartTimeframe", val)}
                        atrPeriod={backtestSettingInputData.generateTrades.atrPeriod}
                        chartPeriod={backtestSettingInputData.generateTrades.atrChartPeriod}
                        chartTimeframe={backtestSettingInputData.generateTrades.atrChartTimeframe}
                    />
                </Grid>
            </Grid>
        </>
    )
}
