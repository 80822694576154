import React, {createContext, useContext, ReactNode, useEffect, useRef} from 'react';
import {firebase_functions} from "../../../common/firebaseConfig";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";

interface ShowcaseContextProps {
    showcaseBacktestCount: number;
    showcaseBacktests: any[];
    showcaseBacktestsLoading: boolean;
    showcaseMinDateRangeFilter: number;
    setShowcaseMinDateRangeFilter: (dateRange: number) => void;
    showcaseMaxDateRangeFilter: number;
    setShowcaseMaxDateRangeFilter: (dateRange: number) => void;
    showcaseSymbolTypeFilter: string;
    setShowcaseSymbolTypeFilter: (symbolType: string) => void;
    showcaseSymbolSearchFilter: string;
    setShowcaseSymbolSearchFilter: (symbolSearch: string) => void;
    showcaseSavedOnlyFilter: boolean;
    setShowcaseSavedOnlyFilter: (savedOnly: boolean) => void;
    showcaseSearchByPatternName: string;
    setShowcaseSearchByPatternName: (patternName: string) => void;
    showcaseTradeLimitFilter: number;
    setShowcaseTradeLimitFilter: (tradeLimit: number) => void;
    take: number;
    setTake: (take: number) => void;
    skip: number;
    setSkip: (skip: number) => void;
    sort: { field: string, direction: 'desc' | 'asc' };
    setSort: (sort: { field: string, direction: 'desc' | 'asc' }) => void;
}

// Create the contexts
const ShowcaseContext = createContext<ShowcaseContextProps | undefined>(undefined);

interface Props {
    children: ReactNode;
}

export const ShowcaseProvider: React.FC<Props> = ({ children }) => {

    const {
        tradingHouse,
    } = useApplicationSuiteContext();

    const [showcaseBacktestCount, setShowcaseBacktestCount] = React.useState<number>(0);
    const [showcaseBacktests, setShowcaseBacktests] = React.useState<any[]>([]);
    const [showcaseMinDateRangeFilter, setShowcaseMinDateRangeFilter] = React.useState<number>(0);
    const [showcaseMaxDateRangeFilter, setShowcaseMaxDateRangeFilter] = React.useState<number>(0);
    const [showcaseSymbolTypeFilter, setShowcaseSymbolTypeFilter] = React.useState<string>("all");
    const [showcaseSymbolSearchFilter, setShowcaseSymbolSearchFilter] = React.useState<string>("");
    const [showcaseSavedOnlyFilter, setShowcaseSavedOnlyFilter] = React.useState<boolean>(false);
    const [showcaseSearchByPatternName, setShowcaseSearchByPatternName] = React.useState<string>("");
    const [showcaseTradeLimitFilter, setShowcaseTradeLimitFilter] = React.useState<number>(-1);

    const [take, setTake] = React.useState<number>(10);
    const [skip, setSkip] = React.useState<number>(0);
    const [sort, setSort] = React.useState<{ field: string, direction: 'desc' | 'asc' }>({ field: "profitNumber", direction: "desc" });
    const trackingGetShowcaseId = useRef(0);

    const [getShowcase, showcaseBacktestsLoading] = useHttpsCallable(
        firebase_functions,
        'getShowcase'
    );

    useEffect(() => {
        setSkip(0);
    }, [showcaseSavedOnlyFilter, sort]);

    useEffect(() => {
        const currentRequestId = ++trackingGetShowcaseId.current;

        getShowcase({
            maxOpenTrades: showcaseTradeLimitFilter,
            symbolType: showcaseSymbolTypeFilter,
            minDateRange: showcaseMinDateRangeFilter,
            maxDateRange: showcaseMaxDateRangeFilter,
            symbolSearch: showcaseSymbolSearchFilter,
            patternNameSearch: showcaseSearchByPatternName,
            take,
            skip: skip * take,
            sort,
            tradingHouse
        }).then((backtests) => {
            if (currentRequestId === trackingGetShowcaseId.current) {
                if (backtests?.data) {
                    const backtestsData = backtests?.data as any;
                    setShowcaseBacktests(backtestsData.data as any[] || [])
                    setShowcaseBacktestCount(backtestsData.count || 0)
                } else {
                    setShowcaseBacktests([])
                    setShowcaseBacktestCount(0)
                }
            }
        })
        
    }, [
        getShowcase,
        showcaseMaxDateRangeFilter,
        showcaseMinDateRangeFilter,
        showcaseSymbolTypeFilter,
        showcaseSymbolSearchFilter,
        tradingHouse,
        showcaseSearchByPatternName,
        showcaseTradeLimitFilter,
        take,
        skip,
        sort,
    ])

    return (
        <ShowcaseContext.Provider value={{
            showcaseBacktestCount,
            showcaseBacktests,
            showcaseBacktestsLoading,
            showcaseSymbolTypeFilter,
            setShowcaseSymbolTypeFilter,
            showcaseSymbolSearchFilter,
            setShowcaseSymbolSearchFilter,
            showcaseSavedOnlyFilter,
            setShowcaseSavedOnlyFilter,
            showcaseSearchByPatternName,
            setShowcaseSearchByPatternName,
            take,
            setTake,
            skip,
            setSkip,
            sort,
            setSort,
            showcaseMinDateRangeFilter,
            setShowcaseMinDateRangeFilter,
            showcaseMaxDateRangeFilter,
            setShowcaseMaxDateRangeFilter,
            showcaseTradeLimitFilter,
            setShowcaseTradeLimitFilter
        }}>
            {children}
        </ShowcaseContext.Provider>
    );
}

// Custom hook to use this contexts
export const useShowcaseContext = (): ShowcaseContextProps => {
    const context = useContext(ShowcaseContext);
    if (!context) {
        throw new Error('useShowcaseContext must be used within a ShowcaseProvider');
    }
    return context;
}
