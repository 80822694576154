import { Checkbox, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

interface Props {
    label: string;
    checked: boolean;
    onChange: (value: boolean) => void;
}

const IndicatorBooleanParameterCheckbox: React.FC<Props> = ({label, checked, onChange}) => {
    return (
        <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={(e) => onChange(e.target.checked)}
                        name={label}
                        color="primary"
                    />
                }
                label={label}
            />
        </Box>
    );
}

export default IndicatorBooleanParameterCheckbox;
