import {ConditionGroupType, ConditionType} from "../app/builder/contexts/PatternTreeContext";

export function useFlattenConditionGroups(...groupType: ConditionGroupType[]): ConditionType[] {


    function getAllConditionsFromGroup(group: ConditionGroupType): ConditionType[] {
        let allConditions: ConditionType[] = []

        for (const condition of group.conditions) {
            allConditions.push(condition)
        }

        for (const conditionGroup of group.conditionGroups) {
            allConditions = [...allConditions, ...getAllConditionsFromGroup(conditionGroup)]
        }

        return allConditions
    }

    function flattenGroupType(groupType: ConditionGroupType[]): ConditionType[] {
        let allConditions: ConditionType[] = [];

        for (const group of groupType) {
            allConditions = [...allConditions, ...getAllConditionsFromGroup(group)];
        }

        return allConditions;
    }

    return flattenGroupType(groupType);
}
