import Indicator from "./types/Indicator";
import Value from "./Value";
import Close from "./Close";
import High from "./High";
import Low from "./Low";
import conditionTypes from "../conditions";

const Open: Indicator = {
    value: "open",
    label: "Open",
    fields: ["open"],
    RHS: () => [Value, Open, High, Low, Close],
    conditions: conditionTypes,
}

export default Open;
