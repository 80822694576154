import DialogTitle from "@mui/material/DialogTitle";
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import {FC, forwardRef, ReactElement, Ref} from "react";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {styled} from "@mui/material/styles";


const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialog-container': {
        backdropFilter: 'blur(3px)',
        background: 'rgba(255,255,255,0.1)',
    },
}));

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface ConfirmDialogProps {
    open: boolean;
    title: string;
    description: string;
    onClose: () => void;
    onConfirm: () => void;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({ open, title, description, onClose, onConfirm }) => {
    return (
        <StyledDialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant={"contained"}>Cancel</Button>
                <Button onClick={onConfirm} variant={"outlined"} color={"error"}>Delete</Button>
            </DialogActions>
        </StyledDialog>
    );
};

export default ConfirmDialog;
