import Echo from "./EchoIndicator";
import Major from "./MajorIndicator";
import Minor from "./MinorIndicator";
import Pressure from "./PressureIndicator";
import ThinBlue from "./ThinBlueIndicator";
import ThickBlue from "./ThickBlueIndicator";
import ThinOrange from "./ThinOrangeIndicator";
import ThickOrange from "./ThickOrangeIndicator";


const gtfIndicators = [
    Echo,
    Major,
    Minor,
    Pressure,
    ThinBlue,
    ThickBlue,
    ThinOrange,
    ThickOrange,
]

export default gtfIndicators;
