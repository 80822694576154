import BacktestActions from "../components/BacktestActions";
import React, {useContext, useEffect, useState} from "react";
import {useBacktestContext} from "../contexts/BacktestContext";
import {SnackbarHelperContext} from "../../../contexts/SnackbarHelperContext";
import Box from "@mui/material/Box";
import {Autocomplete, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import setNestedValue from "lodash.set";
import Typography from "@mui/material/Typography";
import VisibilitySelect from "../components/actionBars/components/VisibilitySelect";

function BacktestSidebar() {
    const {backtestSettingInputData, setBacktestSettingInputData} = useBacktestContext();
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    const initialBacktestOptions = JSON.parse(localStorage.getItem('savedBacktestOptions') || '[]');
    const [backtestOptions, setBacktestOptions] = useState(initialBacktestOptions);

    useEffect(() => {
        // Event listener for localStorage changes
        const handleStorageChange = (event: any) => {
            if (event.key === 'savedBacktestOptions') {
                setBacktestOptions(event.newValue);
            }
        };

        // Add the event listener
        window.addEventListener('storage', handleStorageChange);

        // Clean up the event listener
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    function handleDeleteBacktestOption(op: any) {
        const savedBacktestOptions = localStorage.getItem('savedBacktestOptions')
        const parsedSavedBacktestOptions = savedBacktestOptions ? JSON.parse(savedBacktestOptions) : [];
        const newSavedBacktestOptions = parsedSavedBacktestOptions.filter((option: any) => option.id !== op.id);
        localStorage.setItem('savedBacktestOptions', JSON.stringify(newSavedBacktestOptions));
        setBacktestOptions(newSavedBacktestOptions);
        setSnackbarSettings({message: 'Backtest settings deleted.', severity: 'success', autoHideDuration: 6000});
    }

    return <>
        <Box display='flex' flexDirection='column' height={'100%'} gap={4} p={3}>

            <Box sx={{ overflowY: "auto"}}>
                <Typography variant={"h6"}>Backtest Settings</Typography>

            </Box>
            <Autocomplete
                freeSolo
                options={backtestOptions}
                value={backtestSettingInputData}
                onChange={(_, val: any) => val && setBacktestSettingInputData(val)}
                renderInput={(params) => (
                    <TextField {...params} onChange={(e) => {
                        setBacktestSettingInputData((prevState: any) => {
                            const newState = { ...prevState };
                            setNestedValue(newState, 'name', e.target.value);
                            return newState;
                        })
                    }} label="Backtest settings name" fullWidth />
                )}
                getOptionLabel={(option) => typeof option === 'string' ? "" : option.name}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <>
                                {option.name}
                                <IconButton
                                    size="small"
                                    onClick={(event) => {
                                        event.stopPropagation();  // Prevent the option from being selected
                                        handleDeleteBacktestOption(option);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            </>

                        </Box>
                    </li>
                )}
            />
            <VisibilitySelect
                variation={'noBackground'}
            />
            <Box display='flex' flexDirection='row' justifyContent={'space-between'} mt='auto' height={48}>
                <BacktestActions />
            </Box>
        </Box>
    </>
}

export default BacktestSidebar;
