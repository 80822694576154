import {ChartDetailInput} from "./ChartDetailInput";
import {MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";
import {Pattern} from "../contexts/PatternContext";
import Box from "@mui/material/Box";

type TriggerChartDetailsInputProps = {
    trigger: Pattern['trigger'],
    handleChange: (key: string, value: any) => void;
    onEdit?: (isEditing: boolean) => void;
}


function TriggerChartDetailsInput(props: TriggerChartDetailsInputProps) {
    const {trigger, handleChange, onEdit} = props;
    const [timeOptions, setTimeOptions] = useState<{ label: string, type: string, value?: number, timeframe?: string }[]>([]);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedValue, setSelectedValue] = useState<string>('');

    const handleBeforePostChange = (event: any) => {
        const selectedOption = timeOptions.find(option => option.label === (event.target.value as string));

        if (selectedOption) {
            handleChange('type', selectedOption.type);

            if (selectedOption.type === "timeBeforePost") {
                handleChange('value', selectedOption.value);
                handleChange('timeframe', selectedOption.timeframe);
            }
        }
        setSelectedValue(event.target.value as string);
    };

    function onEditHandler(isEditing: boolean) {
        setIsEditing(isEditing);
        if (onEdit) {
            onEdit(isEditing);
        }
    }

    useEffect(() => {
        let options = [{label: 'At post', type: "atPost"}, {label: 'Anytime', type: "anytime"}]
        let timeframe = trigger.chartDetails.timeframe;
        let periodicity = trigger.chartDetails.periodicity;

        const seconds = Array(59).fill(null).map((_, i) =>  ({label: `${i + 1} second before post`, type: "timeBeforePost", value: i+1, timeframe: 'second'}));
        const minutes = Array(timeframe === 'hour' ? 59 : periodicity-1).fill(null).map((_, i) =>  ({label: `${i + 1} minute before post`, type: "timeBeforePost", value: i+1, timeframe: 'minute'}));

        options = options.concat(seconds).concat(minutes);

        setTimeOptions(options);


        let currentSelectedValue = trigger.type === "atPost" ? "At post" : trigger.type === "anytime" ? "Anytime" : `${trigger.value} ${trigger.timeframe} before post`
        if (!options.find(option => option.label === currentSelectedValue)) {
            currentSelectedValue = "atPost"
            handleChange('type', 'atPost');
        }
        setSelectedValue(currentSelectedValue);
    }, [trigger, handleChange]);

    return <>
        <ChartDetailInput
            details={trigger.chartDetails}
            handleChange={(key, value) => handleChange(`chartDetails.${key}`, value)}
            flexDirection={"row"}
            onEdit={onEditHandler}
        >
            {
                isEditing
                    ? <Select
                    value={selectedValue}
                    onChange={handleBeforePostChange}
                >
                    {timeOptions.map((option, index) => (
                        <MenuItem key={index} value={option.label}>{option.label}</MenuItem>
                    ))}
                </Select>
                : <Box>{trigger.type === "atPost" ? "at post" : trigger.type === "anytime" ? "anytime" : `${trigger.value} ${trigger.timeframe} before post`}</Box>
            }
        </ChartDetailInput>
    </>
}

export default TriggerChartDetailsInput;
