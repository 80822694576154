import {
    Autocomplete,
    Box,
    FormControl,
    InputLabel, MenuItem, Select,
    TextField, Typography
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {collection, query, orderBy, where, doc, getDoc} from "firebase/firestore";
import { firebase_firestore} from "../../../../../common/firebaseConfig";
import {defaultPattern, Pattern, PatternContext} from "../../../contexts/PatternContext";
import {useCollection, useCollectionData} from "react-firebase-hooks/firestore";
import _cloneDeep from "lodash.clonedeep";
import {usePattern} from "../../../../../hooks/usePattern";
import {PatternTreeContext} from "../../../contexts/PatternTreeContext";
import ConfirmDialog from "./ConfirmDialog";
import * as jsondiffpatch from "jsondiffpatch";
import TriggerChartDetailsInput from "../../TriggerChartDetailsInput";
import {useApplicationSuiteContext} from "../../../../../contexts/ApplicationSuiteProvider";


export default function PatternInput() {

    const {
        setPattern,
        pattern,
        handleChangePattern,
    } = useContext(PatternContext);

    const {
        buyConditionGroups,
        sellConditionGroups,
    } = useContext(PatternTreeContext);

    const {
        usermeta,
        tradingHouse,
    } = useApplicationSuiteContext();

    const [, patternRefPath] = usePattern(pattern.id || undefined);

    const [layouts, setLayouts] = useState<any[]>([])
    const [querySnapshot] = useCollection(query(collection(firebase_firestore, `usermeta/${usermeta.gcid}/layouts`), orderBy("name")));
    const [usermetaPatterns] = useCollectionData(query(collection(firebase_firestore, `usermeta/${usermeta.gcid}/patterns`), where("active", "==", true), where("tradingHouse", "==", tradingHouse))) as any[];
    const [standardPatterns] = useCollectionData(query(collection(firebase_firestore, `tradingHouses/${tradingHouse}/standardPatterns`))) as any[];
    const [isEditingTriggerChart, setIsEditingTriggerChart] = useState(false)

    const [isUnsavedChangesDialogOpen, setIsUnsavedChangesDialogOpen] = useState<Pattern>();

    useEffect(() => {
        if (querySnapshot) {
            setLayouts(querySnapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() })));
        }
    }, [querySnapshot])

    async function unsavedChanges() {
        const patternCopy = _cloneDeep(pattern);
        patternCopy.buyConditionGroups = buyConditionGroups;
        patternCopy.sellConditionGroups = sellConditionGroups;

        if (patternRefPath) {
            const existingPatternDoc = await getDoc(doc(firebase_firestore, patternRefPath));

            if (!existingPatternDoc.exists()) {
                return jsondiffpatch.diff(defaultPattern, patternCopy)
            } else {
                return jsondiffpatch.diff(existingPatternDoc.data(), patternCopy)
            }
        } else {
            return jsondiffpatch.diff(defaultPattern, patternCopy)
        }
    }

    function selectNewPattern(newPattern: any) {
        unsavedChanges().then((unsavedChanges) => {
            if (unsavedChanges) {
                setIsUnsavedChangesDialogOpen(newPattern);
            } else {
                setPattern(newPattern);
            }
        });
    }

    return (
        <>
            <Box display={"flex"} flexDirection={"column"} height="calc(100% - 8px)">
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                    <Autocomplete
                        freeSolo
                        forcePopupIcon={true}
                        options={(standardPatterns || []).sort((a: any, b: any) => a.name.localeCompare(b.name)).concat((usermetaPatterns || []).sort((a: any, b: any) => a.name.localeCompare(b.name)))}
                        value={pattern}
                        onChange={(_, val: any) => val && selectNewPattern(val)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={(e) => setPattern((prevPattern: any) => ({
                                    ...prevPattern,
                                    name: e.target.value,
                                }))}
                                error={!pattern.name || pattern.name.trim() === ""}
                                label="Pattern Name*"
                                fullWidth
                                variant="standard"
                            />
                        )}
                        getOptionLabel={(option) => typeof option === 'string' ? "" : option.name}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={`${option.name}-${option.id}`} style={{width: "100%", color: `${selected ? 'rgb(0, 161, 255)' : ''}`}}>
                                <Box display="flex" alignItems="flex-end" width={"100%"}>
                                    <Box>{option.name} {option.author && <span style={{fontSize: 10, color: "gray"}}>({option.author})</span>}</Box>
                                    <span style={{
                                        flexGrow: 1,
                                        backgroundImage: `radial-gradient(gray 15%, transparent 20%)`, /* This makes actual dots */
                                        backgroundSize: '5px 1px', /* Adjust the size of dots and the gap between them */
                                        height: '1px', /* The height of the line of dots */
                                        margin: '0 10px', /* Optional: to give some space between name/dots and dots/number */
                                    }}/>
                                    <Box sx={{fontSize: "10px"}}> v{option.version}</Box>

                                </Box>
                            </li>
                        )}
                    />

                    {
                        layouts ?
                        <FormControl variant="standard" size="small" fullWidth>
                            <InputLabel id="layout-select-label">Layout</InputLabel>
                            <Select
                                variant={"standard"}
                                labelId="layout-select-label"
                                id="pattern-layout_id"
                                sx={{textAlign: "left"}}
                                value={pattern.layoutId || ''}
                                onChange={(e) => {
                                    let value: string | null = e.target.value
                                    if (value && value.trim() === '') {
                                        value = null
                                    }
                                    setPattern((prevPattern: any) => ({
                                        ...prevPattern,
                                        layoutId: value,
                                    }));
                                }}
                            >
                                <MenuItem value={''}>
                                    None
                                </MenuItem>
                                {layouts.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> : null
                    }

                    <Box display={"flex"} flexDirection={isEditingTriggerChart ? "column" : "row"} alignItems={isEditingTriggerChart ? "" : "center"} gap={2} mt={2}>
                        <Typography variant={"body1"} alignSelf={isEditingTriggerChart ? "flex-start" : ""}>Trigger Chart:</Typography>
                        <TriggerChartDetailsInput trigger={pattern.trigger}
                                                  handleChange={(key: string, value: any) => handleChangePattern(`trigger.${key}`, value)}
                                                  onEdit={(isEditing => setIsEditingTriggerChart(isEditing))}
                        />
                    </Box>

                    {/*<Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={2} mx={"auto"}>*/}
                    {/*    <Typography variant={"body1"} alignSelf={""}>Trigger Chart:</Typography>*/}

                    {/*    <TriggerChartDetailsInput trigger={pattern.trigger}*/}
                    {/*                              handleChange={(key: string, value: any) => handleChangePattern(`trigger.${key}`, value)}*/}
                    {/*    />*/}
                    {/*</Box>*/}
                </Box>

            </Box>
            <ConfirmDialog
                open={!!isUnsavedChangesDialogOpen}
                title="Unsaved Changes Detected"
                description="It looks like you've made changes to your pattern that haven't been saved yet. If you proceed without saving, these changes will be lost. What would you like to do?"
                confirmButtonText="Discard Changes"
                cancelButtonText="Keep Editing"
                onClose={() => {
                    setPattern((prevPattern: any) => ({
                        ...prevPattern,
                        ...pattern
                    }))
                    setIsUnsavedChangesDialogOpen(undefined)
                }}
                onConfirm={() => {
                    if (isUnsavedChangesDialogOpen) {
                        setPattern(isUnsavedChangesDialogOpen);
                    }
                    setIsUnsavedChangesDialogOpen(undefined);
                }}
            />
        </>
    );
}
