import {FormControl, Grid, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import React from "react";
import ExitStrategiesProps from "./ExitStrategiesProps";
import Typography from "@mui/material/Typography";
import AtrInputs from "./AtrInputs";

export default function PeakValleyExitStrategyInput(props: ExitStrategiesProps) {
    const {backtestSettingInputData, handleInputChange} = props;

    return (
        <>
            <Grid container item spacing={2}>
                <Grid item sm={4} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-amount">Fractal left/right</InputLabel>
                        <OutlinedInput
                            value={backtestSettingInputData.generateTrades.fractalCount}
                            name="generateTrades.fractalCount"
                            onChange={(e) => handleInputChange(e.target.name, parseFloat(e.target.value))}
                            type={"number"}
                            inputProps={{min: 1}}
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">#</InputAdornment>}
                            label="Fractal left/right"
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-amount">Max candles lookback</InputLabel>
                        <OutlinedInput
                            value={backtestSettingInputData.generateTrades.maxPeakValleyLookback}
                            name="generateTrades.maxPeakValleyLookback"
                            onChange={(e) => handleInputChange(e.target.name, parseFloat(e.target.value))}
                            type={"number"}
                            inputProps={{min: 1}}
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">#</InputAdornment>}
                            label="Max candles lookback"
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Price offset</InputLabel>
                        <OutlinedInput
                            value={backtestSettingInputData.generateTrades.priceOffset}
                            name="generateTrades.priceOffset"
                            onChange={(e) => handleInputChange(e.target.name, parseFloat(e.target.value))}
                            type={"number"}
                            inputProps={{step: .00001, min: 0}}
                            label="Price offset"
                        />
                    </FormControl>
                </Grid>
            </Grid>


            <Grid item xs={12}>
                <Typography variant={"body2"}>* Fallback when no fractal found:</Typography>
            </Grid>

            <Grid item xs={12}>
                <AtrInputs
                    onATRPeriodChange={(val) => handleInputChange("generateTrades.atrPeriod", val)}
                    onChartPeriodChange={(val) => handleInputChange("generateTrades.atrChartPeriod", val)}
                    onChartTimeframeChange={(val) => handleInputChange("generateTrades.atrChartTimeframe", val)}
                    atrPeriod={backtestSettingInputData.generateTrades.atrPeriod}
                    chartPeriod={backtestSettingInputData.generateTrades.atrChartPeriod}
                    chartTimeframe={backtestSettingInputData.generateTrades.atrChartTimeframe}
                />
            </Grid>
        </>
    )
}
