import Indicator from "../types/Indicator";
import Value from "../Value";
import conditionTypes from "../../conditions";

const CommodityChannelIndex: Indicator = {
    value: "commodityChannelIndex",
    label: "Commodity Channel Index",
    nickname: "CCI",
    fields: ["commodityChannelIndex"],
    params: {
        period: 20,
    },
    RHS: () => [CommodityChannelIndex, Value],
    conditions: conditionTypes,
};


export default CommodityChannelIndex;
