import {Card, CardActions, CardContent, CardHeader, Typography} from "@mui/material";
import NewRootGroupButton from "../components/creationButtons/conditionGroups/NewRootGroupButton";

function PatternBuilderInstructionsCard() {
    return (
        <Card variant="outlined" sx={{height: '100%', display: "flex", flexDirection: "column", backgroundColor: (theme) => theme.palette.background.custom4}}>
            <CardHeader
                title={
                    <Typography variant="h4" className={"ad-card-header"}>
                        Welcome to the Pattern Builder!
                    </Typography>
                }
            />

            <CardContent sx={{flexGrow: 1}}>
                <Typography variant="body1" color="textSecondary" paragraph>
                    Pattern Builder is your personal tool for creating and refining trade patterns, tailored to your strategies. Craft patterns that help identify potential buy or sell opportunities in the market.
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                    To quick start, select a standard pattern from the "Pattern Name" dropdown in the Current Pattern panel. After renaming, save a fresh copy with “SAVE AS”.
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                    Dive into the build area to tweak indicators and conditions. Start with the BUY tab. Once done, you can easily copy conditions over to the SELL tab and make necessary adjustments.
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                    Always remember to “SAVE” after updates. When you're ready to take your pattern live, just hit “PUBLISH” to access the latest version on your Alerts.
                </Typography>
            </CardContent>
            <CardContent>
                <Typography variant="body1" paragraph>
                    We're excited for what you'll create! Happy building!
                </Typography>
            </CardContent>
            <CardActions>
                <NewRootGroupButton/>
            </CardActions>
            <Typography variant="body2" color="textSecondary" paragraph mx={5}>
                For tutorials, check out our Learning Center or reach out to <a style={{textDecoration: "none", color: 'rgb(0, 161, 255)'}} href="mailto:help@greenchart.com">help@greenchart.com</a> for support.
            </Typography>
        </Card>

    );
}

export default PatternBuilderInstructionsCard;
