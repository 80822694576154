import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import {useEffect, useState} from "react";
import "./TableComponent.css"
import CSVDownloadButton from "./CSVDownloadButton";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
interface Data {
    [key: string]: any;
}

interface ColumnData {
    dataKey: keyof Data;
    label: string;
    numeric?: boolean;
    width: number;
}


const columns: ColumnData[] = [
    "Ratio (risk/reward)",
    "Initial Balance",
    "Gross Profit (total of all profit trades)",
    "Gross Loss (total of all loss trades)",
    "Net Profit (%)",
    "Total Trades (opened and closed = 1 trade)",
    "AB Maximal Drawdown ($)",
    "AB Relative Drawdown (%)",
    "Equity Drawdown (%)",
    "Profit Factor - Gross Profit divided by absolute value of Gross Loss",
    "Short Trades (win %)",
    "Long Trades (win %)",
    "Profit Trades (% of total)",
    "Loss Trades (% of total)",
    "Average Profit Trade",
    "Average Loss Trade",
    "Maximum Consecutive wins ($)",
    "Maximum Consecutive losses ($)",
    "Average Consecutive win amount",
    "Average Consecutive loss amount",
].map((header, index) => ({
    label: header,
    dataKey: header,
    width: 180,
}))


const VirtuosoTableComponents: TableComponents<Data> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} />
    )),
};

function FixedHeaderContent() {

    const theme = useTheme()

    return (
        <TableRow sx={{
            background: theme.palette.background.custom3
        }}>
            <TableCell style={{width: 60}}>
            </TableCell>
            {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align={column.numeric || false ? 'right' : 'left'}
                    style={{ width: column.width }}
                >
                    {column.label}
                </TableCell>
            ))}
        </TableRow>
    );
}



export default function ReactVirtualizedTable(props: {backtest: any}) {

    const {backtest} = props

    const theme = useTheme();

    const [rows, setRows] = useState<any>([])
    const [scrollState, setScrollState] = useState({ canScrollLeft: false, canScrollRight: true });


    const handleScroll = (event: any) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        setScrollState({
            canScrollLeft: scrollLeft > 0,
            canScrollRight: scrollLeft < scrollWidth - clientWidth,
        });
    };


    function RowContent(_index: number, row: Data) {

        if (!row['Net Profit (%)']) {
            return 0;
        }

        const netProfitStr = row['Net Profit (%)'].replace(/\$/, '').match(/-?[\d,]+(\.\d+)?/)[0].replace(/,/g, '');
        if (!netProfitStr) {
            return 0;
        }

        let backgroundColor = theme.palette.background.custom4;

        if (parseFloat(netProfitStr) > 0) {
            backgroundColor = 'rgb(46, 125, 50)'
        }

        return (
            <React.Fragment>
                <TableCell
                    sx={{background: backgroundColor, textAlign: "center"}}
                >
                    <CSVDownloadButton
                        backtest={backtest}
                        group={row["Ratio (risk/reward)"]}
                    />
                </TableCell>
                {columns.map((column) => (
                    <TableCell
                        sx={{background: backgroundColor}}
                        key={column.dataKey}
                        align={column.numeric || false ? 'right' : 'left'}
                    >
                        {row[column.dataKey]}
                    </TableCell>
                ))}
            </React.Fragment>
        );
    }

    useEffect(() => {

        if (backtest.summary) {
            backtest.summary.sort((a: any, b: any) => {
                if (!a['Net Profit (%)']) {
                    return -1;
                } else if (!b['Net Profit (%)']) {
                    return 1;
                }


                const netProfitStr = a['Net Profit (%)'].split(' ')[1].replace('%', '').replace(/,/g, "").replace(/\)/, "").replace(/\(/, "")
                if (!netProfitStr) {
                    return -1;
                }

                const netProfitStr2 = b['Net Profit (%)'].split(' ')[1].replace('%', '').replace(/,/g, "").replace(/\)/, "").replace(/\(/, "")
                if (!netProfitStr2) {
                    return 1;
                }

                return parseFloat(netProfitStr) - parseFloat(netProfitStr2) > 0 ? -1 : 1;

            })
            setRows(backtest.summary)
            return
        }

    }, [backtest])

    return (
        <Paper style={{ height:"300px" , width: '100%', position: "relative" }}>
            <Box className={`table-fade ${!scrollState.canScrollLeft ? 'no-left-fade' : ''} ${!scrollState.canScrollRight ? 'no-right-fade' : ''}`}
                sx={{
                    background: theme.palette.background.custom3,
                    "&:before": {
                        left: 0,
                        background: `linear-gradient(to right, ${theme.palette.background.custom3}, rgba(255, 255, 255, 0))`,
                    },
                    "&:after": {
                        right: 0,
                        background: `linear-gradient(to left, ${theme.palette.background.custom3}, rgba(255, 255, 255, 0))`,
                    },
                    "& .MuiTableContainer-root": {
                        background: theme.palette.background.custom3
                    },
                    "& .MuiTable-root": {
                        borderSpacing: "0 2px !important"
                    },
                    "& .MuiTableCell-root": {
                        padding: "4px 1px",
                    }
                }}
            >
                <TableVirtuoso
                    style={{position: "absolute", top: 0, left: 0}}
                    data={rows}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={FixedHeaderContent}
                    itemContent={RowContent}
                    onScroll={handleScroll}
                />
            </Box>
        </Paper>
    );
}
