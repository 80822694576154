import {
    Box
} from "@mui/material";
import { useTheme} from "@mui/material/styles";
import React from "react";
import Application from "../../../layout/Application";
import {ShowcaseProvider} from "../contexts/ShowcaseContext";
import ShowcaseSidebar from "./ShowcaseSidebar";
import ShowcaseTable from "../components/ShowcaseTable";

function ShowcaseApp() {
    const theme = useTheme();

    return (
        <>
            <Box display={"flex"} sx={{ height: '100vh' }} flexDirection={{ xs: "column", md: "row" }} >
                <Box sx={{ backgroundColor: theme.palette.background.custom1 }} width={"100%"} display={"flex"} height={"100%"}>
                    <ShowcaseTable />
                </Box>
            </Box>
        </>
    );
}

const App = ({tab}: any) => <Application AppComponent={ShowcaseApp} SidebarComponent={ShowcaseSidebar} ContextProviders={[ShowcaseProvider]} tab={tab}/>
export default App;
