import Application from "../../../layout/Application";
import BacktestList from "../components/backtestList/BacktestList";
import {BacktestReportProvider} from "../contexts/BacktestReportContext";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import BacktestReportSidebar from "./BacktestReportSidebar";
import ReportsHeaderBar from "../components/actionBars/ReportsHeaderBar";

function BacktestReportApp() {

    const theme = useTheme();

    return <>
        <Box sx={{background: theme.palette.background.custom1, minHeight: "100vh"}}>
            <ReportsHeaderBar />
            <BacktestList/>
        </Box>
    </>
}

const app = ({tab}: any) => <Application AppComponent={BacktestReportApp} SidebarComponent={BacktestReportSidebar} ContextProviders={[BacktestReportProvider]} tab={tab}/>;
export default app;
