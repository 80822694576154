import {Paper, Tooltip} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import CreationButtonContainer from "../CreationButtonContainer";
import AddIcon from "@mui/icons-material/Add";
import React, {useContext} from "react";
import {PatternTreeContext} from "../../../contexts/PatternTreeContext";

function NewConditionButton({groupId}: { groupId: string }) {
    const {
        handleAddCondition
    } = useContext(PatternTreeContext);

    const theme = useTheme()

    return <Paper
        onClick={() => handleAddCondition(groupId)}
        sx={{ borderRadius: "8px", alignSelf: "center", margin: "0 8px", flexGrow: 1, background: theme.palette.background.custom4}}
        elevation={2}
    >
        <Tooltip title={"Add condition"} arrow>
            <CreationButtonContainer sx={{height: "100%", width: 50}}>
                <AddIcon />
            </CreationButtonContainer>
        </Tooltip>
    </Paper>
}

export default NewConditionButton;
