import {Input} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

function IndicatorNumberParameterInput({label, value, step, onChange}: any) {
    return <>
        <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <label htmlFor={label} style={{flexGrow: 1}}>
                {label}
            </label>
            <Input
                id={label}
                value={value}
                type="number"
                inputProps={{
                    step: step || 1,
                    min: step || 1,
                }}
                onChange={(e) => onChange(parseFloat(e.target.value))}
                placeholder="value"
                className="condition-selector-input"
                sx={{ minWidth: 200 }}
            />
        </Box>
    </>
}

export default IndicatorNumberParameterInput;
