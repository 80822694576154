import {Card, CardContent, CardHeader, List, ListItem, ListItemText, Typography, ListItemIcon} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import HistoryIcon from "@mui/icons-material/History";
import ShareIcon from "@mui/icons-material/Share";

function PatternBuilderUsesCard() {
    return (
        <Card variant="outlined" sx={{backgroundColor: (theme) => theme.palette.background.custom4}}>
            <CardHeader
                title={
                    <Typography variant="h4" className={"ad-card-header"}>
                        Utilize Your Patterns
                    </Typography>
                }
            />

            <CardContent>
                <Typography variant="body1" paragraph>
                    Your personalized patterns are not just an expression of your trading strategy, they're powerful tools to help you navigate the market dynamics. Here's what you can achieve:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <NotificationsIcon className={"ad-primary-text-color"}/>
                        </ListItemIcon>
                        <ListItemText
                            className={"ad-primary-text-color"}
                            primary="Real-time Alerts"
                            secondary="Build alerts based on your patterns. Get instant text messages, emails, and in app alerts, keeping you informed about potential trade opportunities. This ensures you never miss a moment to execute a favorable trade."
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <HistoryIcon className={"ad-primary-text-color"}/>
                        </ListItemIcon>
                        <ListItemText
                            className={"ad-primary-text-color"}
                            primary="Backtest Your Pattern"
                            secondary="Evaluate the performance of your patterns with historical data."
                        />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <ShareIcon className={"ad-primary-text-color"}/>
                        </ListItemIcon>
                        <ListItemText
                            className={"ad-primary-text-color"}
                            primary="Pattern Sharing"
                            secondary="Collaborate and share your unique patterns with other traders."
                        />
                    </ListItem>
                </List>
            </CardContent>
        </Card>

    );
}

export default PatternBuilderUsesCard;
