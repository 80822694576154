import React, {createContext, useCallback, useContext, useEffect} from "react";
import {ConditionGroupType, PatternTreeContext} from "./PatternTreeContext";
import _set from "lodash.set";
import ReactGA from "react-ga4";
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import Indicator from "../condition/indicators/types/Indicator";
import Open from "../condition/indicators/Open";
import High from "../condition/indicators/High";
import Low from "../condition/indicators/Low";
import Close from "../condition/indicators/Close";
import Value from "../condition/indicators/Value";
import gtfIndicators from '../condition/indicators/thirdparty/gtf';
import standardIndicators from '../condition/indicators/standard';

type AtPostTrigger = {
    chartDetails: {
        symbol: string,
        periodicity: number,
        timeframe: string,
    },
    type: 'atPost',
};

type AnytimeTrigger = {
    chartDetails: {
        symbol: string,
        periodicity: number,
        timeframe: string,
    },
    type: 'anytime',
};

type TimeBeforePostTrigger = {
    chartDetails: {
        symbol: string,
        periodicity: number,
        timeframe: string,
    },
    type: 'timeBeforePost',
    value: number,
    timeframe: "minute" | "hour",
};

type Trigger = AtPostTrigger | AnytimeTrigger | TimeBeforePostTrigger;

export type Pattern = {
    id?: string,
    name: string,
    buyConditionGroups: ConditionGroupType[],
    sellConditionGroups: ConditionGroupType[],
    active?: boolean,
    layoutId?: string,
    link?: string,
    author?: string,
    message?: string,
    version?: number,
    tradingHouse?: string,
    alertVersion?: number,
    versions?: {[versionNum: number]: { notes: string }}[],
    trigger: Trigger,
};

export const defaultPattern: Pattern = {
    name: "New Pattern",
    message: "",
    layoutId: "",
    version: 1,
    alertVersion: 1,
    buyConditionGroups: [],
    sellConditionGroups: [],
    trigger: {
        type: "atPost",
        chartDetails: {
            symbol: "EURUSD",
            periodicity: 1,
            timeframe: "minute"
        }
    },
    tradingHouse: "greenchart",
}


const defaultStartingIndicators = [
    Open, High, Low, Close, Value,
];

export function PatternProvider({children}: any) {

    const {
        setSellConditionGroups,
        setBuyConditionGroups
    } = useContext(PatternTreeContext)

    const {
        pattern,
        setPattern,
        tradingHouse,
    } = useApplicationSuiteContext();

    const [startingIndicators, setStartingIndicators] = React.useState<Indicator[]>([]);
    const [saveAsStandardPattern, setSaveAsStandardPattern] = React.useState<boolean>(false);

    // const initializePattern = useCallback((newPattern: Pattern) => {
    //
    //     setPattern(newPattern)
    // }, [setSellConditionGroups, setBuyConditionGroups]);

    useEffect(() => {
        setSellConditionGroups([]);
        setBuyConditionGroups([]);
    }, [tradingHouse, setSellConditionGroups, setBuyConditionGroups]);

    useEffect(() => {
        setSellConditionGroups(pattern.sellConditionGroups)
        setBuyConditionGroups(pattern.buyConditionGroups)
    }, [pattern.sellConditionGroups, pattern.buyConditionGroups, setBuyConditionGroups, setSellConditionGroups])


    useEffect(() => {
        if (tradingHouse === 'gtf') {
            setStartingIndicators(gtfIndicators.concat(defaultStartingIndicators));
        } else if (tradingHouse === 'greenchart') {
            setStartingIndicators(standardIndicators.concat(defaultStartingIndicators));
        }
    }, [tradingHouse])

    const getIndicatorByValue = useCallback((value: string | null | undefined): Indicator | undefined  => {
        return startingIndicators.find(indicator => indicator.value === value)
    }, [startingIndicators])

    function handleChangePattern(key: string, value: any) {

        ReactGA.event('pattern_change', {
            key: key,
            value: value ? value.toString() : '',
        });

        setPattern((prevPattern) => {
            const updatedPattern = {...prevPattern};
            _set(updatedPattern, key, value);
            return updatedPattern;
        });

    }

    return (
        <PatternContext.Provider
            value={{
                pattern,
                setPattern,
                handleChangePattern,
                getIndicatorByValue,
                startingIndicators,
                saveAsStandardPattern,
                setSaveAsStandardPattern,
            }}>
            {children}
        </PatternContext.Provider>
    )
}


interface patternModifers {
    pattern: Pattern,
    setPattern:  React.Dispatch<React.SetStateAction<Pattern>>
    handleChangePattern: (key: string, value: any) => void,
    getIndicatorByValue: (value: string | null | undefined) => Indicator | undefined,
    startingIndicators: Indicator[],
    saveAsStandardPattern: boolean,
    setSaveAsStandardPattern: React.Dispatch<React.SetStateAction<boolean>>,
}

export const PatternContext = createContext<patternModifers>({} as patternModifers);
