import ConditionType from "./ConditionType";

const MovingUpCondition: ConditionType = {
    value: "moving up",
    label: "Moving Up",
    hasRHS: false,
    hasValue: true,
};

export default MovingUpCondition;
