import React, {useState} from "react";
import {GreenChartTooltip} from "../../../../common/components/GreenChartTooltip";
import {Box} from "@mui/material";
import OkayDialog from "../sidebar/patternInput/OkayDialog";
import SSButton from "../../../../common/components/actionButton/SSButton";
import SaveIcon from "@mui/icons-material/Save";
import {collection, getDocs, query} from "firebase/firestore";
import {firebase_firestore} from "../../../../common/firebaseConfig";
import {useApplicationSuiteContext} from "../../../../contexts/ApplicationSuiteProvider";
import useSavePattern from "../../../../hooks/useSavePattern";

const SavePatternButton = (props: {disabled: boolean}) => {
    const {disabled} = props;

    const [isPatternLimitReachedDialogOpen, setIsPatternLimitReachedDialogOpen] = useState<boolean>(false);

    const {
        usermeta,
    } = useApplicationSuiteContext()

    const { savePattern } = useSavePattern();

    return (
        <GreenChartTooltip
            title={disabled ? "All conditions must be complete" : "Save pattern"} arrow
            key={"saveButton"}>
            <Box>
                <OkayDialog
                    open={isPatternLimitReachedDialogOpen}
                    title="100 Pattern Limit Reached"
                    description="You have reached the maximum number of patterns. Please delete some patterns before creating a new one."
                    onClose={() => setIsPatternLimitReachedDialogOpen(false)}
                    onOkay={() => setIsPatternLimitReachedDialogOpen(false)}
                />
                <SSButton
                    type={'success'}
                    disabled={disabled}
                    onClick={async () => {
                        if (disabled) {
                            return;
                        }
                        const patterns = await getDocs(query(collection(firebase_firestore, `usermeta/${usermeta.gcid}/patterns`)));
                        const activePatterns = patterns.docs.filter(doc => !doc.data().deleted);
                        if (activePatterns.length < 100) {
                            savePattern();
                        } else {
                            setIsPatternLimitReachedDialogOpen(true);
                        }
                    }}
                >
                    <SaveIcon />
                </SSButton>
            </Box>
        </GreenChartTooltip>
    )
}

export default SavePatternButton;