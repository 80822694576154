import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ReactNode } from "react";

interface ActionButtonProps {
    sx?: any;
    type: 'action' | 'success' | 'danger' | 'cyan' | 'green';
    children?: ReactNode;
    disabled?: boolean;
    onClick?: () => void;
}

const createStyledButton = (color: string) => styled((props: any) => {
    const theme = useTheme();
    return (
        <Box
            onClick={props.onClick}
            {...props}
            sx={{
                width: '50px',
                height: '50px',
                transition: 'all 0.3s ease-in-out',
                cursor: props.disabled ? 'not-allowed' : 'copy',
                //@ts-ignore
                border: `1px solid ${props.disabled ? theme.palette.background.custom4 : theme.palette[color].idleBorder}`,
                borderRadius: 2,
                //@ts-ignore
                color: props.disabled ? theme.palette[color].disabled : theme.palette[color].idle,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: props.disabled ? theme.palette.background.custom3 : 'transparent',
                '&:hover': {
                    //@ts-ignore
                    border: props.disabled ? `1px solid ${theme.palette.background.custom4}` : `1px solid ${theme.palette[color].main}`,
                    //@ts-ignore
                    color: props.disabled ? theme.palette[color].disabled : theme.palette[color].main,
                    //@ts-ignore
                    backgroundColor: props.disabled ? theme.palette.background.custom3 : theme.palette[color].background,
                },
                ...props.sx,
            }}
        />
    );
})``;

const ActionButton = createStyledButton('action');
const SuccessButton = createStyledButton('success');
const DangerButton = createStyledButton('danger');
const CyanButton = createStyledButton('cyan');
const GreenButton = createStyledButton('green');

const buttons = {
    success: SuccessButton,
    action: ActionButton,
    danger: DangerButton,
    cyan: CyanButton,
    green: GreenButton,
}

const SSButton = (props: ActionButtonProps) => {
    const { type, sx, children, disabled, onClick } = props;
    const ButtonComponent = buttons[type];
    return <ButtonComponent sx={sx} disabled={disabled} onClick={onClick}>{children}</ButtonComponent>;
};

export default SSButton;
