import {Box, Drawer, Paper, Tooltip, Typography} from "@mui/material";
import CreationButtonContainer from "../CreationButtonContainer";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import React from "react";
import Divider from "@mui/material/Divider";
import SavedConditionsList from "../../SavedConditionsList";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";

function NewSavedComponentLibraryButton({groupId}: { groupId: string }) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme()

    return <>
        <Paper
            onClick={() => setOpen(true)}
            sx={{ borderRadius: "8px", alignSelf: "center", margin: "0 8px", flexGrow: 1, background: theme.palette.background.custom4}}
            elevation={2}
        >
            <Tooltip title={"Saved conditions"} arrow>
                <CreationButtonContainer sx={{height: "100%", width: 50}}>
                    <LibraryAddIcon />
                </CreationButtonContainer>
            </Tooltip>
        </Paper>
        <Drawer
            anchor={'right'}
            open={open}
            onClose={() => setOpen(false)}

        >
            <Box
                sx={{padding: 2, maxWidth: '75vw'}}
            >
                <Box display={"flex"} alignItems={"center"}>
                    <Typography variant="h6" flexGrow={1}>Your Saved Conditions and Groups</Typography>
                    <Tooltip title={"Close"} arrow>
                        <IconButton onClick={() => setOpen(false)} >
                            <Close/>
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider textAlign="left" sx={{margin: '20px 0'}}>Choose a condition or a condition group to add to your pattern.</Divider>
                <SavedConditionsList groupId={groupId}/>
            </Box>

        </Drawer>
    </>
}

export default NewSavedComponentLibraryButton;
