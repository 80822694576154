import {Autocomplete, Box, TextField} from "@mui/material";
import React, {useContext, useState} from "react";
import {useApplicationSuiteContext} from "../../../../../contexts/ApplicationSuiteProvider";
import {PatternTreeContext} from "../../../contexts/PatternTreeContext";
import {defaultPattern, Pattern, PatternContext} from "../../../contexts/PatternContext";
import {usePattern} from "../../../../../hooks/usePattern";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection, doc, getDoc, query, where} from "firebase/firestore";
import {firebase_firestore} from "../../../../../common/firebaseConfig";
import {useTheme} from "@mui/material/styles";
import _cloneDeep from "lodash.clonedeep";
import * as jsondiffpatch from "jsondiffpatch";
import ConfirmDialog from "../../sidebar/patternInput/ConfirmDialog";

const PatternOption = ({ props, option, selected }: { props: any, option: any, selected: boolean }) => {
    return (
        <li {...props} key={`${option.name}-${option.id}`} style={{width: "100%", color: `${selected ? 'rgb(0, 161, 255)' : ''}`}}>
            <Box display="flex" alignItems="flex-end" width={"100%"}>
                <Box>{option.name} {option.author && <span style={{fontSize: 10, color: "gray"}}>({option.author})</span>}</Box>
                <span style={{
                    flexGrow: 1,
                    backgroundImage: `radial-gradient(gray 15%, transparent 20%)`, /* This makes actual dots */
                    backgroundSize: '5px 1px', /* Adjust the size of dots and the gap between them */
                    height: '1px', /* The height of the line of dots */
                    margin: '0 10px', /* Optional: to give some space between name/dots and dots/number */
                }}/>
                <Box sx={{fontSize: "10px"}}> v{option.version}</Box>

            </Box>
        </li>
    )
}

const PatternNameInput = () => {
    const {
        usermeta,
        tradingHouse,
    } = useApplicationSuiteContext();

    const {
        buyConditionGroups,
        sellConditionGroups,
    } = useContext(PatternTreeContext);

    const {
        setPattern,
        pattern,
    } = useContext(PatternContext);

    const [, patternRefPath] = usePattern(pattern.id || undefined);

    const [standardPatterns] = useCollectionData(query(collection(firebase_firestore, `tradingHouses/${tradingHouse}/standardPatterns`))) as any[];
    const [usermetaPatterns] = useCollectionData(query(collection(firebase_firestore, `usermeta/${usermeta.gcid}/patterns`), where("active", "==", true), where("tradingHouse", "==", tradingHouse))) as any[];

    const [isUnsavedChangesDialogOpen, setIsUnsavedChangesDialogOpen] = useState<Pattern>();
    const theme = useTheme();

    async function unsavedChanges() {
        const patternCopy = _cloneDeep(pattern);
        patternCopy.buyConditionGroups = buyConditionGroups;
        patternCopy.sellConditionGroups = sellConditionGroups;

        if (patternRefPath) {
            const existingPatternDoc = await getDoc(doc(firebase_firestore, patternRefPath));

            if (!existingPatternDoc.exists()) {
                return jsondiffpatch.diff(defaultPattern, patternCopy)
            } else {
                return jsondiffpatch.diff(existingPatternDoc.data(), patternCopy)
            }
        } else {
            return jsondiffpatch.diff(defaultPattern, patternCopy)
        }
    }

    function selectNewPattern(newPattern: any) {
        unsavedChanges().then((unsavedChanges) => {
            if (unsavedChanges) {
                setIsUnsavedChangesDialogOpen(newPattern);
            } else {
                setPattern(newPattern);
            }
        });
    }

    return (
        <Box
            sx={{
                maxWidth: '300px',
                width: 'calc(100vw - 1146px)',
                minWidth: '100px',
                '@media (min-width: 1446px)': {
                    width: '300px',
                },
            }}
        >
            <ConfirmDialog
                open={!!isUnsavedChangesDialogOpen}
                title="Unsaved Changes Detected"
                description="It looks like you've made changes to your pattern that haven't been saved yet. If you proceed without saving, these changes will be lost. What would you like to do?"
                confirmButtonText="Discard Changes"
                cancelButtonText="Keep Editing"
                onClose={() => {
                    setPattern((prevPattern: any) => ({
                        ...prevPattern,
                        ...pattern
                    }))
                    setIsUnsavedChangesDialogOpen(undefined)
                }}
                onConfirm={() => {
                    if (isUnsavedChangesDialogOpen) {
                        setPattern(isUnsavedChangesDialogOpen);
                    }
                    setIsUnsavedChangesDialogOpen(undefined);
                }}
            />

            <Autocomplete
                freeSolo
                forcePopupIcon={true}
                options={(standardPatterns || []).sort((a: any, b: any) => a.name.localeCompare(b.name)).concat((usermetaPatterns || []).sort((a: any, b: any) => a.name.localeCompare(b.name)))}
                value={pattern}
                onChange={(_, val: any) => val && selectNewPattern(val)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        sx={{background: theme.palette.background.custom4}}
                        onChange={(e) => setPattern((prevPattern: any) => ({
                            ...prevPattern,
                            name: e.target.value,
                        }))}
                        error={!pattern.name || pattern.name.trim() === ""}
                        label="Pattern Name*"
                        fullWidth
                    />
                )}
                getOptionLabel={(option) => typeof option === 'string' ? "" : option.name}
                renderOption={(props, option, { selected }) => (
                    <PatternOption
                        props={props}
                        option={option}
                        selected={selected}
                    />
                )}
            />
        </Box>
    )
}

export default PatternNameInput;