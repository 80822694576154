import React, {useContext, useEffect, useState} from "react";
import {ConditionGroupType, ConditionType, PatternTreeContext} from "../contexts/PatternTreeContext";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import {Delete} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {Tooltip, Typography} from "@mui/material";
import ConditionGroup from "../conditionGroup/ConditionGroup";
import Condition from "../condition/Condition";
import Divider from "@mui/material/Divider";
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
function SavedConditionsList({groupId}: any) {

    const {
        tradingHouse,
    } = useApplicationSuiteContext();

    const {
        handleAddNestedGroup,
        addConditionGroup,
        handleAddCondition
    } = useContext(PatternTreeContext);

    const [savedConditions, setSavedConditions] = useState<(ConditionType | ConditionGroupType)[]>([])
    // const [listType, setListType] = useState<"conditions" | "groups" | "both">("conditions")


    useEffect(() => {
        const savedConditions = localStorage.getItem(`savedConditions-${tradingHouse}`);
        const legacySavedConditions = localStorage.getItem(`savedConditions`);

        if (legacySavedConditions) {
            localStorage.setItem(`savedConditions-${tradingHouse}`, legacySavedConditions);
            localStorage.removeItem(`savedConditions`);
            setSavedConditions(JSON.parse(legacySavedConditions));
        }

        savedConditions && setSavedConditions(JSON.parse(savedConditions))
    }, [tradingHouse])

    function handleAdd(condition: ConditionType | ConditionGroupType) {
        if (!groupId && condition.type === "group") {
            addConditionGroup(condition)
        } else if (condition.type === "group") {
            handleAddNestedGroup(groupId, condition)
        } else if (condition.type === "condition") {
            handleAddCondition(groupId, condition)
        }
    }

    function deleteCondition(conditionId: string) {
        const newConditions = savedConditions.filter((condition: (ConditionType | ConditionGroupType)) => condition.id !== conditionId)
        setSavedConditions(newConditions)
        localStorage.setItem(`savedConditions-${tradingHouse}`, JSON.stringify(newConditions))
    }

    return  <>
        {
            (savedConditions && savedConditions.length) ?
                <>
                    {
                        savedConditions.filter(condition => groupId || condition.type === 'group').map((condition: ConditionType | ConditionGroupType, index: number) =>
                            <React.Fragment key={condition.id}>
                                <SavedListItem
                                    onClick={() => handleAdd(condition)}
                                    onDelete={() => deleteCondition(condition.id)}
                                >
                                    <Box
                                        pt={3} px={1}
                                        sx={{flexGrow: 1}}
                                    >
                                        {
                                            condition.type === "condition"
                                                ? <Condition condition={condition} viewOnly={true}/>
                                                : <ConditionGroup conditionGroup={condition} canEdit={false} conditionsVisible={false}/>
                                        }
                                    </Box>
                                </SavedListItem>
                                {index !== savedConditions.length-1 && <Divider sx={{marginTop: '24px'}}/>}
                            </React.Fragment>
                        )
                    }
                </>
                : <>
                    <Typography variant={"body1"} pt={2}>You don't have any saved conditions yet.</Typography>
                <Typography variant={"body1"}>Start building and saving conditions to reuse them later.</Typography>
            </>
        }
    </>

}

function SavedListItem(props: any) {

    return <>
        <Box display={"flex"} alignItems={"center"}>
            <Tooltip title={"Add to pattern"} arrow>
                <IconButton  onClick={() => props.onClick()} onMouseEnter={(e) => e.currentTarget.style.color = "rgb(0, 161, 255)"} onMouseLeave={(e) => e.currentTarget.style.color = ""}>
                    <AddIcon fontSize={"medium"}/>
                </IconButton>
            </Tooltip>
            {props.children}
            <Tooltip title={"Delete from saved list"} arrow>
                <IconButton onClick={() => props.onDelete()} onMouseEnter={(e) => e.currentTarget.style.color = "rgb(201,80,80)"} onMouseLeave={(e) => e.currentTarget.style.color = ""}>
                    <Delete fontSize={"small"}/>
                </IconButton>
            </Tooltip>
        </Box>
    </>

}

export default SavedConditionsList;
