import React from "react";
import { styled } from "@mui/material/styles";
import {MenuItem, Select, FormControl, InputLabel} from "@mui/material";
import { useApplicationSuiteContext } from "../contexts/ApplicationSuiteProvider";

const TradingHouseSelector = () => {
    const { tradingHouse, setTradingHouse } = useApplicationSuiteContext();

    const StyledFormControl = styled(FormControl)(({ theme }) => ({
        width: '120px',
        cursor: 'pointer',
        '.MuiOutlinedInput-root': {
            backgroundColor: theme.palette.background.custom4,
            '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.23)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
            }
        },
        '.MuiSelect-select': {
            '&:focus': {
                backgroundColor: theme.palette.background.custom4,
            },
            paddingRight: '36px',
        },
        '.MuiPaper-root': {
            backgroundColor: theme.palette.background.custom4,
        }
    }));

    const handleChange = (e: { target: { value: any; }; }) => {
        const newValue = e.target.value;
        localStorage.setItem("tradingHouse", newValue);
        setTradingHouse(newValue);
    };

    return (
        <StyledFormControl variant="outlined">
            <InputLabel id="symbol">Trading House</InputLabel>
            <Select
                labelId="trading-house-label"
                id="trading-house-select"
                value={tradingHouse}
                onChange={handleChange}
                label={"Trading House"}
            >
                <MenuItem value="greenchart">GreenChart</MenuItem>
                <MenuItem value="gtf">GTF</MenuItem>
            </Select>
        </StyledFormControl>
    );
};

export default TradingHouseSelector;
