import {FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Switch} from "@mui/material";
import React from "react";
import ExitStrategiesProps from "./ExitStrategiesProps";
import Typography from "@mui/material/Typography";
import AtrInputs from "./AtrInputs";


export default function DynamicTakeProfitInput(props: ExitStrategiesProps) {
    const {backtestSettingInputData, handleInputChange} = props;
    return (
        <>

            <Grid container item lg={12} spacing={2}>

                <Grid container item lg={3} xs={12}>
                    <Grid item display={"flex"} xs={12} alignItems={"center"}>
                        <Typography variant={"body2"}>Allow TP decrease</Typography>
                        <FormControl>
                            <Switch
                                checked={!!backtestSettingInputData.analyzeTrades.dynamicTakeProfit.allowDecrease}
                                onChange={(e) => handleInputChange("analyzeTrades.dynamicTakeProfit.allowDecrease", e.target.checked)}
                                color="primary"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item display={"flex"} xs={12} alignItems={"center"}>
                        <Typography variant={"body2"}>Allow TP increase</Typography>
                        <FormControl>
                            <Switch
                                checked={!!backtestSettingInputData.analyzeTrades.dynamicTakeProfit.allowIncrease}
                                onChange={(e) => handleInputChange("analyzeTrades.dynamicTakeProfit.allowIncrease", e.target.checked)}
                                color="primary"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item lg={9} xs={12} alignItems={"center"} spacing={2}>

                    <Grid item lg={4} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="type-label">Type</InputLabel>
                            <Select
                                labelId="type-label"
                                value={backtestSettingInputData.analyzeTrades.dynamicTakeProfit.type}
                                name="analyzeTrades.dynamicTakeProfit.type"
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                label="Type"
                            >
                                <MenuItem value="atr">ATR</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>ATR recalculation period</InputLabel>
                            <OutlinedInput
                                value={backtestSettingInputData.analyzeTrades.dynamicTakeProfit.atrRecalculationPeriod}
                                name="analyzeTrades.dynamicTakeProfit.atrRecalculationPeriod"
                                onChange={(e) => handleInputChange(e.target.name, parseFloat(e.target.value))}
                                type={"number"}
                                inputProps={{min: 0}}
                                label="ATR recalculation period"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="timeframe-label">ATR recalculation timeframe</InputLabel>
                            <Select
                                labelId="timeframe-label"
                                value={backtestSettingInputData.analyzeTrades.dynamicTakeProfit.atrRecalculationTimeframe}
                                name="analyzeTrades.dynamicTakeProfit.atrRecalculationTimeframe"
                                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                label="ATR recalculation timeframe"
                            >
                                <MenuItem value="minute">Minute</MenuItem>
                                <MenuItem value="hour">Hour</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>


                <Grid container item xs={12}>
                    <Grid item xs={12} lg={3}/>
                    <Grid container item xs={12} lg={9}>
                        <AtrInputs
                            onATRPeriodChange={(val) => handleInputChange("analyzeTrades.dynamicTakeProfit.atrPeriod", val)}
                            onChartPeriodChange={(val) => handleInputChange("analyzeTrades.dynamicTakeProfit.atrChartPeriod", val)}
                            onChartTimeframeChange={(val) => handleInputChange("analyzeTrades.dynamicTakeProfit.atrChartTimeframe", val)}
                            atrPeriod={backtestSettingInputData.analyzeTrades.dynamicTakeProfit.atrPeriod}
                            chartPeriod={backtestSettingInputData.analyzeTrades.dynamicTakeProfit.atrChartPeriod}
                            chartTimeframe={backtestSettingInputData.analyzeTrades.dynamicTakeProfit.atrChartTimeframe}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
