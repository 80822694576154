import {Box} from "@mui/material";
import NewSavedComponentLibraryButton from "../components/creationButtons/condition/NewSavedComponentLibraryButton";
import NewConditionButton from "../components/creationButtons/condition/NewConditionButton";

const PhantomCondition = ({groupId}: any) => {
    return (
        <>
            <Box sx={{display: "flex", flexDirection: 'column', gap: 1, height: 'auto', minHeight: 190}}>
                <NewConditionButton groupId={groupId}/>
                <NewSavedComponentLibraryButton groupId={groupId}/>
            </Box>
        </>
    );
};


export default PhantomCondition;
