import React, {RefObject, useEffect} from 'react';

const useKeyboardInteraction = (isOpen: boolean, searchInputRef: RefObject<HTMLInputElement>, onKeyInteraction: (event: React.KeyboardEvent) => void) => {
    useEffect(() => {
        const focusSearchInput = (event: KeyboardEvent) => {
            const isAlphanumeric = /^[a-z0-9]$/i.test(event.key);
            if (isOpen && isAlphanumeric && document.activeElement !== searchInputRef.current) {
                searchInputRef.current?.focus();
            }
        };

        document.addEventListener('keydown', focusSearchInput);
        return () => document.removeEventListener('keydown', focusSearchInput);
    }, [isOpen, searchInputRef, onKeyInteraction]);
};

export default useKeyboardInteraction;