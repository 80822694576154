import React from "react";
import ActionBar from "../../../common/components/actionBar/ActionBar";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {Box} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import NavigationSelect from "../../../components/NavigationSelect";
import TradingHouseSelector from "../../../components/TradingHouseSelector";
import RemainingBalance from "../../../components/RemainingBalance";
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";

interface ShowcaseHeaderBarProps {
    setFilterOpen: (open: boolean) => void;
    filterOpen: boolean;
}

const ShowcaseHeaderBar: React.FC<ShowcaseHeaderBarProps> = ({ setFilterOpen, filterOpen }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { canToggleTradingHouse } = useApplicationSuiteContext();

    const menuButton = () => {
        return (
            <Box sx={{ color: 'white', alignContent: 'center', display: 'flex' }}>
                {/*//@ts-ignore*/}
                <IconButton onClick={setFilterOpen}>
                    <FilterAltIcon />
                </IconButton>
            </Box>
        );
    };

    return (
        <ActionBar
            left={
                <Box display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'}>
                    <NavigationSelect />
                    {canToggleTradingHouse && <TradingHouseSelector/>}
                </Box>
            }
            right={
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <RemainingBalance />
                {isMobile && menuButton()}
            </Box>
            }
            position={'top'}
        />
    );
};

export default ShowcaseHeaderBar;
