import React from 'react';
import {Box} from "@mui/material";
import NewRootGroupButton from "../components/creationButtons/conditionGroups/NewRootGroupButton";
import NewSavedGroupButton from "../components/creationButtons/conditionGroups/NewSavedGroupButton";

const PhantomConditionGroup = () => {
    return (
        <>
            <Box sx={{display: "flex", flexDirection: 'row', gap: 1, marginBottom: 2, }}>
                <NewRootGroupButton/>
                <NewSavedGroupButton/>
            </Box>
        </>

    )
};

export default PhantomConditionGroup;
