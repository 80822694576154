import {Paper, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, {useContext} from "react";
import {PatternTreeContext} from "../../../contexts/PatternTreeContext";
import CreationButtonContainer from "../CreationButtonContainer";
import {useTheme} from "@mui/material/styles";

function NewRootGroupButton() {

    const {
        addConditionGroup,
    } = useContext(PatternTreeContext);

    const theme = useTheme()

    return <Paper
        onClick={() => addConditionGroup()}
        sx={{ borderRadius: 2, alignSelf: "center", margin: 1, flexGrow: 1, background: theme.palette.background.custom3}}
        elevation={2}
    >
        <Tooltip title="Add a group to hold conditions" arrow>
            <CreationButtonContainer sx={{width: "100%", height: 50, fontSize: 22}}>
                New Group <AddIcon/>
            </CreationButtonContainer>
        </Tooltip>
    </Paper>
}

export default NewRootGroupButton;
