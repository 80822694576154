import Indicator from "./types/Indicator";
import Value from "./Value";
import Close from "./Close";
import Open from "./Open";
import High from "./High";
import conditionTypes from "../conditions";

const Low: Indicator = {
    value: "low",
    label: "Low",
    fields: ["low"],
    RHS: () => [Value, Open, High, Low, Close],
    conditions: conditionTypes,
}

export default Low;
