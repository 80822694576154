import CrossingUpCondition from "./CrossingUpCondition";
import CrossingDownCondition from "./CrossingDownCondition";
import CrossingCondition from "./CrossingCondition";
import EqualsCondition from "./EqualsCondition";
import GreaterThanCondition from "./GreaterThanCondition";
import LessThanCondition from "./LessThanCondition";
import MovingDownCondition from "./MovingDownCondition";
import MovingUpCondition from "./MovingUpCondition";
import LessThanOrEqualsCondition from "./LessThanOrEqualsCondition";
import GreaterThanOrEqualsCondition from "./GreaterThanOrEqualsCondition";
import ConvergingCondition from "./ConvergingCondition";
import DivergingCondition from "./DivergingCondition";


const ConditionTypes = [
    CrossingCondition,
    CrossingUpCondition,
    CrossingDownCondition,
    LessThanOrEqualsCondition,
    GreaterThanOrEqualsCondition,
    EqualsCondition,
    GreaterThanCondition,
    LessThanCondition,
    MovingUpCondition,
    MovingDownCondition,
    ConvergingCondition,
    DivergingCondition,
]

export default ConditionTypes;
