import React, {useState} from "react";
import useDeletePattern from "../../../../hooks/useDeletePattern";
import {GreenChartTooltip} from "../../../../common/components/GreenChartTooltip";
import {Box} from "@mui/material";
import ConfirmDialog from "../sidebar/patternInput/ConfirmDialog";
import SSButton from "../../../../common/components/actionButton/SSButton";
import DeleteIcon from "@mui/icons-material/Delete";

const DeletePatternButton = (props: {disabled: boolean}) => {
    const {disabled} = props;
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

    const { deletePattern } = useDeletePattern();

    return (
        <GreenChartTooltip
            title={disabled ? "All conditions must be complete" : "Delete pattern"} arrow
            key={"deleteButton"}>
            <Box>
                <ConfirmDialog
                    open={isDeleteDialogOpen}
                    title="Confirm Pattern Deletion"
                    description="Are you sure you want to permanently delete this pattern? Once deleted, it cannot be recovered."
                    confirmButtonText="Delete Pattern"
                    cancelButtonText="Cancel"
                    onClose={() => setIsDeleteDialogOpen(false)}
                    onConfirm={() => {
                        deletePattern()
                        setIsDeleteDialogOpen(false);
                    }}
                />
                <SSButton
                    type={'danger'}
                    disabled={disabled}
                    onClick={() => {
                        if (disabled) {
                            return;
                        }
                        setIsDeleteDialogOpen(true)
                    }}
                >
                    <DeleteIcon />
                </SSButton>
            </Box>
        </GreenChartTooltip>
    )
}

export default DeletePatternButton;