import Indicator from "../types/Indicator";
import {overlayIndicators} from "./index";
import Open from "../Open";
import High from "../High";
import Low from "../Low";
import Close from "../Close";
import Value from "../Value";
import conditionTypes from "../../conditions";

const ParabolicSAR: Indicator = {
    value: "parabolicSAR",
    label: "Parabolic SAR",
    nickname: "Parabolic SAR",
    fields: ["parabolicSAR"],
    params: {
        step: 0.02,
        max: 0.20,
    },
    RHS: () => [...overlayIndicators, Open, High, Low, Close, Value],
    conditions: conditionTypes,
}


export default ParabolicSAR;
