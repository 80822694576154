import React, {useState} from "react";
import {styled, useTheme} from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import {GreenChartTooltip} from "../../../../../common/components/GreenChartTooltip";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import LockIcon from "@mui/icons-material/Lock";
import MenuItem from "@mui/material/MenuItem";
import {useBacktestContext} from "../../../contexts/BacktestContext";
import {useApplicationSuiteContext} from "../../../../../contexts/ApplicationSuiteProvider";

const VisibilitySelect: React.FC<{ variation?: 'default' | 'noBackground' }> = ({ variation = 'default' }) => {
    const theme = useTheme();
    const { setBacktestSettingInputData, backtestSettingInputData } = useBacktestContext();

    const {
        canPrivatePatterns
    } = useApplicationSuiteContext();

    const isLocked = !canPrivatePatterns;

    const [visibility, setVisibility] = useState<string>(backtestSettingInputData?.isPublic ? 'public' : 'private');
    const handleChange = (event: SelectChangeEvent<string>) => {
        setBacktestSettingInputData({
            ...backtestSettingInputData,
            isPublic: event.target.value === 'public'
        });
        setVisibility(event.target.value);
    };

    // Define the background styles based on the variation prop
    const backgroundColorStyle = variation === 'noBackground' ? 'transparent' : theme.palette.background.custom4;

    const StyledFormControl = styled(FormControl)(({ theme }) => ({
        width: '100%',
        maxWidth: '300px',
        cursor: 'pointer',
        '.MuiOutlinedInput-root': {
            backgroundColor: backgroundColorStyle,
            '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.23)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
            }
        },
        '.MuiSelect-select': {
            '&:focus': {
                backgroundColor: backgroundColorStyle,
            },
            paddingRight: '36px',
        },
        '.MuiPaper-root': {
            backgroundColor: backgroundColorStyle,
        }
    }));

    if (isLocked) {
        return (
            <GreenChartTooltip title={<Typography variant={"body2"}>Upgrade to Pro Plus to run private backtests</Typography>} arrow>
                <a href="https://myaccount.greenchart.com/dashboard/marketplace?display=membership"
                   target="_blank"
                   style={{ textDecoration: 'none', display: 'block', width: '25%' }} rel="noreferrer">
                    <StyledFormControl
                        variant="outlined"
                        sx={{
                            color: 'grey.500',
                            cursor: 'pointer',
                            '& .MuiOutlinedInput-root': {
                                pointerEvents: 'none'
                            }
                        }}
                    >
                        <InputLabel id="visibility-label">Visibility</InputLabel>
                        <Select
                            labelId="visibility-label"
                            id="visibility-select"
                            value={visibility}
                            label="Visibility"
                            disabled
                            IconComponent={() => <LockIcon style={{ color: theme.palette.grey[500], marginRight: '16px' }} />}
                        >
                            <MenuItem value={visibility}>{visibility.charAt(0).toUpperCase() + visibility.slice(1)}</MenuItem>
                        </Select>
                    </StyledFormControl>
                </a>
            </GreenChartTooltip>
        );
    }

    return (
        <StyledFormControl variant="outlined">
            <InputLabel id="visibility-label">Visibility</InputLabel>
            <Select
                labelId="visibility-label"
                id="visibility-select"
                value={visibility}
                onChange={handleChange}
                label="Visibility"
            >
                <MenuItem value="private">Private</MenuItem>
                <MenuItem value="public">Public</MenuItem>
            </Select>
        </StyledFormControl>
    );
};

export default VisibilitySelect;
