import {Box, Button, Drawer} from "@mui/material";
import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';

type ExitStregyDrawerProps = {
    isOpen: boolean,
    onClose: () => void
}

export default function ExitStrategyDrawer(props: ExitStregyDrawerProps) {
    const {isOpen, onClose} = props

    const [isVisible, setIsVisible] = React.useState(isOpen)

    useEffect(() => {
        setIsVisible(isOpen)
    }, [isOpen])

    return <>
        <Drawer
            anchor="right"
            open={isVisible}
            onClose={onClose}
        >
            <Box sx={{width: 500}}>
                <Box display={"flex"} flexDirection={"column"} p={3} gap={1}>
                    <Button onClick={onClose} sx={{position: "absolute", top: 16, right: 16, padding: 0, height: 40, width: 40}}>
                        <CloseIcon/>
                    </Button>
                    <h2>Learn more about exit strategies</h2>
                    <Box>
                        <h3 style={{color: "#00A1ff", marginBottom: 8}}>ATR - Average True Range</h3>
                        <Typography variant={"body1"}>
                            Average True Range takes the value of the high price minus the low price of each previous candle in a range and averages them. The candle range is set under <b>ATR Setting</b>. Set the Take Profit or Stop Loss value under Use ATR for and the alternate value is automatically set by the <b>Risk/Reward</b> settings.
                        </Typography>
                    </Box>
                    <Box>
                        <h3 style={{color: "#00A1ff", marginBottom: 8}} >Incremental</h3>
                        <Typography variant={"body1"}>
                            Choose a starting number of points for your Stop Loss (<b>Incremental Start</b>), choose the number of points you want to end with (<b>Incremental Stop</b>) and the step amount (<b>Incremental Step</b>) you want to take for each increment.
                        </Typography>
                    </Box>
                    <Box>
                        <h3 style={{color: "#00A1ff", marginBottom: 8}} >Fixed</h3>
                        <Typography variant={"body1"}>
                            Set the number of points you wish to use for your <b>Stop Loss</b> (risk) and <b>Take Profit</b> (reward).
                            For Forex pairs like EUR/USD, input with full decimals (e.g., 0.00002), and for indices like U30USD, use a simpler format (e.g., 20).
                            Click the <b>+</b> sign to add more fixed Risk/Reward ratios.
                        </Typography>
                    </Box>
                    <Box>
                        <h3 style={{color: "#00A1ff", marginBottom: 8}} >Fractal</h3>
                        <Typography variant={"body1"}>
                            Is a peak/valley candle pattern that is identified by a high/low candle with <u>at least</u> two lower/higher candles to the left and right side. You can set <b>Candles left/right</b>, and also the number of candles to look back (<b>Max candles lookback</b>) to find a fractal (high/low candle). Use the <b>Price offset</b> to set the number of points above/below the fractal for your S/L or T/P. Use the <b>Fallback when no peak/valley found</b> settings to revert to using the ATR if no fractal is found nearby.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Drawer>
    </>
}
