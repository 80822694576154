import React, {ReactNode, ReactElement} from "react";
import Box from "@mui/material/Box";
import {useApplicationSuiteContext} from "../contexts/ApplicationSuiteProvider";

type ApplicationProps = {
    AppComponent: React.ComponentType<any>;
    SidebarComponent?: React.ComponentType<any>;
    ContextProviders?: React.ComponentType<{ children: ReactNode }>[];
    tab?: number;
};

function RecursiveWrapper({
                              components,
                              children,
                          }: {
    components: React.ComponentType<{ children: ReactNode }>[];
    children: ReactNode;
}): ReactElement {
    if (components.length === 0) return <>{children}</>;

    const [CurrentProvider, ...remainingProviders] = components;

    return (
        <CurrentProvider>
            <RecursiveWrapper components={remainingProviders}>
                {children}
            </RecursiveWrapper>
        </CurrentProvider>
    );
}

function Application({
                         AppComponent,
                         ContextProviders = [],
                         tab,
                     }: ApplicationProps) {

    const {activeTab} = useApplicationSuiteContext()

    return (
        <RecursiveWrapper components={ContextProviders}>
            {
                activeTab === tab && <Box display="flex">
                <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
                    <AppComponent />
                </Box>
              </Box>
            }

        </RecursiveWrapper>
    );
}

export default Application;
