import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useBacktestContext } from "../contexts/BacktestContext";
import Box from "@mui/material/Box";
import { usePattern } from "../../../hooks/usePattern";
import { useApplicationSuiteContext } from "../../../contexts/ApplicationSuiteProvider";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import { styled, useTheme } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import { SnackbarHelperContext } from "../../../contexts/SnackbarHelperContext";
import Typography from "@mui/material/Typography";
import { GreenChartTooltip } from "../../../common/components/GreenChartTooltip";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SSButton from "../../../common/components/actionButton/SSButton";

const MS_IN_MINUTE = 60 * 1000;
const MS_IN_HOUR = MS_IN_MINUTE * 60;
const MS_IN_DAY = MS_IN_HOUR * 24;
const MS_IN_WEEK = MS_IN_DAY * 7;
const MS_IN_MONTH = MS_IN_DAY * 30;

export default function BacktestActions() {
    const { usermeta } = useApplicationSuiteContext();
    const { setSnackbarSettings } = useContext(SnackbarHelperContext);
    const theme = useTheme();

    const { backtestSettingInputData, setBacktestSettingInputData, backtestInputIsValid, runBacktest } = useBacktestContext();

    const [pattern] = usePattern(backtestSettingInputData.patternId, backtestSettingInputData.patternVersion, backtestSettingInputData.gcid);
    const [isInsufficientFundsDialogVisible, setIsInsufficientFundsDialogVisible] = React.useState(false);

    const candleIterations = calculateIntervals();

    const costPerCredit = 0.0000006;
    const priceFactor = 10;
    const creditsToUse = parseFloat(((candleIterations || 0) * costPerCredit * priceFactor).toFixed(2))

    const insufficientFunds = usermeta.products && usermeta.products?.backtest ? (usermeta.products?.backtest?.accruedCost + creditsToUse) >= usermeta.products?.backtest?.maxCost : false;

    useEffect(() => {
        setBacktestSettingInputData((prev: any) => ({
            ...prev,
            system: {
                credits: candleIterations
            }
        }));
    }, [setBacktestSettingInputData, candleIterations])

    function saveBacktestSettings() {
        if (backtestInputIsValid) {
            const settings = { ...backtestSettingInputData, id: uuidv4() };
            const savedBacktestOptions = localStorage.getItem('savedBacktestOptions')
            const parsedSavedBacktestOptions = savedBacktestOptions ? JSON.parse(savedBacktestOptions) : [];
            parsedSavedBacktestOptions.push(settings);
            localStorage.setItem('savedBacktestOptions', JSON.stringify(parsedSavedBacktestOptions));
            setSnackbarSettings({ message: 'Backtest settings saved.', severity: 'success', autoHideDuration: 6000 });
        }
    }

    function runBacktestOnValidation() {
        if (backtestInputIsValid) {
            runBacktest(backtestSettingInputData);
        }
    }

    function calculateIntervals() {

        let intervalMs = msToJump();

        if (!intervalMs) return null;

        // Parse dates
        const start = new Date(backtestSettingInputData.dateRange.startDate).getTime();
        const end = new Date(backtestSettingInputData.dateRange.endDate).getTime();

        // Get the difference in milliseconds
        const diff = end - start;

        // Calculate the number of intervals
        const numIntervals = Math.floor(diff / intervalMs);

        return numIntervals;
    }

    function msToJump() {
        if (!pattern) return null;

        switch (pattern.trigger.type) {
            // case 'anytime': return 1000;
            case 'atPost': return convertToMS(pattern.trigger.chartDetails.timeframe, pattern.trigger.chartDetails.periodicity, 1);
            default: return convertToMS(pattern.trigger.chartDetails.timeframe, pattern.trigger.chartDetails.periodicity, 1);
        }
    }

    function priceOfBacktest() {
        if (creditsToUse === 0) {
            return '< 0.00 credits';
        }

        return `${creditsToUse} credits`;
    }

    function convertToMS(timeframe: string, period: number, interval: number) {
        if (timeframe === "minute") {
            return period * interval * MS_IN_MINUTE;
        } else if (timeframe === "hour") {
            return period * interval * MS_IN_HOUR;
        } else if (timeframe === "day") {
            return period * interval * MS_IN_DAY;
        } else if (timeframe === "week") {
            return period * interval * MS_IN_WEEK;
        } else if (timeframe === "month") {
            return period * interval * MS_IN_MONTH;
        } else {
            return period * interval * MS_IN_MINUTE;
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' }, alignItems: 'center', gap: 2, px: 2 }}>
            <Box
                sx={{
                    fontSize: '16px',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ fontWeight: 'bold', color: insufficientFunds ? theme.palette.danger.main : 'white' }}>
                    {insufficientFunds ? 'Insufficient Funds' : 'Cost to run:'}
                </Box>
                <Box>
                    {priceOfBacktest()}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', order: { xs: 0, md: 1 }, gap: 2 }}>
                <GreenChartTooltip title={<Typography variant={"body2"}>Save current settings</Typography>} arrow>
                    <Box>
                        <SSButton
                            type={'action'}
                            disabled={!backtestInputIsValid}
                            onClick={saveBacktestSettings}
                        >
                            <CreateNewFolderIcon />
                        </SSButton>
                    </Box>
                </GreenChartTooltip>

                <GreenChartTooltip title={<Typography variant={"body2"}>Run backtest</Typography>} arrow>
                    <Box>
                        <SSButton
                            type={insufficientFunds ? 'danger' : 'success'}
                            disabled={!backtestInputIsValid}
                            onClick={
                                () => insufficientFunds
                                    ? setIsInsufficientFundsDialogVisible(true)
                                    : runBacktestOnValidation()
                            }
                        >
                            <PlayArrowIcon />
                        </SSButton>
                    </Box>
                </GreenChartTooltip>
            </Box>
            <InsufficientFundsDialog open={isInsufficientFundsDialogVisible} onClose={() => setIsInsufficientFundsDialogVisible(false)} />
        </Box>
    )
}

const StyledLinks = styled("a")(({ theme }) => ({
    '&': {
        color: theme.palette.primary.main,
        textDecoration: "none"
    },
    '&:hover': {
        textDecoration: "underline"
    }
}));

function InsufficientFundsDialog({ open, onClose }: any) {
    const upgradePackageURL = "https://myaccount.greenchart.com/dashboard/marketplace?display=Strategy%20Suite";
    const addonPackageURL = "https://myaccount.greenchart.com/dashboard/marketplace?display=Strategy%20Suite";

    function openMarketplace() {
        window.open('https://myaccount.greenchart.com/dashboard/marketplace?display=Strategy%20Suite', '_blank');
        onClose();
    }

    return <>
        <Dialog
            open={open}
            keepMounted
            onClose={onClose}
            aria-describedby="insufficient-funds-dialog"
        >
            <DialogTitle>Insufficient Backtest Credits</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Oops! Running this backtest will exceed your available backtesting credit. You can <StyledLinks href={upgradePackageURL} target={"_blank"}>upgrade to a higher package</StyledLinks> or <StyledLinks href={addonPackageURL} target={"_blank"}>purchase more credits</StyledLinks>. You can also try reducing the date range or the frequency of the trigger chart to continue backtesting.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant={"outlined"} color={"primary"}>Cancel</Button>
                <Button onClick={openMarketplace} variant={"contained"} color={"primary"}>Go To Marketplace</Button>
            </DialogActions>
        </Dialog>
    </>
}
