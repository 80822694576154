import Indicator from "../../types/Indicator";
import Close from "../../Close";
import Open from "../../Open";
import Value from "../../Value";
import Low from "../../Low";
import High from "../../High";
import conditionTypes from "../../../conditions";

const Pressure: Indicator = {
    value: "pressure",
    label: "Pressure",
    fields: ["redPressure", "greenPressure"],
    RHS: () => [Pressure, Open, High, Low, Close, Value],
    conditions: conditionTypes,
}

export default Pressure;
