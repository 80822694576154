import Indicator from "../../types/Indicator";
import Value from "../../Value";
import conditionTypes from "../../../conditions";

const Minor: Indicator = {
    value: "minor",
    label: "Minor",
    fields: ["grayMinor", "blackMinor"],
    RHS: () => [Minor, Value],
    conditions: conditionTypes,
}

export default Minor
