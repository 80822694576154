import {
    Box, Dialog,
} from "@mui/material";
import BacktestInput from "../components/BacktestInput";
import DialogTitle from "@mui/material/DialogTitle";
import {styled, useTheme} from "@mui/material/styles";
import ConstructionIcon from '@mui/icons-material/Construction'
import React from "react";
import Application from "../../../layout/Application";
import {BacktestProvider} from "../contexts/BacktestContext";
import {useApplicationSuiteContext} from "../../../contexts/ApplicationSuiteProvider";
import BacktestSidebar from "./BacktestSidebar";

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiDialog-container': {
        backdropFilter: 'blur(3px)',
        background: 'rgba(255,255,255,0.5)',
    },
}));

const BACKTESTER_UNDER_MAINTENANCE = false;

function BacktesterApp() {
    const { activeTab } = useApplicationSuiteContext()
    const theme = useTheme();


    return (
        <>
            <StyledDialog open={activeTab === 1 ? BACKTESTER_UNDER_MAINTENANCE : false}>
                <DialogTitle display={"flex"} gap={2} alignItems={"center"}>
                    <ConstructionIcon fontSize={"large"}/>
                    The Backtester is under maintenance.
                    <ConstructionIcon fontSize={"large"}/>
                </DialogTitle>
            </StyledDialog>

            <Box display={"flex"} sx={{minHeight: '100vh'}} flexDirection={{xs: "column", md: "row"}} >
                <Box  sx={{backgroundColor: theme.palette.background.custom1}} width={"100%"} display={"flex"} height={"100%"}>
                    <BacktestInput/>
                </Box>
            </Box>
        </>

    );
}

const App = ({tab}: any) => <Application AppComponent={BacktesterApp} SidebarComponent={BacktestSidebar} ContextProviders={[BacktestProvider]} tab={tab}/>;
export default App;
