import { useState } from "react";

const defaultType = 'All';

const useTypeFilter = (types: string[], filterTypes: string[], initialType = defaultType) => {
    const [selectedType, setSelectedType] = useState(initialType);

    const handleTypeClick = (type: string) => {
        // Check if the clicked type is already selected
        if (selectedType === type) {
            // If so, reset to defaultType
            setSelectedType(defaultType);
        } else {
            // Otherwise, set to the clicked type
            setSelectedType(type);
        }
    };

    const isTypeSelected = (type: string) => selectedType === type;

    const resetTypeFilter = () => setSelectedType(defaultType);

    const isTypeEnabled = (typeToCheck: string) => types.includes(typeToCheck) && filterTypes.includes(typeToCheck);

    return { selectedType, handleTypeClick, isTypeSelected, resetTypeFilter, isTypeEnabled };
};

export default useTypeFilter;
