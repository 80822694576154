import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";

type GC_Modal_Confirmation_Props = {
    modalTitle: string,
    text: string,
    modalBody: string,
    cancelText: string,
    submitText: string,
    shouldShowAgainOption?: boolean,
    onSubmit: (showAgain?: boolean) => void,
    onCancel: () => void,
    setOpen: (isOpen: boolean) => void,
    open: boolean
}

export default function GCModalConfirmation(props: GC_Modal_Confirmation_Props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [showAgain, setShowAgain] = React.useState(false);

    const handleClose = () => {
        props.onCancel && props.onCancel();
        props.setOpen(false);
    };

    const handleSubmit = () => {
        props.onSubmit && props.onSubmit(showAgain);
        props.setOpen(false)
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {props.modalTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.modalBody}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Typography variant="body2" style={{marginRight: "auto"}}>
                        {props.shouldShowAgainOption && <input onChange={(e) => setShowAgain(e.target.checked)} type="checkbox" id="showAgain" name="showAgain" value="showAgain"/>}
                        {props.shouldShowAgainOption && <label htmlFor="showAgain">Don't show this again</label>}
                    </Typography>
                    <Button autoFocus onClick={handleClose}>
                        {props.cancelText}
                    </Button>
                    <Button onClick={handleSubmit} autoFocus>
                        {props.submitText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
