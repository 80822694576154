import DialogTitle from "@mui/material/DialogTitle";
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import {FC, forwardRef, ReactElement, Ref} from "react";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";


const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface OkayDialogProps {
    open: boolean;
    title: string;
    description: string;
    onClose: () => void;
    onOkay: () => void;
    okayButtonText?: string;
}

const OkayDialog: FC<OkayDialogProps> = ({ open, title, description, onClose, onOkay, okayButtonText= 'Okay'}) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onOkay} variant={"outlined"} color={"primary"}>{okayButtonText}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default OkayDialog;
