import React from 'react';
import Box from '@mui/material/Box';
import Application from './Application';
import { useApplicationSuiteContext } from '../contexts/ApplicationSuiteProvider';
import BacktestApp from '../app/backtest/app/BacktesterApp';
import PatternBuilderApp from '../app/builder/app/PatternBuilderApp';
import BacktestReportApp from '../app/reports/app/BacktestReportApp';
import TrainingApp from '../app/training/app/TrainingApp';
import ShowcaseApp from '../app/showcase/app/ShowcaseApp';
import './MainLayout.css';
import WelcomeDialog from "../components/WelcomeDialog";

function MainLayout() {
    const {
        activeTab,
        hasStarter,
    } = useApplicationSuiteContext();

    const tabComponents = {
        0: <PatternBuilderApp tab={0} />,
        1: <BacktestApp tab={1} />,
        2: <BacktestReportApp tab={2} />,
        3: <TrainingApp tab={3} />,
        4: <ShowcaseApp tab={4} />,
    };

    return (
        <>
            <Box className="mainLayout">
                {Object.entries(tabComponents).map(([tabIndex, component]) => (
                    <Box key={tabIndex} className={activeTab === parseInt(tabIndex) ? 'activeTab' : 'inactiveTab'}>
                        {component}
                    </Box>
                ))}
                {/* Fallback for any other tab */}
                {!(activeTab in tabComponents) && (
                    <Box className="activeTab">
                        <Application AppComponent={Box} />
                    </Box>
                )}
            </Box>

            {hasStarter && <WelcomeDialog/>}
        </>
    );
}

export default MainLayout;
