import React, {createContext, useState, useContext, ReactNode, useEffect} from 'react';
import {useAuthState} from "react-firebase-hooks/auth";
import {firebase_auth, firebase_firestore, firebase_functions} from "../common/firebaseConfig";
import {useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import {collection, doc, limit, query, where} from "firebase/firestore";
import _cloneDeep from "lodash.clonedeep";
import {defaultPattern, Pattern} from "../app/builder/contexts/PatternContext";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {ISymbol} from "../common/components/dialogs/symbolSelectDialog/SymbolSelect";
import {useSearchParams} from "react-router-dom";
import {SnackbarHelperContext} from "./SnackbarHelperContext";

type FixedOptions = {
    exitStrategy: 'fixed';
};

type AtrOptions = {
    exitStrategy: 'atr';
    atrPeriod: number,
    atrChartPeriod: number,
    atrChartTimeframe: string,
};

type PeakValleyOptions = {
    exitStrategy: 'peak_valley';
    fractalCount: number,
    maxPeakValleyLookback: number,
    priceOffset: number,
};

type IncrementalOptions = {
    exitStrategy: 'incremental';
    incrementalStart?: number;
    incrementalStop?: number;
    incrementalStep?: number;
};



const defaultATRExitStrategy: AtrOptions = {
    exitStrategy: "atr",
    atrChartPeriod: 5,
    atrChartTimeframe: "minute",
    atrPeriod: 15,
}

const defaultIncrementalExitStrategy: IncrementalOptions = {
    exitStrategy: "incremental",
    incrementalStart: .00050,
    incrementalStop: .00010,
    incrementalStep: .00010,
}

const defaultPeakValleyExitStrategy: PeakValleyOptions = {
    exitStrategy: "peak_valley",
    fractalCount: 2,
    maxPeakValleyLookback: 10,
    priceOffset: .00010,
}

const defaultFixedExitStrategy: FixedOptions = {
    exitStrategy: "fixed",
}

const defaultInputSettings: any = {
    name: "Custom Settings",
    atrPeriod: 5,
    executionSymbol: "EURUSD",
    dateRange: {
        startDate: new Date(new Date().getTime() - (1440 * 60 * 1000 * 30)).setHours(0, 0, 0, 0),
        endDate: new Date().setHours(23, 59, 59, 999),
    },
    startTime: new Date().setHours(5, 0, 0,0),
    endTime: new Date().setHours(9, 0, 0,0),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    generateTrades: {
        ...defaultIncrementalExitStrategy,
        ...defaultPeakValleyExitStrategy,
        ...defaultFixedExitStrategy,
        ...defaultATRExitStrategy,
        exitStrategy: "atr",
        tradeRatios: [],
    },
    analyzeTrades: {
        equity: 10000,
        riskPerTrade: .01,
        commissions: 2,
        tradeLimit: 0,
        dailyTradeLimit: 0,
        allowHedging: false,
        forceExit: {},
        contractSize: 100000,
    },
    isPublic: true,
}

type ApplicationSuiteContextType = {
    backtestSettingInputData: any;
    setBacktestSettingInputData: React.Dispatch<React.SetStateAction<any | null>>;
    activeTab: number;
    setActiveTab: React.Dispatch<React.SetStateAction<number>>;
    usermeta: any;
    runningBacktests: boolean;
    pattern: Pattern;
    setPattern: React.Dispatch<React.SetStateAction<Pattern>>;
    canShowcaseViewBacktestSettings: boolean;
    canViewPrivatePatterns: boolean;
    symbols: ISymbol[] | undefined;
    tradingHouse: string;
    setTradingHouse: React.Dispatch<React.SetStateAction<string>>;
    canToggleTradingHouse: boolean;
    canPrivatePatterns: boolean;
    hasStarterTrialAccess: boolean;
    hasStarter: boolean;
};

const ApplicationSuiteContext = createContext<ApplicationSuiteContextType | undefined>(undefined);

type ApplicationSuiteProviderProps = {
    children: ReactNode;
};

function ApplicationSuiteProvider({ children }: ApplicationSuiteProviderProps) {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [backtestSettingInputData, setBacktestSettingInputData] = useState<any>(defaultInputSettings);
    const [pattern, setPattern] = useState<Pattern>(_cloneDeep(defaultPattern));
    const [symbols, setSymbols] = useState<ISymbol[]>();

    const [user] = useAuthState(firebase_auth);
    const [usermeta] = useDocumentData(doc(firebase_firestore, `usermeta/${user?.uid}`));
    const [canToggleTradingHouse, setCanToggleTradingHouse] = useState<boolean>(false);
    const [runningBacktests] = useCollectionData(
        query(
            collection(firebase_firestore, `usermeta/${usermeta?.gcid}/backtests`),
            where("status", "==", "running"),
            limit(1)
        ));

    const [tradingHouse, setTradingHouse] = useState<string>(pattern?.tradingHouse ? pattern.tradingHouse : "greenchart");

    const [getSymbols] = useHttpsCallable(firebase_functions, 'getSymbols');

    useEffect(() => {
        if (usermeta) {
            setTradingHouse(["member", "free", "trial"].includes(usermeta.tradingHouses?.gtf?.currentStatus?.status) ? "gtf" : "greenchart");

            const canToggleTradingHouse = usermeta.isAdmin || usermeta.tradingHouses?.gtf?.currentStatus?.status === "free";
            setCanToggleTradingHouse(canToggleTradingHouse);

            if (canToggleTradingHouse) {
                const tradingHouse = localStorage.getItem("tradingHouse")
                if (tradingHouse) {
                    setTradingHouse(tradingHouse);
                }
            }
        }
    }, [usermeta]);

    useEffect(() => {
        getSymbols().then((symbols: any) => {
            setSymbols(symbols.data.map((symbol: any) => ({...symbol, name: symbol.displaySymbol})));
        });
    }, [getSymbols]);

    useEffect(() => {
        setPattern(defaultPattern);
        setBacktestSettingInputData((prev: any) => ({
            ...prev,
            patternId: undefined,
        }));
    }, [tradingHouse]);

    const [searchParams] = useSearchParams();
    const [setSharedPatternOnAccount] = useHttpsCallable(firebase_functions, "setSharedPatternOnAccount")
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    function extractPatternId() {
        const pid = searchParams.get('pid');
        searchParams.delete('pid');
        return pid;
    }

    useEffect(() => {
        const pid = extractPatternId();
        if (!pid || !pid.length) return;

        setSnackbarSettings({message: 'Importing pattern onto your account...', severity: 'info', autoHideDuration: 2000});
        void setSharedPatternOnAccount({linkId: pid}).then((res: any) => {
            if (!res?.data?.pattern) {
                setSnackbarSettings({message: res?.data?.message ? res.data.message : 'There was a problem importing the pattern onto your account!', severity: 'error', autoHideDuration: 6000});
                return;
            }

            setPattern(res.data.pattern);
            setSnackbarSettings({message: 'Pattern added to your account!', severity: 'success', autoHideDuration: 6000});
        }).catch((error: any) => {
            console.error("Error:", error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!user || !usermeta) return null;

    // now - 7 days
    const oneWeek = new Date(new Date().getTime() - (1440 * 60 * 1000 * 60 * 24 * 7)).setHours(0, 0, 0, 0);

    const hasAdminAccess = usermeta?.products?.alerts?.name?.toLowerCase().startsWith("admin");
    const hasProPlus = usermeta?.products?.alerts?.name?.toLowerCase().startsWith("pro plus");
    const hasPro = usermeta?.products?.alerts?.name?.toLowerCase().startsWith("pro");
    const hasStarter = usermeta?.products?.alerts?.name?.toLowerCase().startsWith("starter") && !usermeta?.products?.alerts?.name?.toLowerCase().startsWith("starter plus");

    const hasStarterTrialAccess = hasStarter
        && new Date(usermeta?.tradingHouses?.greenchart?.currentStatus?.timestamp?.seconds * 1000).getTime() > oneWeek
    const canShowcaseViewBacktestSettings = usermeta.isAdmin || hasAdminAccess || hasProPlus || hasStarterTrialAccess;
    const canViewPrivatePatterns = usermeta.isAdmin || hasAdminAccess || hasProPlus || hasPro || hasStarterTrialAccess;
    const canPrivatePatterns = usermeta.isAdmin || hasAdminAccess || hasProPlus;

    return (
        <ApplicationSuiteContext.Provider value={{
            canShowcaseViewBacktestSettings,
            canViewPrivatePatterns,
            canPrivatePatterns,
            activeTab,
            setActiveTab,
            usermeta,
            backtestSettingInputData,
            setBacktestSettingInputData,
            runningBacktests: (runningBacktests?.length || 0) > 0,
            pattern,
            setPattern,
            symbols,
            tradingHouse,
            setTradingHouse,
            canToggleTradingHouse,
            hasStarterTrialAccess,
            hasStarter
        }}>
            {children}
        </ApplicationSuiteContext.Provider>
    );
}

function useApplicationSuiteContext(): ApplicationSuiteContextType {
    const context = useContext(ApplicationSuiteContext);
    if (!context) {
        throw new Error('useApplicationSuite must be used within an ApplicationSuiteProvider');
    }
    return context;
}

export { ApplicationSuiteProvider, useApplicationSuiteContext };

