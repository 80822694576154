import BuildArea from "../BuildArea";
import AdArea from "../blankMessageArea/AdArea";
import Application from "../../../layout/Application";
import {PatternTreeProvider} from "../contexts/PatternTreeContext";
import {PatternProvider} from "../contexts/PatternContext";
import PatternBuilderSidebar from "./PatternBuilderSidebar";

function PatternBuilderApp() {
    return <>
        <BuildArea>
            <AdArea/>
        </BuildArea>
    </>
}

const App = ({tab}: any) => <Application AppComponent={PatternBuilderApp} SidebarComponent={PatternBuilderSidebar}  ContextProviders={[ PatternTreeProvider, PatternProvider]} tab={tab}/>;
export default App;
