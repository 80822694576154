import Indicator from "../types/Indicator";
import Value from "../Value";
import conditionTypes from "../../conditions";

const Stochastic: Indicator = {
    value: "stochastic",
    label: "Stochastics (Simple)",
    nickname: "Stochastics",
    fields: ["k", "d"],
    params: {
        period: 14,
        field: "Close",
        smooth: true,
    },
    RHS: () => [Stochastic, Value],
    conditions: conditionTypes,
};


export default Stochastic;
