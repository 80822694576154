import {Box, DialogActions, Typography} from "@mui/material";
import React from "react";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export const callerFooterMap = {
    lotSizeCalculator: undefined,
    patternAlerts: undefined,
    navigationBar: undefined,
    tradingSimulator: (
        <DialogActions style={{ backgroundColor: '#f8f8f8', height: '20px', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <WarningAmberIcon sx={{ marginBottom: '4px', marginRight: '8px' }} />
                <Typography sx={{ fontSize: '14px', fontWeight: 300 }}>
                    Symbols shown are based on current layout
                </Typography>
            </Box>
        </DialogActions>
    ),
}