import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import "../backtestResults/TableComponent.css"
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import BacktestListRow from "./BacktestListRow";

interface Data {
    [key: string]: any;
}

interface ColumnData {
    dataKey: keyof Data;
    label: string;
    numeric?: boolean;
    width?: number;
}


const columns: ColumnData[] = [
    {label: "Status", dataKey: "status", width: 100},
    {label: "Pattern", dataKey: "name"},
    {label: "Date Range", dataKey: "dateRange"},
    {label: "Duration", dataKey: "duration"},
    {label: "Time Ago", dataKey: "timeAgo"},
]


const VirtuosoTableComponents: TableComponents<Data> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} />
    )),
};

export default function BacktestListTable({backtests, loadMore}: any) {

    const theme = useTheme();



    function FixedHeaderContent() {

        return (
            <TableRow sx={{
                display: "flex",
                background: theme.palette.background.custom2
            }}>
                {columns.map((column) => (
                    <TableCell
                        key={column.dataKey}
                        align={column.numeric || false ? 'right' : 'left'}
                        sx={{ width: column.width || `calc((100% - 72px) / ${columns.length})`}}
                    >
                        {column.label}
                    </TableCell>
                ))}
                <TableCell sx={{width: "72px"}}/>
            </TableRow>
        );
    }


    return (
            <Box
                sx={{
                    position: "relative",
                    height: "calc(100vh - 49px)",
                    background: theme.palette.background.custom3,
                }}
            >
                <TableVirtuoso
                    style={{position: "absolute", top: 0, left: 0}}
                    data={backtests}
                    components={VirtuosoTableComponents}
                    endReached={loadMore}
                    fixedHeaderContent={FixedHeaderContent}
                    itemContent={(_index, backtest) => <BacktestListRow backtest={backtest}/>}
                />
            </Box>
    );
}
