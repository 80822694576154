import ActionBar from "../../../../common/components/actionBar/ActionBar";
import {Box, FormControlLabel, Switch} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useApplicationSuiteContext} from "../../../../contexts/ApplicationSuiteProvider";
import {PatternContext} from "../../contexts/PatternContext";
import ShortUniqueId from "short-unique-id";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import {firebase_firestore} from "../../../../common/firebaseConfig";
import {SnackbarHelperContext} from "../../../../contexts/SnackbarHelperContext";
import DeletePatternButton from "../buttons/DeletePatternButton";
import SharePatternButton from "../buttons/SharePatternButton";
import PublishPatternButton from "../buttons/PublishPatternButton";
import SavePatternAsButton from "../buttons/SavePatternAsButton";
import SavePatternButton from "../buttons/SavePatternButton";
import {ConditionType, PatternTreeContext} from "../../contexts/PatternTreeContext";
import {useFlattenConditionGroups} from "../../../../hooks/useFlattenConditionGroups";
import {usePattern} from "../../../../hooks/usePattern";

const PatternBuilderActionBar = () => {
    const {
        pattern,
        saveAsStandardPattern,
        setSaveAsStandardPattern,
    } = useContext(PatternContext);

    const {
        buyConditionGroups,
        sellConditionGroups,
    } = useContext(PatternTreeContext);

    const {setSnackbarSettings} = useContext(SnackbarHelperContext);
    const flattenedConditions = useFlattenConditionGroups(...buyConditionGroups, ...sellConditionGroups);

    const {
        usermeta,
    } = useApplicationSuiteContext()

    const [, patternRefPath] = usePattern(pattern.id || undefined);
    const [incompletePattern, setIncompletePattern] = useState(true)

    useEffect(() => {
        if (
            pattern.name
            && pattern.name.trim() !== ""
            && sellConditionGroups.length
            && buyConditionGroups.length
            && flattenedConditions
            && flattenedConditions.every((condition: ConditionType) => condition.condition && condition.condition.isValid)) {
            setIncompletePattern(false)
        } else {
            setIncompletePattern(true)
        }
    }, [flattenedConditions, buyConditionGroups.length, sellConditionGroups.length, pattern.name]);

    async function createShareLink(): Promise<string> {
        const link = await generatePatternShareLink();

        setShareLink(link);
        copyLinkToClipboard(link);
        return link;
    }

    function copyLinkToClipboard(link: string | null) {
        try {
            if (link) {
                navigator.clipboard.writeText(link);
                setSnackbarSettings({message: 'Pattern share link copied to clipboard.', severity: 'success', autoHideDuration: 6000});
            }
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    }

    const day = () => 60 * 60 * 24;

    async function generatePatternShareLink() {
        const uidGenerator = new ShortUniqueId();
        // @ts-ignore
        const uid = uidGenerator.randomUUID(5)
        const patternId = pattern.id;

        const currentTime = Timestamp.now();
        const TTL = new Timestamp(currentTime.seconds + day(), currentTime.nanoseconds);

        const patternLink = {
            linkId: uid,
            patternId,
            patternRefPath: `usermeta/${usermeta.gcid}/patterns/${patternId}`,
            TTL,
        };

        const docRef = doc(firebase_firestore, `patternLinks/${uid}`)
        await setDoc(doc(firebase_firestore, docRef.path), patternLink)

        return `https://myaccount.greenchart.com/dashboard/strategy-suite?pid=${uid}`;
    }

    useEffect(() => {
        setShareLink(null);
    }, [pattern])

    const [shareLink, setShareLink] = useState<string | null>(null);

    return (<>
        <ActionBar
        position={'bottom'}
        middle={
            <Box>
                {
                    usermeta.isAdmin && <FormControlLabel
                            sx={{margin: "8px 0", justifyContent: "start", color: 'white'}}
                            control={<Switch
                                checked={saveAsStandardPattern}
                                onChange={(e) => setSaveAsStandardPattern(e.target.checked)}
                                color="warning"/>
                            }
                            label="ADMIN: Save as Standard Pattern"
                            labelPlacement="start"
                            id="save-standard-pattern"
                        />
                }
            </Box>
        }
        right={
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            justifyContent: 'flex-end',
            paddingRight: '16px',
            alignContent: 'center',
            flexWrap: 'wrap',
        }}
             height={56}
        >
            <DeletePatternButton
                disabled={!pattern.id || !patternRefPath || !patternRefPath.includes(usermeta.gcid)}
            />
            <SharePatternButton
                shareLink={shareLink}
                createShareLink={createShareLink}
                disabled={!pattern.id || pattern.author === 'Showcase' || incompletePattern}
            />
            <PublishPatternButton
                disabled={!patternRefPath || !patternRefPath.includes(usermeta.gcid) || incompletePattern}
            />
            <SavePatternAsButton
                disabled={!pattern.id || incompletePattern}
            />
            <SavePatternButton
                disabled={incompletePattern}
            />
        </Box>
        }
    />
    </>)
}

export default PatternBuilderActionBar;
