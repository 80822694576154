import React, {useContext} from "react";
import {PatternTreeContext} from "../../../contexts/PatternTreeContext";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

const BuySellToggleSwitch = () => {
    const {
        activeTab,
        setActiveTab
    } = useContext(PatternTreeContext);

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newTab: string | null,
    ) => {
        if (newTab) {
            setActiveTab(newTab);
        }
    };

    return (
        <ToggleButtonGroup
            sx={{height: '56px'}}
            color={'secondary'}
            value={activeTab}
            exclusive
            onChange={handleChange}
            aria-label="condition group buy/sell"
        >
            <ToggleButton sx={{width: '78px'}} value="buy" aria-label="buy conditions" >
                Buy
            </ToggleButton>
            <ToggleButton sx={{width: '78px'}} value="sell" aria-label="sell conditions" >
                Sell
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

export default BuySellToggleSwitch;