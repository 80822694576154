import React from "react";
import Box from '@mui/material/Box';
import {styled, CSSObject, useTheme, Theme} from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";

const drawerWidth = 375;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    background: theme.palette.background.custom2,
    color: theme.palette.common.white,
    overflowX: 'hidden',
});

const StyledBox = styled(Box)(
    ({ theme }) => ({
        width: drawerWidth,
        top: 48,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...openedMixin(theme),
    }),
);

export default function MiniBox({ children }: any) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = React.useState(isLargeScreen && !isSmallScreen);

    React.useEffect(() => {
        if (isSmallScreen) {
            setOpen(true);
        } else if (isLargeScreen) {
            setOpen(true);
        }
    }, [isSmallScreen, isLargeScreen]);

    return (
        <StyledBox>
            {open && <Box sx={{ p: 2, borderRight: !isMobile ? `2.5px solid ${theme.palette.background.custom4}` : undefined }}>{children}</Box>}
        </StyledBox>
    );
}
