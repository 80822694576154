import { useCallback } from 'react';
import filters from '../../filters';
import { ISymbol } from "../../SymbolSelect";
import { CallerType } from "../../SymbolSelectManager";

const useCallerFilterSymbols = () => {
    return useCallback((symbols: ISymbol[], caller: CallerType) => {
        const filterFunction = filters[caller];
        if (typeof filterFunction === 'function') {
            return filterFunction(symbols);
        }
        return symbols;
    }, []);
};

export default useCallerFilterSymbols;
