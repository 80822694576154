import {Box, Button, FormControlLabel, Switch, Tooltip} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {defaultPattern, PatternContext} from "../../../contexts/PatternContext";
import _cloneDeep from "lodash.clonedeep";
import {ConditionType, PatternTreeContext} from "../../../contexts/PatternTreeContext";
import {useFlattenConditionGroups} from "../../../../../hooks/useFlattenConditionGroups";
import {collection, doc, getDoc, getDocs, limit, orderBy, query, setDoc, updateDoc, Timestamp} from "firebase/firestore";
import {firebase_firestore} from "../../../../../common/firebaseConfig";
import * as jsondiffpatch from "jsondiffpatch";
import {usePattern} from "../../../../../hooks/usePattern";
import ConfirmDialog from "../patternInput/ConfirmDialog";
import ReactGA from "react-ga4";
import {useApplicationSuiteContext} from "../../../../../contexts/ApplicationSuiteProvider";
import OkayDialog from "../patternInput/OkayDialog";
import IconButton from "@mui/material/IconButton";
import ShareIcon from '@mui/icons-material/Share';
import PreviewIcon from '@mui/icons-material/Preview';
import {GreenChartTooltip} from "../../../../../common/components/GreenChartTooltip";
import {SnackbarHelperContext} from "../../../../../contexts/SnackbarHelperContext";
import ShortUniqueId from 'short-unique-id';
import ShareDialog from "../patternInput/ShareDialog";

export default function PatternActionButtons(props: any) {
    const {
        setPattern,
        pattern,
    } = useContext(PatternContext);

    const {
        buyConditionGroups,
        sellConditionGroups,
    } = useContext(PatternTreeContext);

    const {
        usermeta,
        tradingHouse,
        canViewPrivatePatterns,
    } = useApplicationSuiteContext();

    const [, patternRefPath] = usePattern(pattern.id || undefined);
    const [saveAsStandardPattern, setSaveAsStandardPattern] = useState(false)
    const [versions, setVersions] = useState<any[]>([])
    const [incompletePattern, setIncompletePattern] = useState(true)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [isPatternLimitReachedDialogOpen, setIsPatternLimitReachedDialogOpen] = useState<boolean>(false);
    const [isSharePatternDialogOpen, setIsSharePatternDialogOpen] = useState<boolean>(false);
    const flattenedConditions = useFlattenConditionGroups(...buyConditionGroups, ...sellConditionGroups);

    const [shareLink, setShareLink] = useState<string | null>(null);
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    const day = () => 60 * 60 * 24;

    async function generatePatternShareLink() {
        const uidGenerator = new ShortUniqueId();
        // @ts-ignore
        const uid = uidGenerator.randomUUID(5)
        const patternId = pattern.id;

        const currentTime = Timestamp.now();
        const TTL = new Timestamp(currentTime.seconds + day(), currentTime.nanoseconds);

        const patternLink = {
            linkId: uid,
            patternId,
            patternRefPath: `usermeta/${usermeta.gcid}/patterns/${patternId}`,
            TTL,
        };

        const docRef = doc(firebase_firestore, `patternLinks/${uid}`)
        await setDoc(doc(firebase_firestore, docRef.path), patternLink)

        return `https://myaccount.greenchart.com/dashboard/strategy-suite?pid=${uid}`;
    }

    const fetchVersions = async (path: string) => {
        const q = query(collection(firebase_firestore, path), orderBy("version"));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => doc.data());
    };

    useEffect(() => {
        const userDocPath = `usermeta/${usermeta.gcid}/patterns/${pattern.id}`;
        const userDocRef = doc(firebase_firestore, userDocPath);

        getDoc(userDocRef).then(docSnapshot => {
            const versionsPath = docSnapshot.exists() ?
                `${userDocPath}/versions` :
                `tradingHouses/${tradingHouse}/standardPatterns/${pattern.id}/versions`;

            return fetchVersions(versionsPath);
        }).then(docs => {
            setVersions(docs);
        });

    }, [usermeta.gcid, pattern.version, pattern.id, tradingHouse]);

    useEffect(() => {
        if (
            pattern.name
            && pattern.name.trim() !== ""
            && sellConditionGroups.length
            && buyConditionGroups.length
            && flattenedConditions
            && flattenedConditions.every((condition: ConditionType) => condition.condition && condition.condition.isValid)) {
            setIncompletePattern(false)
        } else {
            setIncompletePattern(true)
        }
    }, [flattenedConditions, buyConditionGroups.length, sellConditionGroups.length, pattern.name]);

    function deletePattern() {
        ReactGA.event('pattern_delete', {
            author: pattern.author,
            pattern_name: pattern.name
        });
        updateDoc(doc(firebase_firestore, patternRefPath), {deleted: true, active: false});
        setPattern(_cloneDeep(defaultPattern))
    }

    const save = async () => {

        let patternLocation = saveAsStandardPattern ? `tradingHouses/${tradingHouse}/standardPatterns` : `usermeta/${usermeta.gcid}/patterns`

        let docRef = pattern.id
            ? doc(firebase_firestore, `${patternLocation}/${pattern.id}`)
            : doc(collection(firebase_firestore, patternLocation))

        const patternCopy = JSON.parse(JSON.stringify(pattern))


        patternCopy.buyConditionGroups = buyConditionGroups;
        patternCopy.sellConditionGroups = sellConditionGroups;
        undefinedToNull(patternCopy)

        if (patternCopy.id) {
            // pattern could be using the ID generated on the usermeta (or the standard) collection. If so, we need to create a new one to prevent duplicate IDs
            const existingDoc = await getDoc(doc(firebase_firestore, `${patternLocation}/${pattern.id}`))
            if (!existingDoc.exists()) {
                patternCopy.version = 1
                patternCopy.alertVersion = 1
                docRef = doc(collection(firebase_firestore, patternLocation))
            } else {
                // existing pattern does exist, so we need to increment the version number
                const previousVersion = versions && versions.length && versions[versions.length - 1].version ? versions[versions.length - 1].version : 1
                patternCopy.version = previousVersion + 1
            }
        }

        patternCopy.id = docRef.id
        patternCopy.active = true
        if (!saveAsStandardPattern) {
            if (usermeta.email && usermeta.email.includes("@")) {

                const userEmailPrefix = usermeta.email.split("@")[0];

                // every can save a pattern but the author needs to remain showcase if it is showcase if the user canViewPrivatePatterns
                // if the user can't view private patterns and it is a showcase pattern, then author remains showcase
                // if there is no author, then the author is the user

                if (!patternCopy.author) {
                    patternCopy.author = userEmailPrefix
                } else {
                    if (patternCopy.author === "Showcase" && !canViewPrivatePatterns) {
                        patternCopy.author = "Showcase"
                    } else {
                        patternCopy.author = userEmailPrefix
                    }
                }
            }
        } else {
            patternCopy.author = tradingHouse
        }

        patternCopy.tradingHouse = tradingHouse;

        ReactGA.event('pattern_save', {
            author: patternCopy.author,
            pattern_name: patternCopy.name
        });

        await saveDiff(docRef, patternCopy)
        await setDoc(docRef, patternCopy)
        setPattern(patternCopy)
        // navigate(`/${patternCopy.id}`, { replace: true });
    }

    async function saveDiff(docRef: any, patternCopy: any) {
        const existingDoc = await getDoc(doc(firebase_firestore, docRef.path))
        let previousPattern = {}

        if (existingDoc.exists()) {

            // TEMP CODE
            const versionCollectionRef = await getDocs(query(collection(firebase_firestore, `${docRef.path}/versions`), orderBy("version"), limit(1))).then((querySnapshot) => querySnapshot.docs.map((doc) => doc.data()));
            if (!versionCollectionRef.length) {
                await setDoc(doc(firebase_firestore, `${docRef.path}/versions/1`), {
                    version: 1,
                    diff: JSON.stringify(jsondiffpatch.diff({}, existingDoc.data()!)),
                    created: new Date().getTime()
                })
            }
            // TEMP CODE

            previousPattern = existingDoc.data()!
        }

        const diff = jsondiffpatch.diff(previousPattern, patternCopy)

        if (diff) {

            const versionRef = doc(firebase_firestore, `${docRef.path}/versions/${patternCopy.version}`)
            const versionData = {
                version: patternCopy.version,
                diff: JSON.stringify(diff),
                created: new Date().getTime()
            }
            await setDoc(versionRef, versionData)
        }
    }

    function undefinedToNull(obj: any) {
        if (!obj || typeof obj !== 'object') {
            return obj;
        }

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] === undefined) {
                    obj[key] = null;
                } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                    undefinedToNull(obj[key]);
                }
            }
        }

        return obj;
    }

    async function createShareLink(): Promise<string> {
        const link = await generatePatternShareLink();

        setShareLink(link);
        copyLinkToClipboard(link);
        return link;
    }

    function copyLinkToClipboard(link: string | null) {
        try {
            if (link) {
                navigator.clipboard.writeText(link);
                setSnackbarSettings({message: 'Pattern share link copied to clipboard.', severity: 'success', autoHideDuration: 6000});
            }
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    }

    useEffect(() => {
        setShareLink(null);
    }, [pattern])

    const actionButtons = [

            <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
                <GreenChartTooltip title={incompletePattern ? "All conditions must be complete" : "Save as a new version"} arrow key={"createNewVersion"}>
                    {/*<Box></Box>*/}
                    <Button
                        variant={'contained'}
                        sx={{ background: 'rgb(0, 161, 255)' }}
                        onClick={async () => {
                            const patterns = await getDocs(query(collection(firebase_firestore, `usermeta/${usermeta.gcid}/patterns`)));
                            const activePatterns = patterns.docs.filter(doc => !doc.data().deleted);
                            if (activePatterns.length < 100) {
                                save();
                            } else {
                                setIsPatternLimitReachedDialogOpen(true);
                            }
                        }}
                        disabled={incompletePattern}
                        fullWidth
                    >
                        Save
                    </Button>
                </GreenChartTooltip>

                {pattern.author !== 'Showcase' && <GreenChartTooltip
                    title={incompletePattern ? "All conditions must be complete to share" : "Share this pattern"} arrow
                    key={"createNewVersion"}>
                    <IconButton disabled={incompletePattern}
                                onClick={shareLink ? () => setIsSharePatternDialogOpen(true) : createShareLink}
                                color="primary">
                        {!shareLink ? <ShareIcon/> : <PreviewIcon/>}
                    </IconButton>
                </GreenChartTooltip>}
            </Box>
    ]

    if (pattern.id) {
        if (patternRefPath && patternRefPath.includes(usermeta.gcid)) {
            actionButtons.push(
                <Tooltip title={"Delete pattern"} arrow key={"delete"}>
                    <Box sx={{flexGrow: 1}}>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setIsDeleteDialogOpen(true)}
                        >
                            Delete
                        </Button>
                    </Box>
                </Tooltip>
            )
        }

        actionButtons.push(
            <Tooltip title={incompletePattern ? "All conditions must be complete" : "Save as a new pattern"} arrow key={"saveNew"}>
                <Box sx={{flexGrow: 3}}>
                    <Button variant="outlined"
                            sx={{color: 'rgb(0, 161, 255)'}}
                            disabled={incompletePattern}
                            fullWidth
                            onClick={async () => {
                                const patterns = await getDocs(query(collection(firebase_firestore, `usermeta/${usermeta.gcid}/patterns`)));
                                const activePatterns = patterns.docs.filter(doc => !doc.data().deleted);
                                if (activePatterns.length < 100) {
                                    pattern.id = undefined
                                    pattern.version = 1;
                                    pattern.alertVersion = 1;
                                    save()
                                } else {
                                    setIsPatternLimitReachedDialogOpen(true)
                                }
                            }}
                    >
                        Save as
                    </Button>
                </Box>
            </Tooltip>
        )

        if (patternRefPath && patternRefPath.includes(usermeta.gcid)) {
            actionButtons.push(
                <Tooltip title={incompletePattern ? "All conditions must be complete" : "Publish new version for alerts"} arrow  key={"publish"}>
                    <Box sx={{flexGrow: 1}}>
                        <Button
                            variant={'outlined'}
                            color={"success"}
                            fullWidth
                            onClick={() => {
                                const previousVersion = versions && versions.length && versions[versions.length - 1].version ? versions[versions.length - 1].version : 1
                                pattern.alertVersion = previousVersion + 1
                                ReactGA.event('pattern_publish', {
                                    author: pattern.author,
                                    pattern_name: pattern.name
                                });
                                save()
                            }}
                            disabled={incompletePattern}
                        >
                            publish
                        </Button>
                    </Box>

                </Tooltip>
            )
        }
    }


    return <>
        <Box>
            {
                usermeta.isAdmin && <FormControlLabel
                sx={{margin: "8px 0", justifyContent: "start"}}
                control={<Switch
                    checked={saveAsStandardPattern}
                    onChange={(e) => setSaveAsStandardPattern(e.target.checked)}
                    color="warning" />
                }
                label="ADMIN: Save as Standard Pattern"
                labelPlacement="start"
                id="save-standard-pattern"
              />
            }
        </Box>

        <CurrentPanelFooter actionButtons={actionButtons}/>

        <ConfirmDialog
            open={!!isDeleteDialogOpen}
            title="Confirm Pattern Deletion"
            description="Are you sure you want to permanently delete this pattern? Once deleted, it cannot be recovered."
            confirmButtonText="Delete Pattern"
            cancelButtonText="Cancel"
            onClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={() => {
                deletePattern()
                setIsDeleteDialogOpen(false);
            }}
        />

        <OkayDialog
            open={!!isPatternLimitReachedDialogOpen}
            title="100 Pattern Limit Reached"
            description="You have reached the maximum number of patterns. Please delete some patterns before creating a new one."
            onClose={() => setIsPatternLimitReachedDialogOpen(false)}
            onOkay={() => setIsPatternLimitReachedDialogOpen(false)}
        />

        <ShareDialog
            open={ !!isSharePatternDialogOpen }
            title={ `Share Pattern: ${pattern.name}` }
            onClose={ () => setIsSharePatternDialogOpen(false) }
            content={ shareLink || '' }
        />
    </>
}

function CurrentPanelFooter(props: any) {
    const childrenArray = props.actionButtons;

    return (
        <Box mt={"auto"} display={"flex"} flexDirection={"column"} gap={1}>
            {props.children}
            {(() => {
                if (childrenArray.length === 2) {
                    return <Single>{childrenArray}</Single>;
                } else if (childrenArray.length === 3 || childrenArray.length === 4 ) {
                    return <OneTop>{childrenArray}</OneTop>;
                } else {
                    return (
                        <Box display={"flex"} flexDirection={"column"} gap={1}>
                            {childrenArray}
                        </Box>
                    );
                }
            })()}
        </Box>
    );
}

function Single(props: any) {
    const childrenArray =  props.children;
    const firstChild = childrenArray[0];

    return <Box  display={"flex"} gap={2} justifyContent={"space-around"}>
        <Box flexGrow={1} width={"100%"}>
            {firstChild}
        </Box>
    </Box>
}

function OneTop(props: any) {
    const childrenArray = React.Children.toArray(props.children);
    const firstChild = childrenArray[0];

    return <Box display={"flex"} flexDirection={"column"} gap={1}>
        {firstChild}
        <Box display={"flex"} flexDirection={"row"} gap={1} justifyContent={"center"}>
            {childrenArray.slice(1)}
        </Box>
    </Box>

}
