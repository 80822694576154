import React from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Box} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTheme} from "@mui/material/styles";

interface CustomAccordionProps {
    title: any;
    subTitle?: any;
    defaultOpen?: boolean;
    children: React.ReactNode;
}

const CollapsibleInputContainer: React.FC<CustomAccordionProps> = ({ title, subTitle, defaultOpen, children }) => {

    const theme = useTheme();
    const [expanded, setExpanded] = React.useState<boolean>(defaultOpen || false);

    return (
        <Accordion onChange={(_, isExpanded) => setExpanded(isExpanded)} expanded={expanded} sx={{background: theme.palette.background.custom3}}>
            <AccordionSummary
                sx={{
                    borderBottom: `1px solid ${theme.palette.background.custom4}`,
                    '&:hover': {
                        background: theme.palette.background.custom4
                    },
                }}
                expandIcon={<ExpandMoreIcon />}
            >

                <Box sx={{ width: {xs: '100%', md: '33%'}, flexShrink: 0 }}>
                    {title}
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        alignItems: 'center',
                    }}
                >
                    {subTitle}
                </Box>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    mt: 1
                }}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    );
}

export default CollapsibleInputContainer;
