import {createContext, useState} from "react";
import SnackbarMessage from "../components/SnackbarMessage";

interface snackbarObject {
    severity: 'error' | 'warning' | 'info' | 'success',
    message: string | any,
    autoHideDuration?: number,
    anchorOrigin?: { vertical: 'top' | 'bottom', horizontal: 'left' | 'center' | 'right' },
}

interface snackbarHelperContextInterface {
    snackbarSettings: snackbarObject | null,
    setSnackbarSettings: (settings: snackbarObject | null) => void,
}

export const SnackbarHelperContext = createContext<snackbarHelperContextInterface>({} as snackbarHelperContextInterface);

export function SnackbarHelper({children}: any) {
    const [snackbarSettings, setSnackbarSettings] = useState<snackbarObject | null>(null);

    return(
        <SnackbarHelperContext.Provider
            value={{
                snackbarSettings: snackbarSettings,
                setSnackbarSettings: setSnackbarSettings,
            }}>
            {children}
            <SnackbarMessage/>
        </SnackbarHelperContext.Provider>
    )
}
