import Indicator from "../types/Indicator";
import Value from "../Value";
import conditionTypes from "../../conditions";

const AwesomeOscillator: Indicator = {
    value: "awesomeOscillator",
    label: "Awesome Oscillator",
    nickname: "Awesome Oscillator",
    fields: ["awesomeOscillator"],
    RHS: () => [AwesomeOscillator, Value],
    conditions: conditionTypes,
}


export default AwesomeOscillator;
