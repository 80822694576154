import Indicator from "../../types/Indicator";
import Value from "../../Value";
import conditionTypes from "../../../conditions";

const ThinBlue: Indicator = {
    value: "thinBlue",
    label: "Thin Blue",
    fields: ["thinBlue"],
    RHS: () => [Value],
    conditions: conditionTypes,
};


export default ThinBlue;
