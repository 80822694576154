import Indicator from "../types/Indicator";
import {overlayIndicators} from "./index";
import Open from "../Open";
import High from "../High";
import Low from "../Low";
import Close from "../Close";
import Value from "../Value";
import conditionTypes from "../../conditions";

const AverageTrueRange: Indicator = {
    value: "averageTrueRange",
    label: "Average True Range",
    nickname: "ATR",
    fields: ["averageTrueRange"],
    params: {
        period: 14,
    },
    RHS: () => [...overlayIndicators, Open, High, Low, Close, Value],
    conditions: conditionTypes,
};


export default AverageTrueRange;
