import Indicator from "../types/Indicator";
import {overlayIndicators} from "./index";
import Open from "../Open";
import High from "../High";
import Low from "../Low";
import Close from "../Close";
import Value from "../Value";
import conditionTypes from "../../conditions";

const AverageTrueRangeBands: Indicator = {
    value: "averageTrueRangeBands",
    label: "Average True Range Bands",
    nickname: "ATR Bands",
    fields: ["upper", "lower"],
    params: {
        field: "close",
        period: 5,
        multiplier: 3,
    },
    RHS: () => [...overlayIndicators, Open, High, Low, Close, Value],
    conditions: conditionTypes,
}


export default AverageTrueRangeBands;
